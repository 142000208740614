import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  Table as MuiTable,
  type TableTypeMap as MuiTableTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTableCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - size - adjust options to better align with UI Kit
};

export type AxTableTypeMap<P = object, D extends React.ElementType = 'table'> = MuiTableTypeMap<P & AxTableCustomProps, D>;

export type AxTableProps<
  D extends ElementType = AxTableTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableTypeMap<P & AxTableCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTable'
};

const StyledAxTable = styled(MuiTable, styledOptions)<AxTableProps>(() => {
  // TODO - update to use UI Kit styles
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=6013-9216&mode=design&t=7poBe9Dfwla5L23E-0
  return {};
});

export const AxTable: MuiOverridableComponent<AxTableTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableProps, ref: Ref<HTMLTableElement>) => {
  const TableProps: AxTableProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTable { ...TableProps } ref={ ref }>
      { children }
    </StyledAxTable>
  );
});

export default AxTable;
