const {
  CollectionView,
  ItemView,
  LayoutView
} = require('Marionette');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const Backbone = require('Backbone');
const $os = require('detectOS');
const logging = require('logging');
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const CreateMenuDefinitionHelper = require('@training/apps/search/CreateMenuDefinitionHelper');

const MoreResultsButton = require('@training/apps/search/MoreResultsButton');
const SearchMessageView = require('@training/apps/search/SearchMessageView');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const PageableListViewController = require('@common/components/pageableList/PageableListViewController');
const PageableBrowseCommunitiesController = require('@training/apps/search/PageableBrowseCommunitiesController');
const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');

const CommunityNavButtonItemView = require('@common/components/discover/views/CommunityNavButtonItemView');
const NeedsReviewNavButtonItemView = require('@common/components/discover/views/NeedsReviewNavButtonItemView');

const SimpleArticleSearchResultsViewController = require('@training/apps/search/SimpleArticleSearchResultsViewController');
const ArticleSearchResultsViewController = require('@training/apps/search/ArticleSearchResultsViewController');

const ArticleMenuDropdown = require('@training/apps/search/ArticleMenuDropdown');
const ArticleContextMenuReportsButton = require('@training/apps/search/ArticleContextMenuReportsButton');

const ArticleReportsController = require('@training/apps/search/reports/ArticleReportsController');
const TopPagesController = require('@training/apps/search/reports/pages/top_pages/TopPagesController');
const TopKeywordsController = require('@training/apps/search/reports/pages/top_keywords_in_search/TopKeywordsController');
const TopKeywordsEmptySearchController = require('@training/apps/search/reports/pages/top_keywords_in_search_empty/TopKeywordsEmptySearchController');

const {
  NoSearchResultsView,
  NotAvailableView,
  SearchPromptView
} = require('@training/apps/search/SearchResultEmptyViews');

const {
  CommunitySettingsButtonOption,
  ReportsButtonOption,
  CommunityInsightsButtonOption
} = require('@training/apps/search/ArticleMenuDropdownOptions');

const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const SearchSubCategoryEnum = require('@training/apps/training/enums/SearchSubCategoryEnum');
const ImpressionTrackerEnum = require('@training/apps/common/enums/ImpressionTrackerEnum');

const { articleSearchResultControllerDefinition } = require('@training/apps/search/ArticleSearchResultControllerDefinitionFactory');
const { topicSearchResultControllerDefinition } = require('@training/apps/search/TopicSearchResultControllerDefinitionFactory');
const { pathSearchResultControllerDefinition } = require('@training/apps/search/PathSearchResultControllerDefinitionFactory');
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');
const RecentCommunitiesView = require('@training/apps/search/RecentCommunitiesView');
const CommunityList = require('@common/data/collections/CommunityList');
const CommunityType = require('@training/apps/training/enums/CommunityType');

/**
 * Public functions
 */
const getTabContentControllerDefinition = (tabType, options = {}) => {
  if (options == null || options.searchPageState == null) {
    logging.error('TabContentControllerDefinitions requires a searchPageState model.');
    return {};
  }

  const getDefinitionFn = _tabDefinitionMap[tabType];
  return getDefinitionFn(options);
};

const getNoQueryAllTabDefinition = (searchPageState, hasDZAndCommunitiesAccess) => {
  if (hasDZAndCommunitiesAccess) {
    return _getBrowseAllTabDefinition(searchPageState);
  }
  return getNoQueryViewDefinition(searchPageState);
};

const getNoQueryViewDefinition = (searchPageState) => {
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: SearchPromptView,
      searchPageState
    }
  };
};

const getNotAvailableViewDefinition = (searchPageState) => {
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: NotAvailableView,
      searchPageState
    }
  };
};

const getArticleBrowseCommunitiesViewDefinition = (searchPageState) => {
  const type = searchPageState.get('communityType');
  const viewDefinition = {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: LayoutView,
      template: require('@training/apps/search/BrowseCommunitiesLayout.html'),
      templateHelpers: {
        headerKey: type && type !== CommunityType.ALL ? `communitiesManagement.communityType.${ type }` : 'components.communityPicker.heading.allCommunities'
      },
      className: 'browse-all-communities ax-grid ax-grid--direction-column',
      regions: {
        contextMenuRegion: '.js-context-menu-region',
        browseCommunitiesRegion: '.js-browse-communities-region'
      }
    },
    regionControllers: {
      contextMenuRegion: getContextMenu(searchPageState),
      browseCommunitiesRegion: {
        ViewControllerClass: PageableBrowseCommunitiesController,
        collection: searchPageState.get('communityList'),
        itemViewClass(options) {
          if (options.model.get('isNeedsReview')) {
            return new NeedsReviewNavButtonItemView(options);
          }
          return new CommunityNavButtonItemView(options);
        },
        itemViewOptions: {
          searchPageState: searchPageState
        },
        delegateEvents: _getBrowseCommunitiesViewEvents(searchPageState)
      }
    }
  };

  const userMetadata = searchPageState.get('userMetadata');

  viewDefinition.viewDefinition.regions.createButtonRegion = '.js-create-button-region';

  viewDefinition.regionControllers.createButtonRegion = CreateMenuDefinitionHelper.getCreateMenuRegionController(
    null,
    userMetadata
  );

  return viewDefinition;
};

const getContextMenu = (searchPageState) => {
  const userMetadata = searchPageState.get('userMetadata');

  if (userMetadata.canViewReports() && !apps.auth.session.user.isAdminUser()) {
    return {viewDefinition: {
      ViewClass: ArticleContextMenuReportsButton
    }}
  }

  const contextMenuDropdownOptions = []
  if (!$os.mobile && !$os.isInMobileApp() && apps.auth.session.user.hasCommunityManagementAccess()) {
    contextMenuDropdownOptions.push(CommunitySettingsButtonOption);
  }

  if (apps.auth.session.user.hasDiscoverReportingAccess()) {
    contextMenuDropdownOptions.push(ReportsButtonOption);
    contextMenuDropdownOptions.push(CommunityInsightsButtonOption);
  }

  if (contextMenuDropdownOptions.length > 0) {
    return {
      viewDefinition: {
        ViewClass: ArticleMenuDropdown,
        userMetadata: searchPageState.get('userMetadata'),
        buttonConfig: {
          buttonIcon: 'icon-ellipsis_horizontal',
          buttonAriaLabel: 'discover.browse.contextMenuDropdown',
          popupAlignment: MenuDropdownPositionEnum.RIGHT + MenuDropdownPositionEnum.BOTTOM
        },
        optionsListConfig: contextMenuDropdownOptions
      }
    }
  }

  return null
}

const _getBrowseCommunitiesViewEvents = (searchPageState) => {
  const domHandler = _getDomMutateEventHandler();
  const viewAttachEvent = {
    'view:attach': (controller, view) => {
      const communityList = searchPageState.get('communityList');
      _addFavoriteAndNeedsReview(searchPageState, communityList);

      view.listenTo(communityList, 'sync', () => {
        _addFavoriteAndNeedsReview(searchPageState, communityList);

        const newHash = SearchUrlHelper.getHashForBrowseCommunities(searchPageState, SearchCategoryEnum.ARTICLES);
        Backbone.history.navigate(
          newHash,
          { trigger: false }
        );
      });
    },
    'view:childview:render': () => {
      triggerResize(true);
    },
    // Needs Review Tile was clicked
    'view:childview:needsreview:item:clicked': () => {
      Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/${ SearchCategoryEnum.ARTICLES }/needs-review/1/`, {trigger: true});
    },
    // A Community Tile was clicked
    'view:childview:item:clicked': (controller, view, childView) => {
      if (childView.model.get('isFavorite')) {
        Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/${ SearchCategoryEnum.ARTICLES }/favorites/1/`, {trigger: true});
      } else {
        Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/community-${ childView.model.get('id') }`, {trigger: true});
      }
    }
  };
  return Object.assign({}, domHandler, viewAttachEvent);
};

/**
 * Private helpers
 */

const _addFavoriteAndNeedsReview = (searchPageState, communityList) => {
  const needsReviewModel = communityList.findWhere('isNeedsReview');
  const favoriteModel = communityList.findWhere('isFavorite');

  if (communityList.state.currentPage !== 0 || needsReviewModel || favoriteModel
    || communityList.extraParams?.type) {
    return;
  }

  const isDZGuest = apps.auth.session.user.isGuestOrSuperuser();

  const userMetadata = searchPageState.get('userMetadata');

  const reportedCount = userMetadata.get('reportedCount');
  const reviewCount = userMetadata.get('reviewCount');


  if (!isDZGuest) {
    const favorite = new Backbone.Model();
    favorite.set({
      isFavorite: true,
      nameKey: 'discover.browse.favorites',
      icon: 'icon-star-solid'
    });

    // Because we are treating the "favourites" list like it's a legit Community, we need to define
    // these methods, so it mimics some parts of the Community interface
    favorite.getName = () => {
      return I18n.t('discover.browse.favorites');
    };
    favorite.getNonPostCount = () => {
      // this is used to construct that metadata phrase, like "3 Articles"
      return userMetadata.get('favoriteCount');
    };
    favorite.arePostsEnabled = () => {
      // favourites are not available on Posts! Only articles can be faved.
      return false;
    };
    favorite.areArticlesEnabled = () => {
      return true; // we return true so that the "Favourites" Community always shows the number of articles
    };
    communityList.unshift(favorite);
  }

  // Needs Review Tile is hidden when both counts are 0
  if (reportedCount > 0 || reviewCount > 0) {
    const needsReview = new Backbone.Model();
    needsReview.set({
      isNeedsReview: true,
      icon: 'icon-needsreview',
      reviewCount,
      reportedCount
    });

    communityList.unshift(needsReview);
  }
};

const _getBehaviors = () => {
  return {
    MutationObservable: {
      observeOptions: {
        childList: true,
        subtree: true
      }
    }
  };
};

const _getDomMutateEventHandler = () => {
  return {
    'view:dom:mutate': () => {
      triggerResize(true);
    }
  };
};

const _getSingleTabViewEvents = (tabName, results, searchPageState) => {
  const domHandler = _getDomMutateEventHandler();
  const viewAttachEvent = {
    'view:attach': (controller, view) => {
      view.listenTo(results, 'sync', () => {
        const newHash = SearchUrlHelper.getHashForCurrentTabState(searchPageState, tabName, true);
        Backbone.history.navigate(
          newHash,
          { trigger: false }
        );

        // hacky way to insert warning message into the collection view
        const isTabPathsOrTopics = tabName === SearchCategoryEnum.TOPICS || tabName === SearchCategoryEnum.PATHS;
        if (isTabPathsOrTopics && !app.sessionController.isMinDailyTrainingFulfilled() && results.length > 0) {
          const $extraTrainingMessage = `<p class="extra-training-message"><span class="ax-font--bold">${ I18n.t('selfDirected.browse.pathTopicsAvailabilityMessage') }</span> ${ I18n.t('learnerTrainingPage.seeTopicDetailsMessage') } </p>`
          const $topRegion = $(view.$el).find('.page-control-top-region');

          if ($topRegion.find('.extra-training-message').length === 0) {
            $topRegion
              .append($extraTrainingMessage);
          }
        }
      });

      view.listenTo(Backbone.Wreqr.radio.channel('global').vent, 'pagination:clicked', () => {
        $('#page-view > .adjustable-container').scrollTop(0);
      });
    }
  };

  return Object.assign({}, domHandler, viewAttachEvent);
};

const _getAllTabDefinition = (options = {}) => {
  const searchPageState = options.searchPageState;
  const startTrainingFn = options.startTrainingFn;
  const tenantPropertyProvider = TenantPropertyProvider.get();

  const hubController = window.app.sessionController.getHubController();

  const dzTenantValue = tenantPropertyProvider.getProperty('discoveryZoneEnabled');
  const isDZGuest = window.apps.auth.session.user.isGuestOrSuperuser();
  const dzEnabled = dzTenantValue && (hubController.hasSomeDiscoveryZoneCommunityAvailable() || isDZGuest);
  const etEnabled = hubController.isExtraTrainingEnabled();
  const showAuthor = dzEnabled ? tenantPropertyProvider.getProperty('dzShowAuthorAndContributorInfo') : false;

  const showReactions = true; // in this situation we DO show the reaction tray

  if (etEnabled && startTrainingFn == null) {
    logging.error('Topic Search Results require a startTrainingFn.');
    return {};
  }

  const topicResults = searchPageState.get('results')[SearchCategoryEnum.TOPICS];
  const articleResults = searchPageState.get('results')[SearchCategoryEnum.ARTICLES];
  const pathResults = searchPageState.get('results')[SearchCategoryEnum.PATHS];
  const searchString = searchPageState.get('searchString');

  const viewConfig = {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: LayoutView,
      topicsCollection: topicResults,
      articlesCollection: articleResults,
      pathsCollection: pathResults,
      template: require('@training/apps/search/AllTabSearchLayout.html'),
      templateHelpers: {
        dzEnabled,
        etEnabled
      },
      regions: {
        searchMessageRegion: '.search-results-message-region',
        articleResultsRegion: '.search__article-results-region',
        seeArticlesButtonRegion: '.see-articles-region',
        topicResultsRegion: '.search__topic-results-region',
        seeTopicsButtonRegion: '.see-topics-region',
        pathResultsRegion: '.search__path-results-region',
        seePathsButtonRegion: '.see-paths-region'
      },
      behaviors: _getBehaviors()
    },
    regionControllers: {
      searchMessageRegion: {
        viewDefinition: {
          ViewClass: SearchMessageView,
          searchPageState
        }
      },
      articleResultsRegion: _getResultsRegion(articleSearchResultControllerDefinition, articleResults, {
        searchPageState,
        showAuthor,
        showReactions,
        source: ImpressionTrackerEnum.ARTICLE_SEARCH_RESULTS,
        titleTag: 'h3'
      }),
      seeArticlesButtonRegion: _getButtonViewDefinition(SearchCategoryEnum.ARTICLES, articleResults, searchString),
      topicResultsRegion: _getResultsRegion(topicSearchResultControllerDefinition, topicResults, {
        startTrainingFn,
        searchPageState,
        titleTag: 'h3'
      }),
      seeTopicsButtonRegion: _getButtonViewDefinition(SearchCategoryEnum.TOPICS, topicResults, searchString),
      pathResultsRegion: _getResultsRegion(pathSearchResultControllerDefinition, pathResults, {
        searchPageState,
        titleTag: 'h3'
      }),
      seePathsButtonRegion: _getButtonViewDefinition(SearchCategoryEnum.PATHS, pathResults, searchString)
    },
    delegateEvents: Object.assign({}, _getDomMutateEventHandler(), {
      'view:attach': (controller, view) => {
        view.listenTo(articleResults, 'sync', () => {
          $('.totalArticleCount').html(articleResults.state.totalRecords);
        });
        view.listenTo(topicResults, 'sync', () => {
          $('.totalTopicCount').html(topicResults.state.totalRecords);
        });
        view.listenTo(pathResults, 'sync', () => {
          $('.totalPathCount').html(pathResults.state.totalRecords);
        });
      }
    })
  };

  if (!dzEnabled) {
    delete viewConfig.viewDefinition.regions.articleResultsRegion;
    delete viewConfig.regionControllers.articleResultsRegion;
    delete viewConfig.viewDefinition.regions.seeArticlesButtonRegion;
    delete viewConfig.regionControllers.seeArticlesButtonRegion;
  }
  if (!etEnabled) {
    delete viewConfig.viewDefinition.regions.topicResultsRegion;
    delete viewConfig.regionControllers.topicResultsRegion;
    delete viewConfig.viewDefinition.regions.seeTopicsButtonRegion;
    delete viewConfig.regionControllers.seeTopicsButtonRegion;
  }

  return viewConfig;
};

const _getResultsRegion = (childViewControllerDefinitionFn, collection, itemViewOptions = {}) => {
  const searchString = itemViewOptions.searchPageState ? itemViewOptions.searchPageState.get('searchString') : '';

  return {
    ViewControllerClass: CollectionLayoutViewController,
    collection,
    viewDefinition: {
      className: 'search__results',
      tagName: 'ul',
      childViewOptions: {
        tagName: 'li'
      },
      emptyView: NoSearchResultsView,
      emptyViewOptions: {
        searchString
      }
    },
    ChildViewControllerDefinition: (model) => {
      return childViewControllerDefinitionFn(model, itemViewOptions);
    }
  };
};

const _getBrowseAllTabDefinition = (searchPageState) => {
  const specialCommunityList = new CommunityList();
  const viewDefinition = {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: LayoutView,
      template: `
        <div class="communities-list-header"></div>
        <div class="special-communities-region"></div>
        <div class="recent-communities-list-region"></div>
      `,
      className: 'browse-recent-communities ax-grid ax-grid--direction-column',
      regions: {
        communitiesRegionHeader: '.communities-list-header',
        specialCommunitiesRegion: '.special-communities-region',
        communitiesListRegion: '.recent-communities-list-region'
      }
    },
    regionControllers: {
      communitiesRegionHeader: {
        ViewControllerClass: LayoutController,
        viewDefinition: {
          ViewClass: LayoutView,
          className: 'articles-header ax-grid ax-grid--align-items-center ax-grid--no-gutter',
          template: `<h2 class='articles-header__title'> <%- t("selfDirected.browse.articleListHeader") %></h2>
            <div class="js-view-communities-button-region ax-grid__col--auto-size"></div>
            <div class="js-context-menu-region context-menu-region ax-grid__col--auto-size"></div>`,
          regions: {
            viewAllCommunitiesButton: '.js-view-communities-button-region',
            contextMenuRegion: '.js-context-menu-region'
          }
        },
        regionControllers: {
          viewAllCommunitiesButton: {
            viewDefinition: {
              ViewClass: ItemView,
              template: `
              <%= axButton({
                className: 'view-all-communities-button',
                label: t('${ $os.mobile ? 'general.viewAll' : 'selfDirected.browse.viewAllCommunities' }')
              }) %>
              `,
              className: 'view-all-communities-wrapper',
              events: {
                'click .view-all-communities-button': () => {
                  Backbone.history.navigate(`#hub/search/type-${ CommunityType.ALL }/1/`, {trigger: true});
                }
              }
            }
          },
          contextMenuRegion: getContextMenu(searchPageState)
        }
      },
      specialCommunitiesRegion: {
        viewDefinition: {
          ViewClass: CollectionView,
          collection: specialCommunityList,
          className: 'search-communities-list ax-grid',
          childView: (options) => {
            if (options.model.get('isNeedsReview')) {
              return new NeedsReviewNavButtonItemView(options);
            }
            return new CommunityNavButtonItemView(options);
          },
          childViewOptions: {
            searchPageState: searchPageState,
            className: 'search-community-list-item ax-grid__col--12 ax-grid__col--m-6 ax-grid__col--l-3'
          }
        },
        delegateEvents: {
          // Needs Review Tile was clicked
          'view:childview:needsreview:item:clicked': () => {
            Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/${ SearchCategoryEnum.ARTICLES }/needs-review/1/`, {trigger: true});
          },
          // A Community Tile was clicked
          'view:childview:item:clicked': (controller, view, childView) => {
            if (childView.model.get('isFavorite')) {
              //ArticlesSearchCollection is missing subCategory due to the path we are following to get to favorites
              searchPageState.get('results')[SearchCategoryEnum.ARTICLES].subCategory = SearchSubCategoryEnum.FAVORITES;
              Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/${ SearchCategoryEnum.ARTICLES }/favorites/1/`, {trigger: true});
            }
          }
        }
      },
      communitiesListRegion: {
        ViewControllerClass: CollectionLayoutViewController,
        collection: searchPageState.get('mostRecentCommunities'),
        viewDefinition: {
          className: 'recent-communities-list'
        },
        ChildViewControllerDefinition: (model) => {
          return {
            viewDefinition: {
              ViewClass: RecentCommunitiesView,
              model
            },
            delegateEvents: {
              'view:childview:item:clicked': (controller, view, childView) => {
                Backbone.history.navigate(`#hub/search/all/community-${ childView.model.get('id') }`, {trigger: true});
              }
            }
          };
        },
        delegateEvents: {
          'view:inflate': () => {
            if (app.sessionController.getHubController().hasSomeDiscoveryZoneCommunityAvailable()) {
              searchPageState.get('mostRecentCommunities').fetch({success: (byType) => {
                if (byType.length > 1) {
                  _addFavoriteAndNeedsReview(searchPageState, specialCommunityList);
                }
              }});
            }
          }
        }
      }
    }
  };

  return viewDefinition;
};

const _getButtonViewDefinition = (tabType, collection, searchString) => {
  return {
    viewDefinition: {
      ViewClass: MoreResultsButton,
      collection,
      tabType,
      searchString
    }
  };
};

const getTitle = (searchPageState) => {
  const titles = {};
  titles[SearchSubCategoryEnum.COMMUNITY] = '';
  titles[SearchSubCategoryEnum.PENDING] = I18n.t('discover.browse.needsReview');
  titles[SearchSubCategoryEnum.FAVORITES] = I18n.t('discover.browse.favorites');
  titles[SearchSubCategoryEnum.ARTICLE_REPORTS] = I18n.t('discover.browse.reports.title');

  return titles[searchPageState.get('subCategory')] || '';
};

const _getArticleTabDefinition = (options) => {
  const searchPageState = options.searchPageState;
  const title = getTitle(searchPageState);
  let viewController;

  const _articleTabDefinitionMap = {
    [SearchSubCategoryEnum.ARTICLE_REPORTS]: ArticleReportsController,
    [SearchSubCategoryEnum.TOP_PAGES]: TopPagesController,
    [SearchSubCategoryEnum.TOP_KEYWORDS_IN_SEARCH]: TopKeywordsController,
    [SearchSubCategoryEnum.TOP_KEYWORDS_EMPTY_SEARCH]: TopKeywordsEmptySearchController,
    [SearchSubCategoryEnum.PENDING]: SimpleArticleSearchResultsViewController,
    [SearchSubCategoryEnum.FAVORITES]: SimpleArticleSearchResultsViewController
  };

  if (_articleTabDefinitionMap[searchPageState.get('subCategory')]) {
    viewController = _articleTabDefinitionMap[searchPageState.get('subCategory')];
  } else {
    // note: this happens when you search without choosing a community
    viewController = ArticleSearchResultsViewController;
  }

  return {
    ViewControllerClass: viewController,
    searchPageState,
    title
  };
};

const _getTopicTabDefinition = (options) => {
  return _getTabDefinition(topicSearchResultControllerDefinition, SearchCategoryEnum.TOPICS, options);
};

const _getPathTabDefinition = (options) => {
  return _getTabDefinition( pathSearchResultControllerDefinition, SearchCategoryEnum.PATHS, options);
};

const _getTabDefinition = (childViewControllerDefinitionFn, tabType, options = {}) => {
  const searchPageState = options.searchPageState;
  const startTrainingFn = options.startTrainingFn;
  const isTopic = (tabType === SearchCategoryEnum.TOPICS);
  const itemViewOptions = isTopic ? {
    searchPageState,
    startTrainingFn,
    titleTag: 'h2'
  } : {
    searchPageState,
    titleTag: 'h2'
  };

  if (isTopic && startTrainingFn == null) {
    logging.error('Topic Search Results require a startTrainingFn.');
    return {};
  }

  const collection = searchPageState.get('results')[tabType];
  const searchString = searchPageState.get('searchString');

  return {
    ViewControllerClass: PageableListViewController,
    collection: collection,
    childViewControllerDefinitionFn: childViewControllerDefinitionFn,
    viewDefinition: {
      behaviors: _getBehaviors()
    },
    itemViewOptions,
    emptyViewClass: NoSearchResultsView,
    emptyViewOptions: {
      searchString
    },
    delegateEvents: _getSingleTabViewEvents(tabType, collection, searchPageState)
  };
};

const _tabDefinitionMap = {
  [SearchCategoryEnum.ALL]: _getAllTabDefinition,
  [SearchCategoryEnum.ARTICLES]: _getArticleTabDefinition,
  [SearchCategoryEnum.TOPICS]: _getTopicTabDefinition,
  [SearchCategoryEnum.PATHS]: _getPathTabDefinition
};

module.exports = {
  getTabContentControllerDefinition,
  getNoQueryAllTabDefinition,
  getNoQueryViewDefinition,
  getNotAvailableViewDefinition,
  getArticleBrowseCommunitiesViewDefinition
};
