import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  MobileStepper as MuiMobileStepper,
  type MobileStepperProps as MuiMobileStepperProps
} from '@mui/material';

export type AxMobileStepperProps = MuiMobileStepperProps;

const styledOptions = {
  name: 'AxMobileStepper'
};

const StyledAxMobileStepper = styled(MuiMobileStepper, styledOptions)<AxMobileStepperProps>(() => {
  // TODO - update to use common variables
  return {};
});

export const AxMobileStepper = forwardRef(({
  ...otherProps
}: AxMobileStepperProps, ref: Ref<HTMLDivElement>) => {
  const MobileStepperProps: AxMobileStepperProps = {
    ...otherProps
  };

  return (
    <StyledAxMobileStepper { ...MobileStepperProps } ref={ ref } />
  );
});

export default AxMobileStepper;
