import {
  ListItemAvatar as MuiListItemAvatar,
  type ListItemAvatarProps as MuiListItemAvatarProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

type AxListItemAvatarProps = MuiListItemAvatarProps;

const styledOptions = {
  name: 'AxListItemAvatar'
};

const StyledAxListItemAvatar = styled(MuiListItemAvatar, styledOptions)<AxListItemAvatarProps>(({theme}) => {
  // TODO - update to use common variables (Possibly use existing Task > New > Assign list styles as default)
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=9831-3479&mode=design&t=7poBe9Dfwla5L23E-0
  return {
    minWidth: 'unset',
    marginRight: theme.uiKit.spacingS
  };
});

export const AxListItemAvatar = forwardRef(({
  children,
  ...otherProps
}: AxListItemAvatarProps, ref: Ref<HTMLLIElement>) => {
  const ListItemAvatarProps: AxListItemAvatarProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItemAvatar { ...ListItemAvatarProps } ref={ ref }>
      { children }
    </StyledAxListItemAvatar>
  );
});

export default AxListItemAvatar;
