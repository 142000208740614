const { ItemView } = require('Marionette');

class SubtextHeaderTitle extends ItemView {
  className() {
    return 'subtext-header-title qa-subtext';
  }

  getTemplate() {
    return `
      <<%= titleTag %> class="<%= titleClass %> qa-subtext-title"><%= title %></<%= titleTag %>>
      <p class="<%= subtextClass %> qa-subtext-text"><%= subtext %></p>
    `;
  }

  templateHelpers() {
    return {
      title: this.getOption('title'),
      titleClass: this.getOption('titleClass'),
      titleTag: this.getOption('titleTag') || 'h4',
      subtext: this.getOption('subtext'),
      subtextClass: this.getOption('subtextClass')
    };
  }
}

module.exports = SubtextHeaderTitle;
