import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  SpeedDialAction as MuiSpeedDialAction,
  type SpeedDialActionProps as MuiSpeedDialActionProps
} from '@mui/material';

export type AxSpeedDialActionProps = MuiSpeedDialActionProps;

const styledOptions = {
  name: 'AxSpeedDialAction'
};

const StyledAxSpeedDialAction = styled(MuiSpeedDialAction, styledOptions)<AxSpeedDialActionProps>(() => {
  // TODO - should use AxFab and AxIconButton styles
  return {};
});

export const AxSpeedDialAction = forwardRef(({
  ...otherProps
}: AxSpeedDialActionProps, ref: Ref<HTMLDivElement>) => {
  const SpeedDialActionProps: AxSpeedDialActionProps = {
    ...otherProps
  };

  return (
    <StyledAxSpeedDialAction { ...SpeedDialActionProps } ref={ ref } />
  );
});

export default AxSpeedDialAction;
