const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const MediaPreview = require('@common/components/mediaPreview/MediaPreview');

const MediaZoomLayout = require('@common/components/mediaZoom/MediaZoomLayout');

const FileFactory = require('@common/libs/file/FileFactory');
const MediaFactory = require('@common/libs/file/MediaFactory');
const MediaTypesEnum = require('@common/components/mediaPreview/MediaTypesEnum').default;

require('@common/libs/behaviors/resizable/Resizable');

module.exports = (media, maxDimensions = {}) => {
  // Make sure the model is in the correct MediaModel container to satify MediaPreview's Interface
  const mediaModel = MediaFactory.createMediaFromValue(FileFactory, media, {
    documentsAllowed: true
  });

  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: MediaZoomLayout,
      behaviors: {
        Resizable: {}
      },
      isImage: mediaModel.get('type') === MediaTypesEnum.IMAGE
    },
    regionControllers: {
      imageRegion: {
        viewDefinition: {
          ViewClass: MediaPreview,
          model: mediaModel,
          maxWidth: maxDimensions.maxWidth,
          maxHeight: maxDimensions.maxHeight
        }
      }
    },
    delegateEvents: {
      'view:resize': (controller, view) => {
        controller.findControllerByRegion('imageRegion').getView()
          .setMaxDimensions(view.getWidth(), view.getHeight());
      }
    }
  };
};
