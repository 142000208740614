const { history } = require('Backbone');
const _ = require('underscore');
const logging = require('logging');
const $os = require('detectOS');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const I18n = require('@common/libs/I18n');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const {
  combineUrlPaths,
  joinUrlFragments
} = require('@common/libs/helpers/app/UrlHelpers');

const TimeLogConfig = require('@training/apps/base/models/TimeLogConfig');
const FlowController = require('@training/apps/training/controllers/FlowController');

const CommunityList = require('@common/data/collections/CommunityList');

const AssessmentType = require('@common/data/enums/AssessmentType');
const { START_PAGE } = require('@common/data/enums/AssessmentLaunchContext');

const TrainingMenuConfigProvider = require('@common/data/TrainingMenuConfigProvider');

const AssessmentTopicOption = require('@common/data/models/assessments/AssessmentTopicOption');

const ReferralStatus = require('@training/apps/training/models/ReferralStatus');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');

const StaticAssessmentInitiatorConfigurationProvider = require('@training/apps/training/controllers/assessments/StaticAssessmentInitiatorConfigurationProvider');
const GuidedLearningContextualAvailabilityFactory = require('@training/apps/training/controllers/guidedLearning/GuidedLearningContextualAvailabilityFactory');

const PostTrainingHomePageController = require('@training/apps/home/layout/PostTrainingHomePageController');

const ConversationalSearchControllerDefinitionFactory = require('@training/apps/conversationalSearch/ConversationalSearchControllerDefinitionFactory').default;
const LearnerTrainingControllerDefinitionFactory = require('@training/apps/learnerTraining/LearnerTrainingControllerDefinitionFactory');

const TodaysActivitiesOrderingModel = require('@training/apps/home/activities/TodaysActivitiesOrderingModel').default;

const SessionTrainingType = require('@common/data/enums/SessionTrainingType');

const {
  HUB,
  ACHIEVEMENTS,
  REWARDS,
  CERTIFICATION_TRAINING,
  EXTRA_TRAINING,
  GAME_SCORES,
  GAME_SCORES_DETAILS,
  REPORT_CARD,
  TOP_PERFORMERS,
  INTRO_TRAINING,
  FEED,
  REFERRAL,
  LEARNING_EVENTS,
  LEARNING_EVENT_DETAILS,
  FORMAL_EXAM,
  REFRESHER_TRAINING,
  PROFILE,
  GUIDED_LEARNING,
  SELF_DIRECTED_PATHS,
  MESSAGE,
  TIMELINE,
  CONVERSATIONAL_SEARCH,
  LEARNER_TRAINING,
  EXECUTION_TASKS,
  CAMPAIGN_COMMUNICATIONS,
  RECOGNITION_PINS,
  CONSOLIDATED_COMMS,
  CERTIFICATE
} = require('@common/data/enums/HubTileIdentifiers').default;
const HubTileIdentifiers = require('@common/data/enums/HubTileIdentifiers').default;
const HubMenuConfigMap = require('@common/modules/base/HubMenuConfigMap');

const PRELIMINARY_REFERRAL = 'PRELIMINARY_REFERRAL';

const pageHashFactory = (...pathFragments) => {
  const url = combineUrlPaths(...pathFragments);
  return () => {
    return url;
  };
};

const ViewInfo = {
  [REWARDS]: {
    timeLogOptions: TimeLogConfig.PageView.Rewards,
    ViewClass: require('@training/apps/training/views/RewardsPage'),
    hash: ({ initialTab } = {}) => {
      return pageHashFactory('rewards', initialTab)();
    }
  },
  [TOP_PERFORMERS]: {
    timeLogOptions: TimeLogConfig.PageView.TopPerformers,
    ViewClass: require('@training/apps/training/views/TopPerformersPage'),
    hash: ({ initialTab } = {}) => {
      return pageHashFactory('topPerformers', initialTab)();
    }
  },
  [GAME_SCORES]: {
    timeLogOptions: TimeLogConfig.PageView.GameScores,
    ViewClass: require('@training/apps/training/views/GameScoresPage'),
    hash: pageHashFactory('gameScores')
  },
  [GAME_SCORES_DETAILS]: {
    ViewClass: require('@training/apps/training/views/GameScoresDetailsPage'),
    hash: ({ gameId } = {}) => {
      return pageHashFactory('gameScores', gameId)();
    }
  },
  [REPORT_CARD]: {
    timeLogOptions: TimeLogConfig.PageView.ReportCard,
    ViewClass: require('@training/apps/training/views/KnowledgePage'),
    MobileClass: require('@training/apps/mobile/views/KnowledgePage'),
    hash: pageHashFactory('reportCard')
  },
  [FEED]: {
    ViewClass: require('@training/apps/training/views/FeedPage'),
    hash: ({ initialTab } = {}) => {
      return pageHashFactory('feed', initialTab)();
    }
  },
  [MESSAGE]: {
    ViewClass: require('@training/apps/training/views/MessageDetailsPage'),
    hash: ({
      messageId,
      language
    } = {}) => {
      const messageParams = joinUrlFragments([messageId, language], '-');
      return pageHashFactory('message', messageParams)();
    }
  },
  [PRELIMINARY_REFERRAL]: {
    timeLogOptions: TimeLogConfig.PageView.AutomaticTellAFriend,
    ViewClass: require('@training/apps/training/views/ReferralPage')
  },
  [REFERRAL]: {
    timeLogOptions: TimeLogConfig.PageView.TellAFriend,
    ViewClass: require('@training/apps/training/views/ReferralPage'),
    hash: pageHashFactory('referral')
  },
  [LEARNING_EVENTS]: {
    timeLogOptions: TimeLogConfig.PageView.LearningEvents,
    ViewClass: require('@training/apps/training/views/LearningEventsPage'),
    hash: ({
      eventId,
      scheduledEventId,
      enrollmentId,
      initialTab
    } = {}) => {
      const eventParams = joinUrlFragments([eventId, scheduledEventId, enrollmentId]);
      return pageHashFactory('events', eventParams, initialTab)();
    }
  },
  [LEARNING_EVENT_DETAILS]: {
    ViewClass: require('@training/apps/training/views/LearningEventDetailsPage'),
    hash: ({ initialTab } = {}) => {
      return pageHashFactory('eventDetails', initialTab)();
    }
  },
  [PROFILE]: {
    timeLogOptions: TimeLogConfig.PageView.Profile,
    ViewClass: require('@training/apps/training/views/ProfilePage'),
    MobileClass: require('@training/apps/mobile/views/ProfilePage'),
    hash: ({ initialTab } = {}) => {
      return pageHashFactory('profile', initialTab)();
    }
  },
  [CONVERSATIONAL_SEARCH]: {
    ViewClass: require('@training/apps/conversationalSearch/ConversationalSearchControllerDefinitionFactory')
  }
};
class HubController extends FlowController {
  // Session gets passed in as the model
  constructor(parentProcessor, options = {}) {
    super(parentProcessor);

    this.processSequenceFlow = this.processSequenceFlow.bind(this);
    this.initiateTrainingFromTopicSearch = this.initiateTrainingFromTopicSearch.bind(this);
    this.startOrResumeAssessment = this.startOrResumeAssessment.bind(this);

    this.toggleHubMenuEnabled(false);
    this.menuConfigProvider = TrainingMenuConfigProvider();

    this.hasAvailableCommunitiesWithPages = false;
    this.pageState = {};

    this.todaysActivitiesOrderingModel = new TodaysActivitiesOrderingModel();

    ({
      sessionModel: this.sessionModel,
      quickLinksList: this.quickLinksList,
      eventCheckinModel: this.eventCheckinModel,
      topicProgressItemList: this.topicProgressItemList,
      messageCollection: this.messageCollection,
      continueTrainingItems: this.continueTrainingItems,
      rewardsCollection: this.rewardsCollection,
      eventsCollection: this.eventsCollection,
      achievementsCongratulationCollection: this.achievementsCongratulationCollection,
      recommendedArticlesList: this.recommendedArticlesList,
      recommendedArticlesEnabled: this.recommendedArticlesEnabled,
      brandingChannelModel: this.brandingChannelModel,
      startCurrentSessionTraining: this.startCurrentSessionTraining,
      topicTrainingCardsCollection: this.topicTrainingCardsCollection,
      pathTrainingCardsCollection: this.pathTrainingCardsCollection,
      catalogTrainingCardsCollection: this.catalogTrainingCardsCollection,
      catalogFilterSubjectList: this.catalogFilterSubjectList,
      timelineNotifier: this.timelineNotifier,
      removeBroadcastFromQueue: this.removeBroadcastFromQueue
    } = options);

    // We need the referral status to know if we should show Tell-A-Friend before
    // the hub and if the user has seen the referral help text
    this.referralStatus = new ReferralStatus();
    this._getReferralStatus = _.memoize(() => {
      return Promise.resolve(this.referralStatus.fetch({ showSpinner: false }));
    });
    this._getHubTiles = _.memoize(() => {
      return Promise.resolve(this.menuConfigProvider.fetch());
    });
    this._fetchAvailableCommunityWithPages = _.memoize(() => {
      return this.fetchHasAvailableCommunities();
    });

    this.trainingPaywallEnabled = window.apps.auth.session.user.get('trainingPaywallEnabled');
  }

  getContinueTrainingItems() {
    return this.continueTrainingItems.fetch({
      data: {
        passDateThreshold: this.sessionModel.get('startDate')
      }
    });
  }

  getReferralStatus() {
    return this._getReferralStatus();
  }

  getIfHasAvailableCommunitiesWithPages() {
    if (TenantPropertyProvider.get().getProperty('discoveryZoneEnabled')) {
      return this._fetchAvailableCommunityWithPages().then((response) => {
        return response.accessible;
      });
    }
    return Promise.resolve(0);

  }

  fetchHasAvailableCommunities() {
    if (this.communitiesAvailableAsync == null) {
      this.communitiesAvailableAsync = CommunityList.hasAvailableCommunities();
    }

    return this.communitiesAvailableAsync.then((response = {}) => {
      return (this.hasAvailableCommunitiesWithPages = response.accessible);
    });
  }

  getDiscoverAccessState() {
    const isDZGuest = window.apps.auth.session.user.isGuestOrSuperuser();
    const promises = [this.fetchHasAvailableCommunities()];

    if (this.menuItemsAsync == null && !isDZGuest) {
      this.menuItemsAsync = this.menuConfigProvider.fetch();
    }
    if (this.currentSessionAsync == null && !isDZGuest) {
      this.currentSessionAsync = this.parentProcessor.loadSession();
    }
    if (!isDZGuest) {
      promises.push(this.menuItemsAsync, this.currentSessionAsync);
    }

    return Promise.all(promises);
  }

  hasSomeDiscoveryZoneCommunityAvailable() {
    return this.hasAvailableCommunitiesWithPages === true;
  }

  shouldSeePreliminaryReferral() {
    const referralStatusAsync = this.getReferralStatus();
    const hubTilesAsync = this.getHubTiles();
    return Promise.all([referralStatusAsync, hubTilesAsync]).then(() => {
      return (
        this.referralStatus.get('dueToSeeInitialReferralPage')
        && this.referralStatus.get('hasBountiesToClaim')
        && this.isHubTileEnabled(REFERRAL)
      );
    });
  }

  toggleHubMenuEnabled(enabled = true) {
    this.isAtTheHub = enabled;
  }

  isHubMenuEnabled() {
    return this.isAtTheHub;
  }

  getHubTiles() {
    return this._getHubTiles();
  }

  getAvailableTiles() {
    return this.menuConfigProvider.getAvailableConfigs();
  }

  isTimelineEnabled() {
    return this.isHubTileEnabled(TIMELINE);
  }

  isHubTileEnabled(hubtile) {
    let config = HubMenuConfigMap[hubtile];
    let key = hubtile;

    while (config.parentConfigId != null) {
      key = config.parentConfigId;
      config = HubMenuConfigMap[config.parentConfigId];
    }

    return _.has(this.getAvailableTiles(), key);
  }

  isExtraTrainingEnabled() {
    return this.isHubTileEnabled(EXTRA_TRAINING);
  }

  _storePageState(pageId, pageOptions) {
    this.pageState = {
      pageId,
      pageOptions
    };
  }

  _clearPageState() {
    this.pageState = {};
  }

  processSequenceFlow(options = {}) {
    return this.getHubTiles()
      .then(() => {
        this.toggleHubMenuEnabled();
        logging.debug('Processing hub tiles');

        return this.showPage(options);
      })
      .then((handled) => {
        if (handled) {
          return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
        }
        return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);

      });
  }

  showPage(options = {}) {
    const {
      pageId,
      pageOptions
    } = this._getPageArgs(options);

    return this._showPageView(pageId, pageOptions)
      .catch((error) => {
        logging.warn(error);
        logging.info(`The '${ pageId }' page for the Hub was unable to be shown. Falling back...`);
        throw error;
      })
      .then((handling = true) => {
        this._clearPageState();
        return handling;
      });
  }

  _getPageArgs(options = {}) {
    if (options.pageId != null) {
      return options;
    }

    return Object.assign(options, {
      pageId: this.pageState.pageId || HUB,
      pageOptions: _.omit(this.pageState.pageOptions || {}, 'navigate')
    });
  }

  _showPageView(pageId, pageOptions) {
    switch (HubTileIdentifiers.assertLegalValue(pageId)) {
      case HUB:
        return this.processRootHubPage(pageOptions);
      case ACHIEVEMENTS:
        return this.showAchievementsPage(pageOptions);
      case CERTIFICATION_TRAINING:
        return this.showCertificationTraining(pageOptions);
      case CERTIFICATE:
        return this.showCertificate(pageOptions);
      case INTRO_TRAINING:
        return this.showIntroTraining(pageOptions);
      case REFERRAL:
        return this.showReferralPage(pageOptions);
      case LEARNER_TRAINING:
        return this.showLearnerTrainingPage(pageOptions);
      case FORMAL_EXAM:
        return this.showFormalExamPage(pageOptions);
      case GUIDED_LEARNING:
        return this.showGuidedLearningPage(pageOptions);
      case SELF_DIRECTED_PATHS:
        return this.showSelfDirectedPathPage(pageOptions);
      case REFRESHER_TRAINING:
        return this.showRefresherTraining(pageOptions);
      case TIMELINE:
        return this.showTimeLinePage(pageOptions);
      case CONVERSATIONAL_SEARCH:
        return this.showConversationalSearchPage(pageOptions);
      default:
        return this.showHubTilePage(pageId, pageOptions);
    }
  }

  navigateHubPage(hash, navigateOptions = {}) {
    const path = combineUrlPaths('hub', hash);
    history.navigate(path, navigateOptions);
  }

  processRootHubPage(pageOptions) {
    const achievementsModalAsync = this.achievementsCongratulationCollection.fetch({showSpinner: false});
    const continueTrainingItemsAsync = this.getContinueTrainingItems();
    const communityListAsync = this.getIfHasAvailableCommunitiesWithPages();
    const getRecommendedArticlesList = this.parentProcessor.getRecommendedArticlesList();

    const promises = [
      achievementsModalAsync,
      continueTrainingItemsAsync,
      communityListAsync,
      getRecommendedArticlesList
    ];

    const propertyProvider = TenantPropertyProvider.get();

    if (propertyProvider.getProperty('openExternalLinks') !== 'DISABLED') {
      const quickLinksAsync = this.quickLinksList.fetch({ showSpinner: false });
      promises.push(quickLinksAsync);
    }

    if (propertyProvider.getProperty('executionPlatformEnabled')
      || propertyProvider.getProperty('communicationsPlatformEnabled')
      || propertyProvider.getProperty('consolidatedCommsEnabled')) {
      const todaysActivitiesOrderingAsync = this.todaysActivitiesOrderingModel.fetch({ showSpinner: false });
      promises.push(todaysActivitiesOrderingAsync);
    }

    const hubPageAsync = Promise.all(promises);

    return hubPageAsync.then(() => {
      return this.showHubPage(pageOptions);
    });
  }

  getDailyTrainingApplicable(session) {
    const currentAssessmentOptionModel = session.getCurrentAssessmentOptionsList().first();
    const sessionTrainingType = session.getTrainingSessionType();

    if (!this.trainingPaywallEnabled) {
      return !this.parentProcessor.isMinDailyTrainingFulfilled();
    }

    // Check for Daily Assessment training type for when Deep Link training is done
    // But Daily Training banner with DailyTraining AssessmentType should be shown
    return sessionTrainingType === SessionTrainingType.DailyTraining
      && !session.hasCompletedAssessmentOfType(AssessmentType.DailyTraining)
      && currentAssessmentOptionModel != null;
  }

  areNudgeTasksApplicable() {
    return this.isHubTileEnabled(EXECUTION_TASKS);
  }

  areNudgeOffersApplicable() {
    return this.isHubTileEnabled(CAMPAIGN_COMMUNICATIONS);
  }

  isConsolidatedCommsApplicable() {
    return this.isHubTileEnabled(CONSOLIDATED_COMMS);
  }

  showHubPage(pageOptions = {}) {
    // TODO: Remove if we ever deeplink to a dedicated nudge view instead of the hub
    if (pageOptions.nudgeId == null) {
      this.navigateHubPage(null, { replace: true });
    }

    const discoveryZoneEnabled = window.apps.base.getKnowledgeHref(this.hasSomeDiscoveryZoneCommunityAvailable());

    const options = {
      availableTiles: this.getAvailableTiles(),
      discoveryZoneEnabled,
      recommendedArticlesEnabled: this.recommendedArticlesEnabled,
      quickLinksList: this.quickLinksList,
      eventCheckinModel: this.eventCheckinModel,
      topicProgressItemList: this.topicProgressItemList,
      achievementsCongratulationCollection: this.achievementsCongratulationCollection,
      recommendedArticlesList: this.recommendedArticlesList,
      session: this.sessionModel,
      messageCollection: this.messageCollection,
      continueTrainingItems: this.continueTrainingItems,
      rewardsCollection: this.rewardsCollection,
      eventsCollection: this.eventsCollection,
      startCurrentSessionTraining: this.startOrResumeAssessment,
      assessmentCallback: (trainingOptions = {}) => {
        this.initiateTrainingFromTopicSearch(trainingOptions, { pageId: HUB });
      },
      brandingChannelModel: this.brandingChannelModel,
      isDailyTrainingApplicable: this.getDailyTrainingApplicable(this.sessionModel),
      areNudgeTasksApplicable: this.areNudgeTasksApplicable(),
      areNudgeOffersApplicable: this.areNudgeOffersApplicable(),
      isConsolidatedCommsApplicable: this.isConsolidatedCommsApplicable(),
      nudgeId: pageOptions.nudgeId,
      todaysActivitiesOrderingModel: this.todaysActivitiesOrderingModel,
      timelineNotifier: this.timelineNotifier
    };

    const hubPageFactory = this._getCreateHubPage.bind(this, options);

    if (this.parentProcessor.trainingInitiatorProvider) {
      return this.parentProcessor.trainingInitiatorProvider.getInitiatorAsync(hubPageFactory).then((candidateAssessmentInitiator) => {
        if (candidateAssessmentInitiator) {
          return candidateAssessmentInitiator.processSequenceFlow().catch(() => {
            //nom nom nom it's handling things
          });
        }

        app.layout.setView(hubPageFactory);
        return Promise.resolve();
      });
    }

    return Promise.resolve(false);
  }

  _getCreateHubPage(options, callback) {
    return PostTrainingHomePageController(Object.assign({}, options, { complete: callback }));
  }

  _getTrainingPage(options, callback) {
    const assessmentCallback = (trainingOptions = {}) => {
      this._storePageState(LEARNER_TRAINING, options);
      this.initiateTrainingFromTopicSearch(trainingOptions, {pageId: LEARNER_TRAINING});
    };

    const startTrainingCallback = (trainingOptions = {}) => {
      trainingOptions.setLaunchContext(EXTRA_TRAINING);
      this._storePageState(LEARNER_TRAINING, options);
      this.parentProcessor.startTraining(trainingOptions);
    };

    const learnerOptions = Object.assign(options,
      {
        topicTrainingCardsCollection: this.topicTrainingCardsCollection,
        pathTrainingCardsCollection: this.pathTrainingCardsCollection,
        catalogTrainingCardsCollection: this.catalogTrainingCardsCollection,
        catalogFilterSubjectList: this.catalogFilterSubjectList,
        assessmentCallback,
        startTrainingCallback,
        complete: callback,
        sessionModel: this.sessionModel
      });

    return LearnerTrainingControllerDefinitionFactory(
      learnerOptions
    );
  }

  showHubTilePage(pageId, pageOptions = {}) {
    if (this.isHubTileEnabled(pageId)) {
      return this.showHubTile(pageId, pageOptions);
    }
    return this.showHubPage();
  }

  showHubTile(pageId, pageOptions = {}) {
    logging.info(`Getting hub page: ${ pageId }`);

    const { navigate = true } = pageOptions;

    const {
      MobileClass,
      ViewClass,
      timeLogOptions,
      hash = () => {}
    } = ViewInfo[pageId];

    if (pageId === FEED) {
      Object.assign(pageOptions, {
        isFeedAvailable: this.parentProcessor.isTrainingPaywallDisabled || this.parentProcessor.isMinDailyTrainingFulfilled(),
        isConsolidatedCommsEnabled: this.isConsolidatedCommsApplicable()
      });
    } else if (pageId === MESSAGE) {
      Object.assign(pageOptions, { isConsolidatedCommsEnabled: this.isConsolidatedCommsApplicable() });
    }

    if (navigate) {
      this.navigateHubPage( hash(pageOptions) );
    }

    const HubTilePage = $os.mobile && MobileClass != null ? MobileClass : ViewClass;
    let timeLogId = null;

    const hubPageDefinition = {
      viewDefinition: Object.assign({
        ViewClass: HubTilePage,
        complete: () => {
          window.app.layout.flash.clear();
          logging.info(`Completing Hub Tile ${ pageId }, timeLogId: ${ timeLogId }`);
          try {
            window.apps.base.timeLogController.stop(timeLogId);
          } catch (e) {
            logging.error(e);
          }
        }
      }, pageOptions),
      delegateEvents: {
        'view:show': () => {
          if (timeLogOptions) {
            const modelId = this.sessionModel.get('id');
            timeLogId = window.apps.base.timeLogController.start(timeLogOptions, modelId);
            logging.info(`Showing Hub Tile ${ pageId }, timeLogId: ${ timeLogId }`);
          }
        },
        'view:assessment:extraTraining': (controller, view, topicId, level, launchContext) => {
          this._storePageState(pageId, pageOptions);

          this.initiateExtraTraining({
            view,
            topicId,
            level,
            launchContext
          });
        }
      }
    };

    window.app.layout.setView(hubPageDefinition);

    return Promise.resolve();
  }

  showTopicKnowledgeDetails(pageId, {
    topicId,
    level
  } = {}) {
    return this.showHubTile(pageId, {
      pageOptions: {
        showDetails: true,
        topicId,
        level
      }
    });
  }

  showAchievementsPage(initialTab) {
    const areAchievementsEnabled = this.isHubTileEnabled(ACHIEVEMENTS);
    const arePinsEnabled = this.isHubTileEnabled(RECOGNITION_PINS);

    if (areAchievementsEnabled || arePinsEnabled) {
      window.app.layout.setView(
        require('@common/modules/react').ReactControllerDefinitionFactory({
          component: import('@training/apps/achievements/AchievementsPage'),
          props: {
            initialTab,
            areAchievementsEnabled,
            arePinsEnabled
          }
        })
      );
    } else {
      this.showHubPage();
    }

    return Promise.resolve();
  }

  showCertificationTraining({ navigate = true } = {}) {
    if (navigate) {
      this.navigateHubPage('certifications');
    }

    const certificationController = this.parentProcessor.getCertificationTrainingAssessmentController();
    certificationController.setConfigurationProvider(
      new StaticAssessmentInitiatorConfigurationProvider({
        canContinue: false
      })
    );

    certificationController.processSequenceFlow({isFromHub: true}).then(() => {
      certificationController.startAssessmentSelection();
    })
      .catch(() => {
      //nom nom nom it's handling things
      });

    return Promise.resolve();
  }

  showIntroTraining({ navigate = true } = {}) {
    if (navigate) {
      this.navigateHubPage('newTopics');
    }

    const introController = this.parentProcessor.getIntroductoryTrainingAssessmentController();
    introController.setConfigurationProvider(
      new StaticAssessmentInitiatorConfigurationProvider({
        canContinue: false
      })
    );

    introController.processSequenceFlow({isFromHub: true}).then(() => {
      return introController.startAssessmentSelection();
    })
      .catch(() => {
      //nom nom nom it's handling things
      });

    return Promise.resolve();
  }

  showRefresherTraining({ navigate = true } = {}) {
    if (navigate) {
      this.navigateHubPage('refresher');
    }

    const refresherController = this.parentProcessor.getRefresherTrainingAssessmentController();
    refresherController.setConfigurationProvider(
      new StaticAssessmentInitiatorConfigurationProvider({
        canContinue: false
      })
    );

    refresherController.processSequenceFlow({isFromHub: true}).then(() => {
      return refresherController.startAssessmentSelection();
    })
      .catch(() => {
      //nom nom nom it's handling things
      });

    return Promise.resolve();
  }

  showFormalExamPage({ navigate = true } = {}) {
    if (navigate) {
      this.navigateHubPage('exams');
    }

    const examController = this.parentProcessor.getFormalExamAssessmentController();
    examController.setConfigurationProvider(
      new StaticAssessmentInitiatorConfigurationProvider({
        canContinue: false
      })
    );

    examController.processSequenceFlow({isFromHub: true}).then(() => {
      return examController.startAssessmentSelection();
    })
      .catch(() => {
      //nom nom nom it's handling things
      });

    return Promise.resolve();
  }

  showGuidedLearningPage(options = {}) {
    const controller = this.parentProcessor.getGuidedLearningController();

    return this._showPathPage(controller, GUIDED_LEARNING, options);
  }

  showSelfDirectedPathPage(options = {}) {
    const controller = this.parentProcessor.getSelfDirectedPathsController();

    return this._showPathPage(controller, SELF_DIRECTED_PATHS, options);
  }

  _showPathPage(controller, pageId, options) {
    const objectiveValidator = GuidedLearningContextualAvailabilityFactory(this.sessionModel).createForHub();

    // We only clear the cache if they are not coming from here
    if (!(this.pageState.pageId === pageId)) {
      controller.clearState();
    }

    controller.setConfigurationProvider(
      new StaticAssessmentInitiatorConfigurationProvider({
        continuePredicate() {
          return false;
        },
        objectiveValidator,
        routePrefix: 'hub'
      })
    );

    controller.showPageFlow(options)
      .catch((exception) => {
        // all other errors dump the user back up to the session controllers flow
        logging.error(exception);
        this.processSequenceFlow({ pageId: HUB });
      });

    return Promise.resolve();
  }

  showPreliminaryReferralPage(options = {}) {
    return this.showHubTile(PRELIMINARY_REFERRAL, {
      referralStatus: this.referralStatus,
      showBeforeHub: true,
      onContinue: () => {
        this.referralStatus.setDueToSeeInitialReferralPage();
        if (_.isFunction(options.onComplete)) {
          options.onComplete();
        }
      }
    });
  }

  showReferralPage() {
    return this.showHubTile(REFERRAL, {
      referralStatus: this.referralStatus
    });
  }

  showLearnerTrainingPage(options = {}) {
    const {
      navigate = true
    } = options;

    if (navigate) {
      this.navigateHubPage(combineUrlPaths('training', (options.initialTab ? options.initialTab : 'topics')));
    }

    const trainingPageFactory = this._getTrainingPage.bind(this, options);

    if (this.parentProcessor.trainingInitiatorProvider) {
      return this.parentProcessor.trainingInitiatorProvider.getInitiatorAsync(trainingPageFactory).then((candidateAssessmentInitiator) => {
        if (candidateAssessmentInitiator) {
          return candidateAssessmentInitiator.processSequenceFlow().catch(() => {
            //nom nom nom it's handling things
          });
        }

        app.layout.setView(trainingPageFactory);
        return Promise.resolve();
      });
    }

    return Promise.resolve(false);
  }

  showTimeLinePage(options = {}) {
    if (this.isHubTileEnabled(TIMELINE)) {
      window.app.layout.setView(
        require('@common/modules/react').ReactControllerDefinitionFactory({
          component: import('@training/apps/timeline/TimelinePage'),
          props: {
            isConsolidatedCommsEnabled: this.isConsolidatedCommsApplicable(),
            isFeedEnabled: this.isHubTileEnabled(FEED),
            timelineNotifier: this.timelineNotifier,
            userMetadata: options.userMetadata,
            targetReferenceItemId: options.targetReferenceItemId,
            targetTab: options.targetTab,
            targetSearchQuery: options.targetSearchQuery,
            removeBroadcastFromQueue: this.removeBroadcastFromQueue,
            scrollParent: window.app.layout.getMainScrollContainer()
          }
        })
      );
    } else {
      this.showHubPage();
    }

    return Promise.resolve();
  }

  isConversationalSearchEnabled() {
    return TenantPropertyProvider.get().getProperty('conversationalSearchEnabled');
  }

  showConversationalSearchPage() {
    if (this.isConversationalSearchEnabled()) {
      window.app.layout.setView(ConversationalSearchControllerDefinitionFactory());
    } else {
      this.showHubPage();
    }

    return Promise.resolve();
  }

  startOrResumeAssessment(trainingOption) {
    trainingOption.setLaunchContext(START_PAGE);

    const assessmentType = trainingOption.getForAssessmentType();

    if (assessmentType === AssessmentType.DailyTraining) {
      return this.parentProcessor.resumeAssessment(trainingOption)
        .catch((xhr = {}) => {
          const errCode = AxonifyExceptionFactory.fromResponse(xhr).getErrorCode();
          const hasCustomErrorMessage = I18n.hasString(`errors.assessment.${ errCode }`);
          const errorMessage = hasCustomErrorMessage ? I18n.t(`errors.assessment.${ errCode }`)
            : I18n.t('errors.assessment.errorCreatingAssessment');
          window.app.layout.flash.error(errorMessage, 7000);
          this.getContinueTrainingItems().then(() => {
            this.showHubPage();
          });
        });
    }

    return this.parentProcessor.startTraining(trainingOption);
  }

  initiateExtraTraining(options = {}) {
    const {
      view,
      topicId,
      level,
      launchContext
    } = options;

    const assessmentTopicOption = new AssessmentTopicOption({
      topic: {
        id: topicId
      },
      level,
      launchContext
    });
    assessmentTopicOption.setForAssessmentType(AssessmentType.ExtraTraining);

    const extraTrainingAssessmentController = this.parentProcessor.getExtraTrainingAssessmentController();
    extraTrainingAssessmentController.setConfigurationProvider(
      new StaticAssessmentInitiatorConfigurationProvider({
        canContinue: true
      })
    );

    extraTrainingAssessmentController.createAndProcessAssessment(assessmentTopicOption)
      .catch(() => {
        if (view && view.getKnowledgeMap) {
          view.getKnowledgeMap({reset: true});
        }
      });
  }

  initiateTrainingFromTopicSearch(options = {}, pageContext = {}) {
    const {
      topicId,
      level,
      assessmentType,
      programId,
      isRetake,
      isPopQuiz,
      launchContext
    } = options;

    const assessmentOptions = {
      topic: {
        id: topicId
      },
      level,
      isPopQuiz,
      isRetake,
      launchContext
    };

    if (programId != null) {
      Object.assign(assessmentOptions, { programId });
    }

    const assessmentTopicOption = new AssessmentTopicOption(assessmentOptions);
    assessmentTopicOption.setForAssessmentType(assessmentType);

    const assessmentController = this.parentProcessor.getSelfDirectedAssessmentInitiatorController();
    assessmentController.setConfigurationProvider(
      new StaticAssessmentInitiatorConfigurationProvider({
        assessmentType,
        pageContext
      })
    );

    assessmentController.createAndProcessAssessment(assessmentTopicOption);
  }
}

module.exports = HubController;
