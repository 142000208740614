const $os = require('detectOS');

function addTodayActivitiesRegionStyles(view, isQuickLinksOnHub) {
  const todaysActivitiesRegion = view.$el.find('.titled-section__region').first();
  const hasHomePageUserStats = view.$el.find('.home-page-layout__region.stats').first().length;

  if (!isQuickLinksOnHub || $os.mobile) {
    // When quick links are displayed on hub, Today's Activities are shifted down below the branded background
    // image and there is no need for branding the text
    view.$el.find('.titled-section__region--title').first()
      .addClass('titled-section__region--overlay');
  } else if (!hasHomePageUserStats) {
    // when there are no stats displayed, we need to add extra margin to the Today's Activities
    todaysActivitiesRegion.addClass('titled-section__region--extra-margin');
  }
}

module.exports = {
  addTodayActivitiesRegionStyles
}
