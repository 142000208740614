const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');

const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');

const TitledSectionControllerDefinition = require('@common/components/titledSection/TitledSectionControllerDefinitionFactory');
const I18n = require('@common/libs/I18n');

const HomePageLayoutController = require('@training/apps/home/layout/HomePageLayoutController');
const PostTrainingTodaysActivitiesDefinitionFactory = require('@training/apps/home/activities/PostTrainingTodaysActivitiesDefinitionFactory');
const ContinueTrainingDefinitionFactory = require('@training/apps/home/continueTraining/ContinueTrainingDefinitionFactory');
const GreetingsDefinitionFactory = require('@training/apps/home/greeting/GreetingsDefinitionFactory');
const MainStatsDefinitionFactory = require('@common/components/stats/MainStatsDefinitionFactory').default;
const QuickLinksDefinitionFactory = require('@training/apps/home/quickLinks/QuickLinksDefinitionFactory').default;
const PerformanceDefinitionFactory = require('@training/apps/home/performance/PerformanceDefinitionFactory');
const DZButtonDefinitionFactory = require('@training/apps/home/dzButton/DZButtonDefinitionFactory');
const RecommendedArticlesDefinitionFactory = require('@training/apps/home/recommendedArticles/RecommendedArticlesDefinitionFactory');
const HubPreviewsDefinitionFactory = require('@training/apps/home/hubPreviews/HubPreviewsDefinitionFactory');
const AchievementsCongratulatoryModalContentFactory = require('@training/apps/home/achievementsModal/AchievementsCongratulatoryModalContentFactory');

const { addTodayActivitiesRegionStyles} = require('@training/apps/home/layout/utils');

module.exports = (options = {}) => {
  const {
    session,
    quickLinksList,
    topicProgressItemList,
    continueTrainingItems,
    messageCollection,
    discoveryZoneEnabled,
    recommendedArticlesEnabled,
    rewardsCollection,
    eventsCollection,
    availableTiles,
    startCurrentSessionTraining,
    assessmentCallback,
    eventCheckinModel,
    achievementsCongratulationCollection,
    recommendedArticlesList,
    brandingChannelModel,
    isDailyTrainingApplicable,
    isConsolidatedCommsApplicable,
    complete,
    areNudgeTasksApplicable,
    areNudgeOffersApplicable,
    nudgeId,
    todaysActivitiesOrderingModel,
    timelineNotifier
  } = options;

  const moreTrainingDefinition = TitledSectionControllerDefinition(I18n.t('start.homepage.continueTrainingHeader'), ContinueTrainingDefinitionFactory({
    continueTrainingItems,
    session,
    assessmentCallback,
    startCurrentSessionTraining
  }));

  const performanceDefinition = TitledSectionControllerDefinition(I18n.t('start.homepage.performanceHeader'), PerformanceDefinitionFactory(availableTiles, topicProgressItemList, session));
  const moreTrainingAndPerformanceDefinitions = [performanceDefinition];

  if (!isDailyTrainingApplicable) {
    moreTrainingAndPerformanceDefinitions.unshift(moreTrainingDefinition);
  }

  const skipTrainingCallback = () => {
    session.skipTraining().done(() => {
      window.location.reload();
    })
  };

  return () => {
    const childControllers = [
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('greeting'), GreetingsDefinitionFactory(session, window.apps.auth.session.user, session.isMinDailyTrainingFulfilled())),
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('stats'), MainStatsDefinitionFactory(availableTiles)),
      TitledSectionControllerDefinition(I18n.t('start.homepage.todaysActivities'), PostTrainingTodaysActivitiesDefinitionFactory({
        session,
        startCurrentSessionTraining,
        eventCheckinModel,
        isDailyTrainingApplicable,
        isConsolidatedCommsApplicable,
        complete,
        areNudgeTasksApplicable,
        areNudgeOffersApplicable,
        nudgeId,
        skipTrainingCallback,
        todaysActivitiesOrderingModel,
        timelineNotifier
      })),
      ...moreTrainingAndPerformanceDefinitions,
      DZButtonDefinitionFactory(recommendedArticlesList, discoveryZoneEnabled, recommendedArticlesEnabled),
      TitledSectionControllerDefinition(I18n.t('start.homepage.recommendedArticlesHeader'), RecommendedArticlesDefinitionFactory(recommendedArticlesList, discoveryZoneEnabled, recommendedArticlesEnabled)),
      TitledSectionControllerDefinition(I18n.t('start.homepage.hubPreviewsHeader'), HubPreviewsDefinitionFactory(availableTiles, messageCollection, rewardsCollection, eventsCollection, apps.auth.session.user))
    ];

    const isQuickLinksOnHub = quickLinksList != null && quickLinksList.length > 0;
    if (isQuickLinksOnHub) {
      childControllers.splice(2, 0, StackedLayoutViewController.createConfigObject(
        ViewOptionHelpers.classNameAppender('quickLinks'),
        QuickLinksDefinitionFactory(quickLinksList, 'homepage')
      ));
    }

    return HomePageLayoutController({
      brandingChannelModel,
      user: window.apps.auth.session.user,
      contentRegion: {
        ViewControllerClass: StackedLayoutViewController,
        viewDefinition: {
          className: 'home-page-layout home-page-layout--post-training ax-container',
          childViewOptions: {
            className: 'home-page-layout__region'
          }
        },
        childControllers,
        delegateEvents: {
          'view:inflate': (controller, view) => {
            window.apps.base.timeLogController.bindPageViewLog(view, 'PostTrainingHub');
          },
          'view:show': () => {
            window.app.layout.setTitle(I18n.t('hub.title'));

            AchievementsCongratulatoryModalContentFactory.buildModal(achievementsCongratulationCollection);
          },
          'view:attach': (controller, view) => {
            addTodayActivitiesRegionStyles(view, isQuickLinksOnHub);
          }
        }
      }
    });
  };
};
