import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  TablePagination as MuiTablePagination,
  type TablePaginationTypeMap as MuiTablePaginationTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTablePaginationCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTablePaginationTypeMap<P = object, D extends React.ElementType = 'div'> = MuiTablePaginationTypeMap<P & AxTablePaginationCustomProps, D>;

export type AxTablePaginationProps<
  D extends ElementType = AxTablePaginationTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTablePaginationTypeMap<P & AxTablePaginationCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTablePagination'
};

const StyledAxTablePagination = styled(MuiTablePagination, styledOptions)<AxTablePaginationProps>(() => {
  // TODO - update to use UI Kit styles
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=6013-9216&mode=design&t=7poBe9Dfwla5L23E-0
  return {};
});

export const AxTablePagination: MuiOverridableComponent<AxTablePaginationTypeMap> = forwardRef(({
  ...otherProps
}: AxTablePaginationProps, ref: Ref<unknown>) => {
  const TablePaginationProps: AxTablePaginationProps = {
    ...otherProps
  };

  return (
    <StyledAxTablePagination { ...TablePaginationProps } ref={ ref }/>
  );
});

export default AxTablePagination;
