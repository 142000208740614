import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  Step as MuiStep,
  type StepTypeMap as MuiStepTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxStepCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxStepTypeMap<P = object, D extends React.ElementType = 'div'> = MuiStepTypeMap<P & AxStepCustomProps, D>;

export type AxStepProps<
  D extends ElementType = AxStepTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxStepTypeMap<P & AxStepCustomProps, D>, D>;

const styledOptions = {
  name: 'AxStep'
};

const StyledAxStep = styled(MuiStep, styledOptions)<AxStepProps>(() => {
  // TODO - update to use common variables
  return {};
});

export const AxStep: MuiOverridableComponent<AxStepTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxStepProps, ref: Ref<HTMLDivElement>) => {
  const StepProps: AxStepProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxStep { ...StepProps } ref={ ref } >
      { children }
    </StyledAxStep>
  );
});

export default AxStep;
