const logging = require('logging');

class SCORMManifestFetcher {
  constructor(options = {}) {
    // override the ajax call if passed in
    this.ajax = options.ajax != null ? options.ajax : (ajaxOptions) => {
      return $.ajax(Object.assign({ shouldRetry: false }, ajaxOptions));
    };
  }

  getManifest(options = {}) {
    logging.debug('SCORMManifestFetcher.getManifest');
    $.extend(options, {
      async: false,
      skipGlobalHandler: true,
      contentType: 'text/xml',
      dataType: 'xml',
      error: () => {
        logging.error(`Error loading SCORM:${ options.url }!`);
        throw new Error('Error loading SCORM');
      }
    });

    return this.ajax(options);
  }
}

module.exports = SCORMManifestFetcher;
