const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');
const SearchBox = require('@common/components/searchBox/SearchBox');
const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');
const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');
const I18n = require('@common/libs/I18n');
const LoadingController = require('@common/components/loading/LoadingController');

module.exports = (collection, getCardViewDefinitionFn) => {
  return {
    tab: {
      tabName: 'topics',
      tabText: I18n.t('learnerTrainingPage.tabTitle.topic'),
      tabClass: 'ax-grid__col--4 ax-grid__col--m-auto-size ax-font ax-font__m'
    },
    tabContentController: () => {
      return {
        ViewControllerClass: StackedLayoutViewController,
        viewDefinition: {
          className: 'learner-training-tab-content'
        },
        childControllers: [
          StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('learner-training-search-box ax-grid ax-grid--no-gutter'), {
            viewDefinition: {
              ViewClass: SearchBox,
              placeholderText: I18n.t('learnerTrainingPage.search.topics'),
              className: 'search-box ax-grid__col--12 ax-grid__col--m-6',
              collection,
              getSearchQuery: (searchTerm) => {
                collection.search(searchTerm);
              },
              clearSearch: () => {
                collection.search();
              }
            },
            delegateEvents: {
              'view:inflate': function() {
                collection.reset();

                if (collection.extraParams && collection.extraParams.search) {
                  this.getView().clearSearch();
                }
              }
            }
          }),
          StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('learner-training-stacked-content'), {
            ViewControllerClass: LoadingController.InfiniteCollectionView,
            collection,
            contentControllerDefinition: {
              ViewControllerClass: CollectionLayoutViewController,
              collection,
              viewDefinition: {
                tagName: 'ul',
                className: 'ax-grid search__results',
                childViewOptions: {
                  tagName: 'li',
                  className: 'list-item touchable ax-grid__col--12 ax-grid__col--m-6 ax-grid__col--l-4'
                }
              },

              ChildViewControllerDefinition: (model) => {
                return getCardViewDefinitionFn(model);
              }
            }
          })
        ]
      }
    }
  }
}
