import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  MenuList as MuiMenuList,
  type MenuListTypeMap as MuiMenuListTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxMenuListCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxMenuListTypeMap<P = object, D extends React.ElementType = 'ul'> = MuiMenuListTypeMap<P & AxMenuListCustomProps, D>;

export type AxMenuListProps<
  D extends ElementType = AxMenuListTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxMenuListTypeMap<P & AxMenuListCustomProps, D>, D>;

const styledOptions = {
  name: 'AxMenuList'
};

const StyledAxMenuList = styled(MuiMenuList, styledOptions)<AxMenuListProps>(() => {
  // TODO - update styles to align with UI Kit
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7246-4597&mode=design&t=56zA1ikdwx5wdCWX-0
  return {};
});

export const AxMenuList: MuiOverridableComponent<AxMenuListTypeMap> = forwardRef(({
  ...otherProps
}: AxMenuListProps, ref: Ref<HTMLUListElement>) => {
  const MenuListProps: AxMenuListProps = {
    ...otherProps
  };

  return (
    <StyledAxMenuList { ...MenuListProps } ref={ ref } />
  );
});

export default AxMenuList;
