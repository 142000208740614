const { ItemView } = require('Marionette');

class PretextHeaderTitle extends ItemView {
  className() {
    return 'subtext-header-title qa-subtext';
  }

  getTemplate() {
    return `
      <p class="<%= pretextClass %> qa-subtext-text"><%= pretext %></p>
      <<%= titleTag %> class="<%= titleClass %> qa-subtext-title"><%= title %></<%= titleTag %>>
    `;
  }

  templateHelpers() {
    return {
      title: this.getOption('title'),
      pretext: this.getOption('pretext'),
      titleClass: this.getOption('titleClass'),
      titleTag: this.getOption('titleTag') || 'p',
      pretextClass: this.getOption('pretextClass')
    };
  }
}

module.exports = PretextHeaderTitle;
