const Backbone = require('Backbone');
const { LayoutView } = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const SearchBox = require('@common/components/searchBox/SearchBox');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const I18n = require('@common/libs/I18n');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const CatalogFilterView = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogFilterView');
const CatalogFilterButtonView = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogFilterButtonView');
const CatalogListResultsViewController = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogListResultsViewController');

const BASE_CATALOG_URL = '#hub/training/catalog';

const _buildURL = (queryString, pageNum, type) => {
  const urlQuery = queryString || '';
  const encodedUrlQuery = encodeURIComponent(encodeURIComponent(urlQuery));
  return UrlHelpers.combineUrlPaths(BASE_CATALOG_URL, 'type-' + type, pageNum.toString(), encodedUrlQuery);
};

const _updateResultCount = (collection) => {
  const resultCount = collection.getResultCount();
  if (resultCount === 1) {
    $('.learner-training-catalog-list-region__result-count').html(I18n.t('learnerTrainingPage.catalog.filter.resultsSingular'));
  } else {
    $('.learner-training-catalog-list-region__result-count').html(I18n.t('learnerTrainingPage.catalog.filter.resultsPlural', { resultCount }));
  }
};

module.exports = (collection, filterSubjectList, getCardViewDefinitionFn) => {
  return {
    tab: {
      tabName: 'catalog',
      tabText: I18n.t('learnerTrainingPage.tabTitle.catalog'),
      tabClass: 'ax-grid__col--4 ax-grid__col--m-auto-size ax-font ax-font__m'
    },
    tabContentController: () => {
      return {
        ViewControllerClass: LayoutController,
        viewDefinition: {
          ViewClass: LayoutView,
          className: 'learner-training-tab-content learner-training-tab-content--catalog ax-grid ax-grid--no-gutter',
          template: `
          <div class="ax-grid__col--12">
            <div class="ax-grid ax-grid--no-gutter">
              <div class="learner-training-search-box ax-grid__col--10 ax-grid__col--m-6"></div>
              <div class="learner-training-filter-button-region ax-grid__col--2"></div>
            </div>
            <p class="learner-training-catalog-list-region__result-count"><%- t('learnerTrainingPage.catalog.filter.resultsPlural', { resultCount: 0 }) %></p>
          </div>
          <div class="learner-training-catalog-list-region ax-grid__col--12 ax-grid__col--m-8 ax-grid__col--l-8"></div>
          <div class="learner-training-catalog-filter-region ax-grid__col--12 ax-grid__col--m-4 ax-grid__col--l-4"></div>
        `,
          regions: {
            searchRegion: '.learner-training-search-box',
            filterButtonRegion: '.learner-training-filter-button-region',
            listRegion: '.learner-training-catalog-list-region',
            filterRegion: '.learner-training-catalog-filter-region'
          }
        },
        regionControllers: {
          searchRegion: {
            viewDefinition: {
              ViewClass: SearchBox,
              placeholderText: I18n.t('learnerTrainingPage.search.catalog'),
              className: 'search-box',
              collection,
              getSearchQuery: (searchTerm) => {
                collection.applySearchParams(searchTerm);
              },
              clearSearch: () => {
                collection.resetSearchParams();
                triggerResize(true);
              }
            },
            delegateEvents: {
              'view:attach': (view, searchBox) => {
                if (collection.getQueryString()) {
                  const searchQuery = collection.getQueryString();
                  searchBox.ui.input.val(searchQuery);
                  searchBox.$el.toggleClass('search-box--active', true);
                }
              }
            }
          },
          filterButtonRegion: {
            viewDefinition: {
              ViewClass: CatalogFilterButtonView,
              filterCollection: collection,
              filterURLBuilder: _buildURL,
              filterSubjectList
            }
          },
          listRegion: {
            ViewControllerClass: CatalogListResultsViewController,
            collection,
            childViewControllerDefinitionFn: getCardViewDefinitionFn,
            delegateEvents: {
              'view:dom:mutate': () => {
                triggerResize(true);
              },
              'view:attach': (controller, view) => {
                _updateResultCount(collection);

                view.listenTo(collection, 'sync', () => {
                  triggerResize(true);
                  Backbone.history.navigate(
                    _buildURL(collection.getQueryString(), collection.state.currentPage, collection.getBrowseType()),
                    {
                      trigger: false,
                      replace: true
                    }
                  );

                  _updateResultCount(collection);
                });

                view.listenTo(Backbone.Wreqr.radio.channel('global').vent, 'pagination:clicked', () => {
                  $('#page-view > .adjustable-container').scrollTop(0);
                });
              }
            }
          },
          filterRegion: {
            viewDefinition: {
              ViewClass: CatalogFilterView,
              filterCollection: collection,
              filterURLBuilder: _buildURL,
              filterSubjectList
            }
          }
        }
      };
    }
  }
}
