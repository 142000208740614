import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  AccordionSummary as MuiAccordionSummary,
  type AccordionSummaryTypeMap as MuiAccordionSummaryTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxAccordionSummaryCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - centerRipple - remove
  // - disableRipple - remove
  // - disableTouchRipple - remove
  // - focusRipple - remove
  // - TouchRippleProps - remove
  // - touchRippleRef - remove
};

export type AxAccordionSummaryTypeMap<P = object, D extends React.ElementType = 'div'> = MuiAccordionSummaryTypeMap<P & AxAccordionSummaryCustomProps, D>;

export type AxAccordionSummaryProps<
  D extends ElementType = AxAccordionSummaryTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxAccordionSummaryTypeMap<P & AxAccordionSummaryCustomProps, D>, D>;

const styledOptions = {
  name: 'AxAccordionSummary'
};

const StyledAxAccordionSummary = styled(MuiAccordionSummary, styledOptions)<AxAccordionSummaryProps>(() => {
  // TODO - update to use UI Kit Accordion styles
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=5020-6316&mode=design&t=BPAww5mYbYFz5Q1j-0
  return {};
});

export const AxAccordionSummary: MuiOverridableComponent<AxAccordionSummaryTypeMap> = forwardRef(({
  ...otherProps
}: AxAccordionSummaryProps, ref: Ref<HTMLDivElement>) => {
  const AccordionSummaryProps: AxAccordionSummaryProps = {
    ...otherProps
  };

  return (
    <StyledAxAccordionSummary { ...AccordionSummaryProps } ref={ ref }/>
  );
});

export default AxAccordionSummary;
