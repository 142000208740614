import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  Tabs as MuiTabs,
  type TabsTypeMap as MuiTabsTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTabsCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - indicatorColor - remove and add intelligent default
  // - textColor - remove and add intelligent default
};

export type AxTabsTypeMap<P = object, D extends React.ElementType = 'button'> = MuiTabsTypeMap<P & AxTabsCustomProps, D>;

export type AxTabsProps<
  D extends ElementType = AxTabsTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTabsTypeMap<P & AxTabsCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTabs'
};

const StyledAxTabs = styled(MuiTabs, styledOptions)<AxTabsProps>(() => {
  // TODO - update styles to align with UI Kit
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7246-4627&mode=design&t=56zA1ikdwx5wdCWX-0
  return {};
});

export const AxTabs: MuiOverridableComponent<AxTabsTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTabsProps, ref: Ref<HTMLButtonElement>) => {
  const TabsProps: AxTabsProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTabs { ...TabsProps } ref={ ref }>
      { children }
    </StyledAxTabs>
  );
});

export default AxTabs;
