import {
  FormHelperText as MuiFormHelperText,
  styled,
  type FormHelperTextTypeMap as MuiFormHelperTextTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxFormHelperTextCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - margin - is this needed?
  // - variant - remove
};

export type AxFormHelperTextTypeMap<P = object, D extends React.ElementType = 'div'> = MuiFormHelperTextTypeMap<P & AxFormHelperTextCustomProps, D>;

export type AxFormHelperTextProps<
  D extends ElementType = AxFormHelperTextTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxFormHelperTextTypeMap<P & AxFormHelperTextCustomProps, D>, D>;

const styledOptions = {
  name: 'AxFormHelperText'
};

const StyledAxFormHelperText = styled(MuiFormHelperText, styledOptions)<AxFormHelperTextProps>(() => {
  // TODO - update to align with UI Kit
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=5099-5555&mode=design&t=BPAww5mYbYFz5Q1j-0
  return {};
});

export const AxFormHelperText: MuiOverridableComponent<AxFormHelperTextTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxFormHelperTextProps, ref: Ref<HTMLDivElement>) => {
  const FormHelperTextProps: AxFormHelperTextProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxFormHelperText { ...FormHelperTextProps } ref={ ref }>
      { children }
    </StyledAxFormHelperText>
  );
});

export default AxFormHelperText;
