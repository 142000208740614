import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  StepConnector as MuiStepConnector,
  type StepConnectorProps as MuiStepConnectorProps
} from '@mui/material';

export type AxStepConnectorProps = MuiStepConnectorProps;

const styledOptions = {
  name: 'AxStepConnector'
};

const StyledAxStepConnector = styled(MuiStepConnector, styledOptions)<AxStepConnectorProps>(() => {
  // TODO - update to use common variables
  return {};
});

export const AxStepConnector = forwardRef(({
  ...otherProps
}: AxStepConnectorProps, ref: Ref<HTMLDivElement>) => {
  const StepConnectorProps: AxStepConnectorProps = {
    ...otherProps
  };

  return (
    <StyledAxStepConnector { ...StepConnectorProps } ref={ ref } />
  );
});

export default AxStepConnector;
