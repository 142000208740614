const $os = require('detectOS');

const shouldRunLegacyMediaElement = () => {
  return $os.browser === 'ie' && !['8', '8.1', '10'].includes($os.windowsVersion);
};

const getMediaElementSheets = () => {
  if (shouldRunLegacyMediaElement()) {
    return ['common/vendor/mediaelement/v3/mediaelementplayer-legacy.css'];
  }
  return ['common/vendor/mediaelement/v4/mediaelementplayer-legacy.css'];

};

module.exports = {
  getMediaElementSheets,
  shouldRunLegacyMediaElement
};
