import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  TableHead as MuiTableHead,
  type TableHeadTypeMap as MuiTableHeadTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTableHeadCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableHeadTypeMap<P = object, D extends React.ElementType = 'thead'> = MuiTableHeadTypeMap<P & AxTableHeadCustomProps, D>;

export type AxTableHeadProps<
  D extends ElementType = AxTableHeadTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableHeadTypeMap<P & AxTableHeadCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTableHead'
};

const StyledAxTableHead = styled(MuiTableHead, styledOptions)<AxTableHeadProps>(() => {
  // TODO - update to use UI Kit styles
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=6013-9216&mode=design&t=7poBe9Dfwla5L23E-0
  return {};
});

export const AxTableHead: MuiOverridableComponent<AxTableHeadTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableHeadProps, ref: Ref<HTMLTableSectionElement>) => {
  const TableHeadProps: AxTableHeadProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableHead { ...TableHeadProps } ref={ ref }>
      { children }
    </StyledAxTableHead>
  );
});

export default AxTableHead;
