import {
  SvgIcon,
  useTheme
} from '@mui/material';

export function IndeterminateCheckBox({ disabled = false }: { disabled?: boolean }) {
  const theme = useTheme();
  return disabled ? (
    <SvgIcon viewBox='0 0 20 20' sx={{
      fill: 'none'
    }}>
      <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' fill={ theme.uiKit.colorGrey20 }/>
      <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' stroke={ theme.uiKit.colorGrey30 }/>
      <rect x='5' y='9' width='10' height='2' rx='1' fill={ theme.uiKit.colorGrey50 }/>
    </SvgIcon>
  ) : (
    <SvgIcon viewBox='0 0 20 20' sx={{
      fill: 'none'
    }}>
      <rect width='20' height='20' rx='4' fill={ theme.uiKit.linkColor }/>
      <rect x='5' y='9' width='10' height='2' rx='1' fill={ theme.uiKit.colorWhite }/>
    </SvgIcon>
  );
}
