import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  TabScrollButton as MuiTabScrollButton,
  type TabScrollButtonProps as MuiTabScrollButtonProps
} from '@mui/material';

export type AxTabScrollButtonProps = MuiTabScrollButtonProps;

const styledOptions = {
  name: 'AxTabScrollButton'
};

const StyledAxTabScrollButton = styled(MuiTabScrollButton, styledOptions)<AxTabScrollButtonProps>(() => {
  // TODO - update to use common variables
  return {};
});

export const AxTabScrollButton = forwardRef(({
  ...otherProps
}: AxTabScrollButtonProps, ref: Ref<HTMLButtonElement>) => {
  const TabScrollButtonProps: AxTabScrollButtonProps = {
    ...otherProps
  };

  return (
    <StyledAxTabScrollButton { ...TabScrollButtonProps } ref={ ref } />
  );
});

export default AxTabScrollButton;
