const { ItemView } = require('Marionette');

class BasicHeaderTitle extends ItemView {

  className() {
    return 'basic-header-title qa-header';
  }

  getTemplate() {
    return `
      <<%= titleTag %> class="<%= titleClass %> qa-header-title"><%= title %></<%= titleTag %>>
    `;
  }

  templateHelpers() {
    return {
      title: this.getOption('title'),
      titleTag: this.getOption('titleTag') || 'p',
      titleClass: this.getOption('titleClass')
    };
  }
}

module.exports = BasicHeaderTitle;
