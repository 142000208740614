import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  Tab as MuiTab,
  type TabTypeMap as MuiTabTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTabCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - centerRipple - remove and add intelligent default
  // - disableFocusRipple - remove and add intelligent default
  // - disableRipple - remove and add intelligent default
  // - disableTouchRipple - remove and add intelligent default
  // - focusRipple - remove and add intelligent default
  // - TouchRippleProps - remove and add intelligent default
  // - touchRippleRef - remove and add intelligent default
};

export type AxTabTypeMap<P = object, D extends React.ElementType = 'div'> = MuiTabTypeMap<P & AxTabCustomProps, D>;

export type AxTabProps<
  D extends ElementType = AxTabTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTabTypeMap<P & AxTabCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTab'
};

const StyledAxTab = styled(MuiTab, styledOptions)<AxTabProps>(() => {
  // TODO - update styles to align with UI Kit
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7246-4627&mode=design&t=56zA1ikdwx5wdCWX-0
  return {};
});

export const AxTab: MuiOverridableComponent<AxTabTypeMap> = forwardRef(({
  ...otherProps
}: AxTabProps, ref: Ref<HTMLDivElement>) => {
  const TabProps: AxTabProps = {
    ...otherProps
  };

  return (
    <StyledAxTab { ...TabProps } ref={ ref } />
  );
});

export default AxTab;
