const _ = require('underscore');

const InfiniteScrollViewMixin = {
  p: 0,
  rpp: 10,
  isLoadingPage: false,
  reset: false,

  initialize() {
    this.throttleLoadNext = _.throttle(_.bind(this.loadNext, this), 100);
  },

  onAttach() {
    this.setScrollContainer();
    this.$scrollContainer.on('scroll', this.throttleLoadNext);
  },

  viewDidAppear() {
    this.setScrollContainer();
    this.$scrollContainer.on('scroll', this.throttleLoadNext);
  },

  onClose() {
    this.$scrollContainer.off('scroll', this.throttleLoadNext);
  },

  onDestroy() {
    if (this.$scrollContainer) {
      this.$scrollContainer.off('scroll', this.throttleLoadNext);
    }
  },

  loadNext() {
    // If the class that mixes in this library has a shouldLoadMorePages function
    // defined, use it instead of our default.
    const shouldLoadMorePages = this.shouldLoadMorePages || this._shouldLoadMorePages;

    if (shouldLoadMorePages(this.$scrollContainer) && !this.noMorePages) {
      return this.onLoadNext();
    }

    return undefined;
  },

  _shouldLoadMorePages(scrollContainer) {
    const pageBottom = scrollContainer.scrollTop() + scrollContainer.outerHeight();
    const childrenSize = scrollContainer.children().outerHeight(true);
    const fetchZone = 100; // the number of pixels left until we run out of items

    return (childrenSize - pageBottom) < fetchZone;
  },

  setScrollContainer() {
    if ((this.$scrollContainer == null)) {
      this.$scrollContainer = this.$el;
    }
  },

  fetchNext() {
    if (this.isLoadingPage) {
      return;
    }
    this.isLoadingPage = true;

    const dataOptions = $.extend({}, this.fetchDataOptions, {
      p: this.p,
      rpp: this.rpp
    });

    const fetchOptions = {
      add: true,
      remove: false,
      update: true,
      data: dataOptions,
      reset: this.reset,
      complete: () => {
        this.isLoadingPage = false;
        this.p += 1;
        this.reset = false;
      },
      success: (collection, response) => {
        this.fetchSuccess(response);
      }
    };

    this.collection.fetch(fetchOptions);
  }
};

module.exports = InfiniteScrollViewMixin;
