import {
  FormGroup as MuiFormGroup,
  styled,
  type FormGroupProps as MuiFormGroupProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type AxFormGroupProps = MuiFormGroupProps;

const styledOptions = {
  name: 'AxFormGroup'
};

const StyledAxFormGroup = styled(MuiFormGroup, styledOptions)<AxFormGroupProps>(() => {
  // TODO - update to align with UI Kit
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=5099-5555&mode=design&t=BPAww5mYbYFz5Q1j-0
  return {};
});

export const AxFormGroup = forwardRef(({
  ...otherProps
}: AxFormGroupProps, ref: Ref<HTMLDivElement>) => {
  const FormGroupProps: AxFormGroupProps = {
    ...otherProps
  };

  return (
    <StyledAxFormGroup { ...FormGroupProps } ref={ ref } />
  );
});

export default AxFormGroup;
