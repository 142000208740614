import { fieldRequiredAsteriskStyles } from '@common/modules/react/themes/components/custom/atoms/FieldRequiredAsterisk';
import {
  FormLabel as MuiFormLabel,
  styled,
  type FormLabelTypeMap as MuiFormLabelTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxFormLabelCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxFormLabelTypeMap<P = object, D extends React.ElementType = 'label'> = MuiFormLabelTypeMap<P & AxFormLabelCustomProps, D>;

export type AxFormLabelProps<
  D extends ElementType = AxFormLabelTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxFormLabelTypeMap<P & AxFormLabelCustomProps, D>, D>;

const styledOptions = {
  name: 'AxFormLabel'
};

const StyledAxFormLabel = styled(MuiFormLabel, styledOptions)<AxFormLabelProps>(({ theme }) => {
  return {
    color: theme.uiKit.colorGrey70,
    margin: 0,
    marginBottom: theme.uiKit.spacingXS,
    '&.Mui-focused': {
      color: theme.uiKit.colorGrey70
    },
    '&.Mui-error': {
      color: theme.uiKit.colorRed60
    },
    '.MuiFormLabel-asterisk': fieldRequiredAsteriskStyles(theme)
  };
});

export const AxFormLabel: MuiOverridableComponent<AxFormLabelTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxFormLabelProps, ref: Ref<HTMLLabelElement>) => {
  const FormLabelProps: AxFormLabelProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxFormLabel { ...FormLabelProps } ref={ ref }>
      { children }
    </StyledAxFormLabel>
  );
});

export default AxFormLabel;
