import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  SpeedDialIcon as MuiSpeedDialIcon,
  type SpeedDialIconProps as MuiSpeedDialIconProps
} from '@mui/material';

export type AxSpeedDialIconProps = MuiSpeedDialIconProps;

const styledOptions = {
  name: 'AxSpeedDialIcon'
};

const StyledAxSpeedDialIcon = styled(MuiSpeedDialIcon, styledOptions)<AxSpeedDialIconProps>(() => {
  return {};
});

export const AxSpeedDialIcon = forwardRef(({
  ...otherProps
}: AxSpeedDialIconProps, ref: Ref<HTMLDivElement>) => {
  const SpeedDialIconProps: AxSpeedDialIconProps = {
    ...otherProps
  };

  return (
    <StyledAxSpeedDialIcon { ...SpeedDialIconProps } ref={ ref } />
  );
});

export default AxSpeedDialIcon;
