import I18nString from '@common/components/I18nString/I18nString';
import {
  AxBox,
  AxCard,
  AxCardContent,
  AxGrid,
  AxIconButton,
  AxSkeleton,
  AxTypography
} from '@common/modules/react/themes/components';
import { useTheme } from '@mui/material';

export default function RecognitionPinsCardSkeleton(): JSX.Element {
  const theme = useTheme();

  return (
    <>
      <AxCard
        sx={{
          height: 'auto',
          mb: theme.uiKit.spacingM
        }}
        className='container__ax-button-ghost-parent'>
        <AxCardContent >
          <AxGrid container display='flex'
            sx={{
              display: 'flex',
              flexDirection: {
                md: 'row',
                xs: 'column'
              }
            }}>
            <AxGrid xs={ 12 } md= 'auto'
              sx={{
                justifyContent: {
                  md: 'left',
                  xs: 'center'
                },
                display: 'flex'

              }}
            >
              <AxSkeleton sx={{
                width: '10rem',
                position: 'relative',
                mr: {
                  md: theme.uiKit.spacingM,
                  xs: 0
                },
                mb: {
                  md: 0,
                  xs: theme.uiKit.spacingM
                }
              }}>
                <AxBox
                  component='img'
                  sx={{
                    height: '4.5rem',
                    width: '10rem',
                    position: 'relative',
                    top: 0,
                    left: 0
                  }}
                />
              </AxSkeleton>
            </AxGrid>
            <AxGrid xs={ 12 } md display='flex' alignItems='center'>
              <AxSkeleton sx={{
                mb: {
                  md: 0,
                  xs: theme.uiKit.spacingM
                }
              }}>
                <I18nString
                  as={ AxTypography }
                  variant='h4'
                  i18nKey='recognitionPins.hasNotReceivedPinsSuggestion'
                  sx={{
                    pl: {
                      md: theme.uiKit.spacingS,
                      xs: 0
                    }
                  }}
                />
              </AxSkeleton>
            </AxGrid>
            <AxGrid
              xs={ 12 }
              md= 'auto'
              sx={{
                display: 'flex',
                justifyContent: {
                  md: 'end',
                  xs: 'center'
                },
                alignItems: 'center',
                height: 'auto',
                width: {
                  md: 'auto',
                  xs: '100%'
                },
                ml: {
                  md: theme.uiKit.spacingM,
                  xs: 0
                }
              }}>
              <AxSkeleton>
                <I18nString
                  as={ AxIconButton }
                  i18nKey='recognitionPins.viewButton'
                  sx={{
                    padding: theme.uiKit.spacingS
                  }}
                />
              </AxSkeleton>
            </AxGrid>
          </AxGrid>
        </AxCardContent>
      </AxCard>
    </>
  );


}
