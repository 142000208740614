import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  TableContainer as MuiTableContainer,
  type TableContainerTypeMap as MuiTableContainerTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTableContainerCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableContainerTypeMap<P = object, D extends React.ElementType = 'div'> = MuiTableContainerTypeMap<P & AxTableContainerCustomProps, D>;

export type AxTableContainerProps<
  D extends ElementType = AxTableContainerTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableContainerTypeMap<P & AxTableContainerCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTableContainer'
};

const StyledAxTableContainer = styled(MuiTableContainer, styledOptions)<AxTableContainerProps>(() => {
  return {};
});

export const AxTableContainer: MuiOverridableComponent<AxTableContainerTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableContainerProps, ref: Ref<HTMLDivElement>) => {
  const TableContainerProps: AxTableContainerProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableContainer { ...TableContainerProps } ref={ ref }>
      { children }
    </StyledAxTableContainer>
  );
});

export default AxTableContainer;
