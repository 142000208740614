import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  AccordionDetails as MuiAccordionDetails,
  type AccordionDetailsProps as MuiAccordionDetailsProps
} from '@mui/material';

type AxAccordionDetailsProps = MuiAccordionDetailsProps;

const styledOptions = {
  name: 'AxAccordionDetails'
};

const StyledAxAccordionDetails = styled(MuiAccordionDetails, styledOptions)<AxAccordionDetailsProps>(() => {
  // TODO - update to use UI Kit Accordion styles
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=5020-6316&mode=design&t=BPAww5mYbYFz5Q1j-0
  return {};
});

export const AxAccordionDetails = forwardRef(({
  ...otherProps
}: AxAccordionDetailsProps, ref: Ref<HTMLDivElement>) => {
  const AccordionDetailsProps: AxAccordionDetailsProps = {
    ...otherProps
  };

  return (
    <StyledAxAccordionDetails { ...AccordionDetailsProps } ref={ ref }/>
  );
});

export default AxAccordionDetails;
