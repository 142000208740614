import I18nString from '@common/components/I18nString/I18nString';
import I18n from '@common/libs/I18n';
import {
  AxBox,
  AxIcon,
  AxStack,
  AxTooltip
} from '@common/modules/react/themes/components';
import FieldRequiredAsterisk from '@common/modules/react/themes/components/custom/atoms/FieldRequiredAsterisk';

export interface FieldLabelWithExtrasProps {
  labelKey: string,
  required?: boolean,
  tooltipKey?: string,
  alignTooltip?: 'start' | 'center' | 'end'
}

export default function FieldLabelWithExtras(props: FieldLabelWithExtrasProps): JSX.Element {
  const {
    labelKey,
    required,
    tooltipKey,
    alignTooltip = 'start'
  } = props;

  return <AxBox
    sx={ (theme) => {
      return {
        marginBottom: theme.uiKit.spacingM
      };
    } }
  >
    <AxStack direction='row' alignItems={ alignTooltip }>

      <I18nString i18nKey={ labelKey } />

      { required && <FieldRequiredAsterisk /> }

      {
        (tooltipKey && tooltipKey !== '')
        && <AxTooltip
          title={ I18n.t(tooltipKey) }
        >
          <AxIcon className='icon-question'
            sx={ (theme) => {
              return {
                // TODO: remove the "!important" in icons.less, so we don't need to clobber it here
                color: theme.uiKit.colorGrey80 + '!important',
                marginLeft: theme.uiKit.spacingXS,
                top: '0.2rem',
                position: 'relative'
              };
            } }/>
        </AxTooltip>
      }
    </AxStack>
  </AxBox>;
}
