import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  StepButton as MuiStepButton,
  type StepButtonTypeMap as MuiStepButtonTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxStepButtonCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  //   - centerRipple - remove
  //   - disableRipple - remove
  //   - disableTouchRipple - remove
  //   - focusRipple - remove
  //   - TouchRippleProps - remove
  //   - touchRippleRef - remove
};

export type AxStepButtonTypeMap<P = object, D extends React.ElementType = 'button'> = MuiStepButtonTypeMap<P & AxStepButtonCustomProps, D>;

export type AxStepButtonProps<
  D extends ElementType = AxStepButtonTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxStepButtonTypeMap<P & AxStepButtonCustomProps, D>, D>;

const styledOptions = {
  name: 'AxStepButton'
};

const StyledAxStepButton = styled(MuiStepButton, styledOptions)<AxStepButtonProps>(() => {
  // TODO - update to use common variables
  return {};
});

export const AxStepButton: MuiOverridableComponent<AxStepButtonTypeMap> = forwardRef(({
  ...otherProps
}: AxStepButtonProps, ref: Ref<HTMLButtonElement>) => {
  const StepButtonProps: AxStepButtonProps = {
    ...otherProps
  };

  return (
    <StyledAxStepButton { ...StepButtonProps } ref={ ref } />
  );
});

export default AxStepButton;
