import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  StepLabel as MuiStepLabel,
  type StepLabelProps as MuiStepLabelProps
} from '@mui/material';

export type AxStepLabelProps = MuiStepLabelProps;

const styledOptions = {
  name: 'AxStepLabel'
};

const StyledAxStepLabel = styled(MuiStepLabel, styledOptions)<AxStepLabelProps>(() => {
  // TODO - update to use common variables
  return {};
});

export const AxStepLabel = forwardRef(({
  ...otherProps
}: AxStepLabelProps, ref: Ref<HTMLDivElement>) => {
  const StepLabelProps: AxStepLabelProps = {
    ...otherProps
  };

  return (
    <StyledAxStepLabel { ...StepLabelProps } ref={ ref } />
  );
});

export default AxStepLabel;
