import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  ListItemSecondaryAction as MuiListItemSecondaryAction,
  type ListItemSecondaryActionProps as MuiListItemSecondaryActionProps
} from '@mui/material';

type AxListItemSecondaryActionProps = MuiListItemSecondaryActionProps;

const styledOptions = {
  name: 'AxListItemSecondaryAction'
};

const StyledAxListItemSecondaryAction = styled(MuiListItemSecondaryAction, styledOptions)<AxListItemSecondaryActionProps>(() => {
  // TODO - update to use common variables (Possibly use existing Task > New > Assign list styles as default)
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=9831-3479&mode=design&t=7poBe9Dfwla5L23E-0
  return {};
});

export const AxListItemSecondaryAction = forwardRef(({
  children,
  ...otherProps
}: AxListItemSecondaryActionProps, ref: Ref<HTMLLIElement>) => {
  const ListItemSecondaryActionProps: AxListItemSecondaryActionProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItemSecondaryAction { ...ListItemSecondaryActionProps } ref={ ref }>
      { children }
    </StyledAxListItemSecondaryAction>
  );
});

export default AxListItemSecondaryAction;
