import {
  ListItemButton as MuiListItemButton,
  styled,
  type ListItemButtonTypeMap as MuiListItemButtonTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxListItemButtonCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - centerRipple - remove and add intelligent default (like AxButton)
  // - disableRipple - remove and add intelligent default (like AxButton)
  // - disableTouchRipple - remove and add intelligent default (like AxButton)
  // - focusRipple - remove and add intelligent default (like AxButton)
  // - TouchRippleProps - remove and add intelligent default (like AxButton)
  // - touchRippleRef - remove and add intelligent default (like AxButton)
};

export type AxListItemButtonTypeMap<P = object, D extends React.ElementType = 'div'> = MuiListItemButtonTypeMap<P & AxListItemButtonCustomProps, D>;

export type AxListItemButtonProps<
  D extends ElementType = AxListItemButtonTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxListItemButtonTypeMap<P & AxListItemButtonCustomProps, D>, D>;

const styledOptions = {
  name: 'AxListItemButton'
};

const StyledAxListItemButton = styled(MuiListItemButton, styledOptions)<AxListItemButtonProps>(() => {
  // TODO - update to use common variables (Possibly use existing Task > New > Assign list styles as default)
  // Ensure any spacing props still work as expected
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=9831-3479&mode=design&t=7poBe9Dfwla5L23E-0
  return {};
});

export const AxListItemButton: MuiOverridableComponent<AxListItemButtonTypeMap> = forwardRef(({
  children,
  disableRipple = true,
  ...otherProps
}: AxListItemButtonProps, ref: Ref<HTMLDivElement>) => {
  const ListItemButtonProps: AxListItemButtonProps = {
    children,
    disableRipple,
    ...otherProps
  };

  return (
    <StyledAxListItemButton { ...ListItemButtonProps } ref={ ref }>
      { children }
    </StyledAxListItemButton>
  );
});

export default AxListItemButton;
