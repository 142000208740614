import {
  AxTextField,
  type AxTextFieldProps
} from '@common/modules/react/themes/components';
import { isEmpty } from 'lodash';
import {
  useCallback,
  useDeferredValue,
  useEffect,
  useState,
  type ReactNode
} from 'react';

export type ValidationsFn = (value: unknown) => boolean;

type Validator = {
  id: string;
  validationFn: ValidationsFn;
  helperText?: ReactNode,
  hideErrorState?: boolean;
};
type ValidationProps = {
  validators?: Validator[];
  value?: unknown;
  onValidationChange?: (id: string | undefined) => unknown // return undefined if valid
};

export type AxValidationTextFieldProps = AxTextFieldProps & ValidationProps;

/*
* Text field that can take validators to validate the input
* Example usage:
* <AxValidationTextField
*  onValidationChange={ (id) => {
*   if (id) {
*     // field is invalid
*   }
*  }}
*  validators={[
*   {
*     id: 'email',
*     validationFn: (value) => !value.includes('@'),
*     helperText: 'Invalid email'
*   }
*  ]}
*  onChange={ (event) => setEmail(event.target.value) }
* />
*/
export const AxValidationTextField = (props: AxValidationTextFieldProps) => {
  const {
    validators,
    onValidationChange,
    ...otherProps
  } = props;

  const [validationStatus, setValidationStatus] = useState<Validator | undefined>();
  const [isFirstCheck, setIsFirstCheck] = useState<boolean>(true);
  const {
    id,
    helperText,
    hideErrorState
  } = validationStatus ?? {};
  const isInvalid = Boolean(id) && !hideErrorState;

  const inputValue = useDeferredValue(props.value);

  const checkValidation = useCallback((value: unknown) => {
    if (!validators || isEmpty(validators)) {
      return;
    }
    const validatorFind = validators.find((validator: Validator) => {
      return validator.validationFn(value);
    });
    if (isFirstCheck || (validatorFind?.id !== validationStatus?.id)) {
      setValidationStatus(validatorFind);
      onValidationChange?.(validatorFind?.id);
      setIsFirstCheck(false);
    }
  }, [validators, isFirstCheck, validationStatus?.id, onValidationChange]);

  useEffect(() => {
    checkValidation(inputValue);
  }, [checkValidation, inputValue]);

  return <AxTextField { ...otherProps } error={ ( isInvalid ) || props.error } helperText={ helperText || props.helperText }/>;
};

export default AxValidationTextField;
