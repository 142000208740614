const DetailItem = require('@common/components/details/item/DetailItem');
const _ = require('underscore');

class MetadataDetailItemView extends DetailItem {
  initialize(options = {}) {
    super.initialize(options);
    ({
      bold: this.bold = false,
      iconClass: this.iconClass
    } = options);
  }

  className() {
    return 'metadata-detail-item text-muted ax-font__caption';
  }

  getTemplate() {
    return `
<% if (iconClass) { %><span class="<%- iconClass %>"></span><% } %>
<span class="value ax-font__caption <%- boldClass %>"><%= getFormattedValue() %></span>
`;
  }

  templateHelpers() {
    return _.extend(super.templateHelpers(), {
      boldClass: this.bold ? 'boldfont ax-font__caption--bold' : '',
      iconClass: this.iconClass
    });
  }
}

module.exports = MetadataDetailItemView;
