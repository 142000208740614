import {
  AppBar as MuiAppBar,
  styled,
  type AppBarTypeMap as MuiAppBarTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxAppBarCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxAppBarTypeMap<P = object, D extends React.ElementType = 'header'> = {
  props: P & Omit<MuiAppBarTypeMap['props'], 'elevation' | 'variant' | 'color'> & AxAppBarCustomProps;
  defaultComponent: D;
};

export type AxAppBarProps<
  D extends ElementType = AxAppBarTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxAppBarTypeMap<P & AxAppBarCustomProps, D>, D>;

const styledOptions = {
  name: 'AxAppBar'
};

const StyledAxAppBar = styled(MuiAppBar, styledOptions)(() => {
  return {};
});

/**
 * A wrapper for the MUI AppBar component
 *
 * ### Links
 * - [AppBar API](https://mui.com/material-ui/react-app-bar/)
 */
export const AxAppBar: MuiOverridableComponent<AxAppBarTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxAppBarProps, ref: Ref<HTMLDivElement>) => {
  const AppBarProps: AxAppBarProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxAppBar { ...AppBarProps } ref={ ref } elevation={ 0 } color='primary'>
      { children }
    </StyledAxAppBar>
  );
});

export default AxAppBar;
