import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  Accordion as MuiAccordion,
  type AccordionProps as MuiAccordionProps
} from '@mui/material';

type AxAccordionProps = MuiAccordionProps;
// TODO
// - elevation - remove
// - variants - align with UI Kit

const styledOptions = {
  name: 'AxAccordion'
};

const StyledAxAccordion = styled(MuiAccordion, styledOptions)<AxAccordionProps>(() => {
  // TODO - update to use UI Kit Accordion styles
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=5020-6316&mode=design&t=BPAww5mYbYFz5Q1j-0
  return {};
});

export const AxAccordion = forwardRef(({
  ...otherProps
}: AxAccordionProps, ref: Ref<HTMLDivElement>) => {
  const AccordionProps: AxAccordionProps = {
    ...otherProps
  };

  return (
    <StyledAxAccordion { ...AccordionProps } ref={ ref }/>
  );
});

export default AxAccordion;
