import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  Stepper as MuiStepper,
  type StepperTypeMap as MuiStepperTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxStepperCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - elevation - remove
};

export type AxStepperTypeMap<P = object, D extends React.ElementType = 'div'> = MuiStepperTypeMap<P & AxStepperCustomProps, D>;

export type AxStepperProps<
  D extends ElementType = AxStepperTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxStepperTypeMap<P & AxStepperCustomProps, D>, D>;

const styledOptions = {
  name: 'AxStepper'
};

const StyledAxStepper = styled(MuiStepper, styledOptions)<AxStepperProps>(() => {
  // TODO - update to use common variables
  return {};
});

export const AxStepper: MuiOverridableComponent<AxStepperTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxStepperProps, ref: Ref<HTMLDivElement>) => {
  const StepperProps: AxStepperProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxStepper { ...StepperProps } ref={ ref } >
      { children }
    </StyledAxStepper>
  );
});

export default AxStepper;
