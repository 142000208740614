import {
  ListItem as MuiListItem,
  styled,
  type ListItemTypeMap as MuiListItemTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import { merge } from 'lodash';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxListItemCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxListItemTypeMap<P = object, D extends React.ElementType = 'li'> = MuiListItemTypeMap<P & AxListItemCustomProps, D>;

export type AxListItemProps<
  D extends ElementType = AxListItemTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxListItemTypeMap<P & AxListItemCustomProps, D>, D>;

const styledOptions = {
  name: 'AxListItem'
};

const StyledAxListItem = styled(MuiListItem, styledOptions)<AxListItemProps>(({
  disablePadding,
  theme
}) => {
  // TODO - update to use common variables (Possibly use existing Task > New > Assign list styles as default)
  // Ensure any spacing props still work as expected
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=9831-3479&mode=design&t=7poBe9Dfwla5L23E-0
  return merge(
    {
      margin: 0
    },
    disablePadding ? {
      padding: 0
    } : {
      padding: theme.uiKit.spacingM
    }
  );
});

export const AxListItem: MuiOverridableComponent<AxListItemTypeMap> = forwardRef(({
  children,
  disablePadding = true,
  ...otherProps
}: AxListItemProps, ref: Ref<HTMLLIElement>) => {
  const ListItemProps: AxListItemProps = {
    children,
    disablePadding,
    ...otherProps
  };

  return (
    <StyledAxListItem { ...ListItemProps } ref={ ref }>
      { children }
    </StyledAxListItem>
  );
});

export default AxListItem;
