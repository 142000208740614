const {
  isBoolean,
  pluck
} = require('underscore');
const logging = require('logging');
const CookieUtil = require('CookieUtil');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const NativeBridgeHelpers = require('@common/libs/helpers/app/NativeBridgeHelpers');
const OAuthProviderEnum = require('@common/data/enums/OAuthProviderEnum');

module.exports = {
  shouldClearCredentials(jqXHR) {
    const exception = AxonifyExceptionFactory.fromResponse(jqXHR);
    const errCode = exception.getErrorCode();

    // Don't clear if it's a timeout or the ajax request was aborted
    if ((jqXHR.status === 0) && ['timeout', 'abort'].includes(jqXHR.statusText)) {
      return false;
    }

    // Don't clear if there's a potential downtime
    if ([502, 503, 504].includes(jqXHR.status)) {
      return false;
    }

    // Don't clear if the host has an IP whitelist
    if (jqXHR.status === 403 && errCode === AxonifyExceptionCode.SERVER_ERROR_IP_FORBIDDEN) {
      return false;
    }

    return true;
  },

  shouldCheckCorporateNetworkAccess(authSessionModel, userModel, propertyPool) {
    const isImpersonation = authSessionModel.isImpersonation();
    const atWorkCheckEnabled = propertyPool.getProperty('atWorkCheckEnabled');
    const atWorkCheckUrl = propertyPool.getProperty('atWorkCheckUrl') || '';
    const isUserLoggedIn = userModel.isLoggedIn();
    const isSuperuser = userModel.isSuperuser();
    const atWorkRequired = isBoolean(userModel.get('atWorkRequired')) ? userModel.get('atWorkRequired') : propertyPool.getProperty('atWorkCheckUserDefault');

    return !isImpersonation
      && atWorkCheckEnabled
      && atWorkCheckUrl.trim().length > 0
      && isUserLoggedIn
      && !isSuperuser
      && atWorkRequired;
  },

  getOAuthSettings(
    oauthSettings = TenantPropertyProvider.get().getProperty('oauthSettings') || [],
    nativeBridgeHelpers = NativeBridgeHelpers
  ) {
    const isInApp = nativeBridgeHelpers.isInApp();
    let supportedOAuthProviders = oauthSettings;

    if (isInApp) {
      // For mobile app users who have upgraded to the latest version
      // the supportedNativeOAuthProviders will be set based on arbitraryAuthenticationProviders flag
      // and we will return the array of oauthSettings that has OIDC "type".
      // If the mobile user is using old version, we will filter for Google by default.
      const supportedNativeOAuthProviders = nativeBridgeHelpers.supportedOAuthProviders();
      if (supportedNativeOAuthProviders === true) {
        return supportedOAuthProviders;
      }
      supportedOAuthProviders = supportedOAuthProviders.filter((supportedOAuthProvider) => {
        if (supportedOAuthProvider.webViewCompatible) {
          if (supportedOAuthProvider.uuid === OAuthProviderEnum.Google) {
            return supportedNativeOAuthProviders.includes(supportedOAuthProvider.uuid);
          }
          return true;
        }
        
        if (supportedNativeOAuthProviders == null || supportedNativeOAuthProviders.length === 0 || !Array.isArray(supportedNativeOAuthProviders)) {
          return false;
        }
        // This is in the case of supportedSchemes from Android devices
        // Match the redirectUri to one of the schemes
        // If their is no match, then don't display the button
        return supportedNativeOAuthProviders.some((scheme) => {
          return supportedOAuthProvider.redirectUri?.includes(scheme);
        });
      });
    }

    return supportedOAuthProviders;
  },

  getOAuthType(providerName) {
    const oauthSettings = TenantPropertyProvider.get().getProperty('oauthSettings');

    const foundProvider = oauthSettings.find((provider) => {
      return provider.uuid === providerName;
    });
    if (foundProvider) {
      return foundProvider.type;
    }
    return false;
  },

  doesOAuthExist(providerName) {
    const oauthSettings = TenantPropertyProvider.get().getProperty('oauthSettings');
    return oauthSettings.some((provider) => {
      return provider.uuid === providerName;
    });
  },

  isOAuthWebviewCompatible(providerName) {
    const oauthSettings = TenantPropertyProvider.get().getProperty('oauthSettings');
    return oauthSettings.some((provider) => {
      return provider.uuid === providerName && provider.webViewCompatible;
    });
  },

  // For mobile app users who have not upgraded to the latest version of the
  // native app we will display a warning that Google OAuth in mobile will
  // stop working unless they upgrade to the new version of the app
  shouldDisplayGoogleOAuthWarning(
    oauthSettings = TenantPropertyProvider.get().getProperty('oauthSettings'),
    nativeSupportedOAuthProviders = NativeBridgeHelpers.supportedOAuthProviders()
  ) {
    const isOldApp = nativeSupportedOAuthProviders.length === 0;
    const tenantSupportsGoogle = pluck(oauthSettings, 'uuid').includes(OAuthProviderEnum.Google);

    return isOldApp && tenantSupportsGoogle;
  },

  setOpenIdConnectInCookie(providerName) {
    const isOpenIdConnectProvider = !OAuthProviderEnum.values().includes(providerName);
    if (isOpenIdConnectProvider) {
      // Temporary using 'isSAMLLogin' cookie proeprty
      // TODO: Remove once we will use AppAuth for OpenID Connect
      logging.info(`Setting isSAMLLogin for provider ${ providerName }`);
      CookieUtil.set('isSAMLLogin', 'true');
    }
  }
};
