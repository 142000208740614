/*
 * This is not our standard type of locale bundle because froala handles language scripts differently than our other
 * libraries. It uses a similar style for consistency.
*/
const FroalaLocaleBundle = {
  localeMap: {
    'en-ca': 'en_ca',
    'en-gb': 'en_gb',
    'en-au': 'en',
    'es-es': 'es',
    'fr-fr': 'fr',
    pt: 'pt_br',
    'pt-pt': 'pt_pt',
    'ro-md': 'ro',
    'sr-lt': 'sr',
    no: 'nb',
    zh: 'zh_cn',
    'zh-hk': 'zh_hk',
    'zh-tw': 'zh_tw'
  },

  notSupportedLocales: ['my-ha', 'ne'],

  supportedLocales: [
    'ar', 'be', 'bg', 'bs', 'cs', 'da', 'de', 'el', 'en_ca', 'en_gb', 'es', 'et', 'fa', 'fi', 'fl', 'fr', 'he', 'hi',
    'hr', 'hu', 'hy', 'id', 'it', 'ja', 'km', 'ko', 'ku', 'lo', 'lt', 'lv', 'me', 'mk', 'ms', 'my', 'nb', 'nl', 'pa',
    'pl', 'pt_br', 'pt_pt', 'ro', 'ru', 'sk', 'sl', 'sq', 'sr', 'sv', 'sw', 'te', 'th', 'tr', 'uk', 'vi', 'zh_cn',
    'zh_hk', 'zh_tw'
  ],

  getLocale(localeCode) {
    const lowerLocaleCode = localeCode.toLowerCase();
    return this.localeMap[lowerLocaleCode] || lowerLocaleCode;
  },

  load(locale) {
    if (locale !== 'en' && !this.notSupportedLocales.includes(locale)) {
      return import(`@common/vendor/froala-editor/js/languages/${ locale }.js`);
    }

    // This is done on purpose, bundles return null
    // to indiciate that they need not be loaded in this current context
    return null;
  }
};

module.exports = FroalaLocaleBundle;
