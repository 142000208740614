const logging = require('logging');
const _ = require('underscore');
const $os = require('detectOS');
const NativeBridgeHelpers = require('@common/libs/helpers/app/NativeBridgeHelpers');
const SMALL_TO_MEDIUM_BREAKPOINT = 680; // This is based on the pixel width transltion from variables.less between @screen-sm and @screen-md-portrait

const {
  guard,
  guardMethod
} = require('DecaffeinateHelpers');

const VideoFileHelpers = require('@common/libs/helpers/app/VideoFileHelpers');

const { imageExtensionList } = require('@common/libs/helpers/types/StringHelpers');

const BrowserHelpers = {
  isCanvasSupported() {
    return guardMethod(document.createElement('canvas'), 'getContext', (o) => {
      return o.getContext('2d');
    }) != null;
  },

  isScreenshotSupported() {
    return this.isCanvasSupported() && ($os.browser === 'chrome') && $os.desktop;
  },

  /**
   * This function attempts to render a union jack flag emoji on a hidden canvas. Then
   * it checks each pixel in turn to see if (R != G != B), ie the pixel is something other
   * than greyscale. If the emoji is rendered in colour, then we deem emojis are adequately supported.
   *
   * This code is wrapped in an anonymous closure using the "module pattern", also known
   * as "Immediately Invoked Function Expression" or "IIFE". The browser check is defined and
   * executed immediately, and the result is set as the value of "isEmojiSupported", so that this
   * canvas shenanegans isn't done every time we need to know about emoji support.
   */
  isEmojiSupported: (function() {
    const canvas = document.createElement('canvas');
    canvas.height = 10;
    canvas.width = canvas.height * 2;
    const ctx = canvas.getContext('2d');
    ctx.font = canvas.height + 'px Arial';
    ctx.fillText('🇬🇧', 0, canvas.height);
    const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    let i = 0;
    // the canvas is 10 x 20, which means we're checking a maximum of 200 pixels
    while (i < data.length) {
      // the data is R, G, B, A, then it repeats for the next pixel R, G, B, A...
      if (data[i] !== data[i + 1] || data[i] !== data[i + 2]) {
        return true;
      }
      i += 4;
    }
    return false;
  })(),

  isClipboardSupported() {
    return navigator.clipboard != null && !($os.android && NativeBridgeHelpers.isInApp());
  },

  _isFileType($input, index, mimeType, allowedExtensions) {
    if (this.isFileApiSupported()) {
      return this._isInputFileMimeType($input, index, mimeType, allowedExtensions);
    }
    return this._isAllowedInputFileExtension($input, allowedExtensions);

  },

  _isInputFileMimeType($input, index, mimeType, allowedExtensions) {
    const fileList = $input[0] != null ? $input[0].files : undefined;
    const inputType = fileList != null ? fileList[index].type.toLowerCase() : undefined;

    if (inputType.length === 0) {
      return this._isAllowedInputFileExtension($input, allowedExtensions);
    }
    return (fileList.length > 0) && (guard(_(inputType).take(mimeType.length), (x) => {
      return x.join('');
    }) === mimeType);

  },

  _isAllowedInputFileExtension($input, allowedExtensions) {
    const name = $input.val();
    const ext = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
    return allowedExtensions.includes(ext);
  },

  isImageFile($input, index = 0) {
    return this._isFileType($input, index, 'image', imageExtensionList);
  },

  isVideoFile($input, index = 0) {
    return this._isFileType($input, index, 'video', VideoFileHelpers.allowedVideoExtensions);
  },

  isCsvFile($input, index = 0) {
    return this._isFileType($input, index, 'text/csv', ['csv']) || this._isFileType($input, index, 'application/vnd.ms-excel', ['csv']);
  },

  isVTTFile($input, index = 0) {
    return this._isFileType($input, index, 'text/vtt', ['vtt']);
  },

  isFileApiSupported() {
    return (window.File != null);
  },

  isHistoryAPISupported() {
    return (window.history.replaceState != null);
  },

  isDownloadAttributeSupported() {
    return ('download' in document.createElement('a'));
  },

  getFileTypeOfInput($input) {
    if (this.isImageFile($input)) {
      return 'image';
    }

    if (this.isVideoFile($input)) {
      return 'video';
    }

    if (this.isCsvFile($input)) {
      return 'csv';
    }

    if (this.isVTTFile($input)) {
      return 'vtt';
    }

    return 'file';
  },

  isIE() {
    return $os.browser === 'ie';
  },

  hideAddressBar() {
    if (document.height <= window.outerHeight) {
      document.body.style.height = `${ window.outerHeight + 60 }px`;
      return window.scrollTo(0, 1);
    }
    return window.scrollTo(0, 1);

  },

  windowWidth() {
    return $(window).width();
  },

  windowHeight() {
    return $(window).height();
  },

  windowDimensions() {
    return {
      width: BrowserHelpers.windowWidth(),
      height: BrowserHelpers.windowHeight()
    };
  },

  triggerResize(force = false) {
    return $(window).trigger('resize', force);
  },

  getScrollbarWidth: (() => {
    let W = window.browserScrollbarWidth != null ? window.browserScrollbarWidth : null;

    if (W == null) {
      let w1, w2;
      const div = $('<div style="width:50px;height:50px;overflow:hidden;position:absolute;top:-200px;left:-200px;"><div style="height:100px;"></div></div>');

      $('body').append(div);

      if (div[0].offsetWidth != null) {
        div.css('overflow-y', 'scroll');
        w1 = div[0].offsetWidth;
        w2 = div[0].clientWidth;
      } else {
        w1 = $('div', div).innerWidth();
        div.css('overflow-y', 'scroll');
        w2 = $('div', div).innerWidth();
      }

      $(div).remove();

      W = (window.browserScrollbarWidth = Math.abs(w1 - w2));
    }

    return _.constant(W);
  })(),

  isFullScreen() {
    return (document.fullScreenElement && document.fullScreenElement !== null)
      || document.fullscreen
      || document.mozFullScreen
      || document.webkitIsFullScreen
      || (document.msFullscreenElement != null);
  },

  preventDragAndDrop($frame = $(window)) {
    const handler = () => {
      const $document = $($frame[0].document != null ? $frame[0].document : $frame[0].contentDocument);

      // To prevent draging of clickable elements
      if ($document.length > 0) {
        $document.on('dragstart', 'body', false);
        $document.on('drop', 'body', false);
      }
    };

    if ($frame.is('iframe')) {
      $frame.one('load', handler);
    } else {
      $frame.ready(handler);
    }
  },

  isTouchDevice() {
    return typeof (window.ontouchstart) !== 'undefined';
  },

  setScalingAndZooming(enabled) {
    let _desc = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
    if (enabled) {
      _desc = 'width=device-width, initial-scale=1.0, user-scalable=yes';
    }
    $('meta[name="viewport"]').attr('content', _desc);
  },

  // Adapted from this gist.
  // https://gist.github.com/KilianSSL/774297b76378566588f02538631c3137
  scrollIntoViewIfNeeded(element) {
    const parent = this.getScrollParent(element);
    const parentComputedStyle = window.getComputedStyle(parent, null);
    const parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'), 10);
    const parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width'), 10);
    const overTop = (element.offsetTop - parent.offsetTop) < parent.scrollTop;
    const overBottom = (((element.offsetTop - parent.offsetTop) + element.clientHeight) - parentBorderTopWidth) > (parent.scrollTop + parent.clientHeight);
    const overLeft = (element.offsetLeft - parent.offsetLeft) < parent.scrollLeft;
    const overRight = (((element.offsetLeft - parent.offsetLeft) + element.clientWidth) - parentBorderLeftWidth) > (parent.scrollLeft + parent.clientWidth);
    const alignWithTop = overTop && !overBottom;

    if (overTop || overBottom || overLeft || overRight) {
      element.scrollIntoView(alignWithTop);
    }
  },


  getScrollParent(element) {
    const overflowRegex = /(auto|scroll)/;
    let parent = element;

    try {
      if ($(element).css('position') === 'fixed') {
        return document.body;
      }
    } catch (error) {
      logging.debug('Could not get styles for element.  Is it in the DOM?');
      return document.body;
    }

    while (parent) {
      let overflowStyles;
      parent = parent.parentElement;

      try {
        const $parent = $(parent);
        overflowStyles = {
          overflow: $parent.css('overflow'),
          overflowY: $parent.css('overflowY'),
          overflowX: $parent.css('overflowX')
        };
      } catch (error1) {
        logging.debug('Could not get styles for $parent.  Is it in the DOM?');
        return document.body;
      }

      if (overflowRegex.test(overflowStyles.overflow + overflowStyles.overflowY + overflowStyles.overflowX)) {
        return parent;
      }
    }

    // return body if we can't find a scrollable parent.
    return document.body;
  },

  serializeWindowFeatures(featureOptions = {}) {
    return _.map(featureOptions, (value, key) => {
      return `${ key }=${ value }`;
    }).join(', ');
  },

  isAdminZoneSupported(os = $os) {
    return !(os.mobile || os.tablet || os.isInMobileApp());
  },

  isValidRelatedTarget(relatedTarget) {
    if (relatedTarget != null) {
      try {
        // It's the actual prop access that throws the error so we'll ignore the lint error here.
        // eslint-disable-next-line no-unused-expressions
        relatedTarget.parentNode;
      } catch (e) {
        return false;
      }
    }

    return true;
  },

  // This was adapted from the gist found here:
  // https://gist.github.com/jehoshua02/d33892df58a95244cf5c
  styleSupported: (function() {
    const supported = [];

    const check = function(prop) {
      let property = prop;
      const body = document.body != null ? document.body : document.documentElement;
      const { style } = body;

      // check for standard
      if (typeof style[property] === 'string') {
        return true;
      }

      // check for vendor specific
      const vendors = ['Moz', 'webkit', 'Webkit', 'Khtml', 'O', 'ms'];
      property = property.charAt(0).toUpperCase() + property.substr(1);

      for (const vendorProperty of vendors) {
        if (typeof style[vendorProperty + prop] === 'string') {
          return true;
        }
      }

      return false;
    };

    return (property) => {
      return supported[property] != null ? supported[property] : (supported[property] = check(property));
    };
  })(),

  cssTransitionSupported() {
    const el = document.createElement('div');
    if (el.style.animationName !== undefined) {
      return true;
    }

    const domPrefixes = ['Webkit', 'Moz', 'O', 'ms', 'Khtml'];
    for (let x = 0; x < domPrefixes.length; x++) {
      if (el.style[domPrefixes[x] + 'AnimationName'] !== undefined ) {
        return true;
      }
    }

    return false;
  },

  isSmallWindow() {
    return window.outerWidth < SMALL_TO_MEDIUM_BREAKPOINT;
  },

  checkDoubleSubmit(limit = 900) {
    if (this.lastClick && ((Date.now() - this.lastClick) < limit)) {
      // It's a double submit!
      return true;
    }
    this.lastClick = Date.now();
    return false;
  },

  getUnsupportedBrowserWarning(os = $os) {
    if ((os.browser === 'ie' && os.version < 12) || (os.browser === 'firefox' && os.version < 76)) {
      return 'flash.browserNotSupported';
    }
    return null;
  }

};

module.exports = BrowserHelpers;
