const logging = require('logging');
const _ = require('underscore');
const Backbone = require('Backbone');
const { sendWarnLog } = require('LoggingService');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const AssessmentType = require('@common/data/enums/AssessmentType');
const ActivityList = require('@common/data/collections/ActivityList');
const GamePlay = require('@common/data/models/GamePlay');

const AssessmentResult = require('@common/data/models/assessments/AssessmentResult');
const FormalExamResult = require('@common/data/models/assessments/FormalExamResult');

const { getTopicOptionModelForAssessmentType } = require('@common/data/models/assessments/AssessmentTopicOptionFactory');

const TIME_SPENT_CADENCE_INTERVAL = 600000; // Update time spent every 10 minutes

const assessmentResultRepeatTracker = {
  _data: {},
  increment(assessmentId) {
    this._data[assessmentId] = this.getCount(assessmentId) + 1;
  },
  getCount(assessmentId) {
    return this._data[assessmentId] || 0;
  },
  reportResults(assessmentId) {
    if (this.getCount(assessmentId) === 3) {
      sendWarnLog({
        logData: {
          logMessage: 'Multiple assessment result requests detected!!'
        }
      });
    }
  }
};

class Assessment extends Backbone.Model {

  apiEndpoint() {
    return '/assessments';
  }

  defaults() {
    return {isActive: false};
  }

  preinitialize() {
    this.destroy = this.destroy.bind(this);
    this.performGameActions = this.performGameActions.bind(this);

    this._initGamePlay();
    this._initActivities();
    this._initAssessmentResult();
  }

  initialize() {
    this._initInitialTimeSpent();
  }

  _initInitialTimeSpent() {
    this.initialTimeSpent = null;
    this.cadenceIntervalId = null;

    const setInitialTimeSpent = (timeSpent) => {
      if (this.initialTimeSpent == null) {
        this.initialTimeSpent = timeSpent;
      }
    };

    setInitialTimeSpent(this.get('timeSpent'));

    this.once('change:timeSpent', (model, timeSpent) => {
      setInitialTimeSpent(timeSpent);
    });
  }

  _initGamePlay() {
    this.gamePlay = new GamePlay();

    this.gamePlay.on('change', (gamePlayModel) => {
      this.set({gamePlay: gamePlayModel.toJSON()}, {silent: true});
    });

    this.on('change:gamePlay', (assessmentModel, gamePlayData = {}) => {
      this.gamePlay.clear({silent: true});
      this.gamePlay.set(gamePlayData);
    });
  }

  _initActivities() {
    this.activities = new ActivityList();

    this.on('change:activities', (assessmentModel, activities = []) => {
      this.activities.set(activities, {remove: false});
    });

    this.activities.on('add', (activity) => {
      if (!activity.isQuestionType() && !activity.isClosed()) {
        const intervalId = setInterval(() => {
          if (activity.isUnderway()) {
            this.timeSpentUpdate();
          } else {
            clearInterval(intervalId);
            this.cadenceIntervalId = null;
          }
        }, TIME_SPENT_CADENCE_INTERVAL);
        this.cadenceIntervalId = intervalId;
      }
    });

    this.activities.on('change reset add remove', () => {
      this.set({activities: this.activities.toJSON()});
    });

    this.activities.on('change', (activity) => {
      if (activity.isClosed() && this.cadenceIntervalId) {
        clearInterval(this.cadenceIntervalId);
        this.cadenceIntervalId = null;
      }

      const isActivityComplete = activity.isComplete();
      let hasQuestionResultBeenDisplayed = isActivityComplete;

      if (this.isFormalExamTraining()) {
        hasQuestionResultBeenDisplayed = isActivityComplete && !this.get('hideAnswersAndReason');
      }

      if (hasQuestionResultBeenDisplayed || activity.isSkipped()) {
        this.timeSpentUpdate();
      }
    });
  }

  _initAssessmentResult() {
    this.assessmentResult = new AssessmentResult();

    this.assessmentResult.on('change', () => {
      this.set({lastRelevantResult: this.assessmentResult.toJSON()});
    });

    this.on('change:lastRelevantResult', (assessmentResultModel, result) => {
      if (_.isObject(result)) {
        this.assessmentResult.set(result);
      }
    });

    this.on('change:type', (assessmentResultModel, type) => {
      this.assessmentResult.set('type', type);
    });

    this.on('change:id', (assessmentResultModel, id) => {
      this.assessmentResult.set('assessmentId', id);
    });
  }

  parse(response) {
    return response.assessment != null ? response.assessment : response;
  }

  getAssessmentResultOption() {
    return this.getAssessmentOption({
      lastRelevantResult: this.get('lastRelevantResult')
    });
  }

  /**
   * Gets the program.
   *
   * This method might return null, since not all assessments will have programs assosciated with them.
   */
  getProgram() {
    return this.get('program');
  }

  shouldShowAssessmentResults() {
    return !this.isDailyTraining();
  }

  hasAssessmentResult() {
    return this.get('id') === this.assessmentResult.get('assessmentId')
      && this.assessmentResult.get('completionDate') != null;
  }

  isNew() {
    // Deleted objects on the server are represented by 'isActive: false' so doing the default isNew check
    // isn't enough. Might be something we should add to all our models that are backed by a server entity.
    return super.isNew() || !this.get('isActive');
  }

  destroy(options = {}) {
    // XXX - Assessments server infrstructure requires the 'assessmentType' be passed in,
    // even for a DELETE..............
    _.extend(
      options,
      {
        url: `${ this.url() }?${ $.param({assessmentType: this.get('type')}) }`,
        wait: true
      }
    );

    const deleteDfr = super.destroy(options);

    this.set('isActive', false);

    return deleteDfr;
  }

  getInitialTimespent() {
    return this.initialTimeSpent || 0;
  }

  getType() {
    return this.get('type');
  }

  isDailyTraining() {
    return this.get('type') === AssessmentType.DailyTraining;
  }

  isExtraTraining() {
    return this.get('type') === AssessmentType.ExtraTraining;
  }

  isCertificationTraining() {
    return this.get('type') === AssessmentType.CertificationTraining;
  }

  isIntroductoryTraining() {
    return this.get('type') === AssessmentType.IntroductoryTraining;
  }

  isRefresherTraining() {
    return this.get('type') === AssessmentType.RefresherTraining;
  }

  isFormalExamTraining() {
    return this.get('type') === AssessmentType.FormalExamTraining;
  }

  isRetake() {
    return this.get('retake');
  }

  isGradable() {
    return true;
  }

  hasOnlyQuestionActivities() {
    // Working under the assumption that survey questions are questions too
    return this.activities.length > 0 && this.activities.every((activity) => {
      return activity.isQuestionContentActivity();
    });
  }

  hasAnsweredAnyQuestions() {
    // Working under the assumption that survey questions are questions too
    return this.activities.some((activity) => {
      return activity.isQuestionContentActivity() && activity.isComplete();
    });
  }

  getUnansweredQuestionCount() {
    return this.activities.reduce((count, activity) => {
      if (activity.isQuestionContentActivity() && !activity.isComplete()) {
        return count + 1;
      }
      return count;
    }, 0);
  }

  shouldPickGame() {
    // Check if `isRetake` for the case of Intro Training assessment, re-takes
    // should not prompt the user to play a game again.
    return !this.isRetake() && this.gamePlay.isNew();
  }

  selectGame(gameId = null, options = {}) {
    // Subsequent calls will be silently ignored, this is to prevent double-click
    if (this.pendingGameSelection != null) {
      return;
    }

    // Don't create a new `gamePlay` if one already exists
    if (!this.gamePlay.isNew()) {
      logging.error('GamePlay is already created, not gonna create another one');
      if (typeof options.error === 'function') {
        options.error();
      }
      return;
    }

    const game = gameId ? {id: gameId} : null;
    const isComplete = (game == null);

    this.gamePlay.save({
      game,
      isComplete
    }, {
      beforeSend: () => {
        this.pendingGameSelection = true;
      },
      complete: () => {
        delete this.pendingGameSelection;
      },
      success: (model, response) => {
        const gamePlay = response.entity;
        this.updateAssessment({gamePlay}, options);
      },
      error() {
        logging.error('An error occured while creating the `gamePlay`');
        return (typeof options.error === 'function' ? options.error() : undefined);
      }
    });
  }

  fetchAssessmentResult() {
    let dfr;
    if (this.isNew()) {
      logging.error('Can\'t get result for an Assessment without an id');
      dfr = $.Deferred();
      dfr.reject();
      return dfr.promise();
    }

    if ((this.get('type') == null)) {
      logging.error('Can\'t get result for an Assessment without a type');
      dfr = $.Deferred();
      dfr.reject();
      return dfr.promise();
    }

    assessmentResultRepeatTracker.increment(this.get('id'));

    return this.assessmentResult.fetch().then((response) => {
      logging.info(`Assessment Result: ${ JSON.stringify(response.result) }`);

      assessmentResultRepeatTracker.reportResults(this.get('id'));
    }, () => {
      logging.error('Error getting Assessment result');
    });
  }

  startTimingAssessment() {
    if (!this.timerId && this.get('id')) {
      this.timerId = window.apps.base.timeLogController.startAssessment(this.get('id'));
    }
  }

  stopTimingAssessment() {
    if (this.timerId) {
      const timeLogEntry = window.apps.base.timeLogController.stop(this.timerId);
      delete this.timerId;
      return timeLogEntry;
    }
    return null;
  }

  pauseTimingAssessment() {
    if (this.timerId) {
      window.apps.base.timeLogController.pause(this.timerId);
    }
  }

  getCurrentTimeSpent() {
    if (this.timerId) {
      return window.apps.base.timeLogController.getCurrentTimeSpent(this.timerId) || 0;
    }

    return 0;
  }

  restartTimer() {
    this.startTimingAssessment();

    // Restart the timer for the "in progress" activity
    const inProgressActivity = this.activities.getInProgressActivity();
    if (inProgressActivity) {
      inProgressActivity.restartTimer();
    }

    // Restart the game play (in case there was one in progress)
    this.gamePlay.restartGamePlay();
  }

  saveFinalTimeSpent(options = {}) {
    const timeLogEntry = this.stopTimingAssessment();

    if (timeLogEntry == null) {
      const deferred = $.Deferred();
      deferred.reject();
      return deferred.promise();
    }

    // Assessment `timeSpent` is cumulative
    const timeSpent = this.getInitialTimespent() + (timeLogEntry.seconds || 0);

    this.initialTimeSpent = timeSpent;
    return this.updateAssessment({ timeSpent }, options);
  }

  // XXX: this is a tricky thing we need to do in order to appease the Grab gods; pause the timer and get the time spent
  // then AFTER the assessment result is called we should stop the timer so that the timelog gets sent to the server.
  pauseAndSaveTimeSpent(options = {}) {
    // Pause the timer, we will stop it later, this is the XXX part of the fix
    this.pauseTimingAssessment(this.timerId);

    return this.timeSpentUpdate(options);
  }

  // XXX: the Grab gods have spoken yet again; do an time update when the last activity is
  // completed to make sure it's as up to date as possible.
  timeSpentUpdate(options = {}) {
    if (this.timerId == null) {
      const deferred = $.Deferred();
      deferred.reject();
      return deferred.promise();
    }

    const timeSpent = this.getInitialTimespent() + this.getCurrentTimeSpent();

    return this.updateAssessment({ timeSpent }, options);
  }

  updateAssessment(data = {}, options = {}) {
    // Don't update/PUT if this is a "new" assessment (i.e. doesn't have an `id`)
    if (this.isNew()) {
      logging.error('Can\'t update an assessment that doesn\'t have an id.');
      const deferred = $.Deferred();
      deferred.reject();
      return deferred.promise();
    }

    // type & launchContext is needed as it's currently a required param when updating an assessment
    data.type = this.get('type');
    data.launchContext = this.get('launchContext');

    return this.save(data, Object.assign({
      patch: true,
      type: 'PUT'
    }, options));
  }

  performGameActions(gameActions = [], options = {}, callback = () => {}) {
    const actions = [].concat(gameActions);

    if (actions.length === 0) {
      callback();
      return Promise.resolve();
    }

    const data = {
      actions,
      assessment: {
        id: this.get('id'),
        type: this.get('type')
      }
    };

    return this.gamePlay.performGameActions(data, _.extend(options, {
      success(response) {
        callback(response);
      }
    }));
  }

  stopGamePlay(options = {}) {
    if (this.gamePlay.isNew()) {
      return;
    }

    const timeLogEntry = this.gamePlay.stopGamePlay();
    if ((timeLogEntry != null ? timeLogEntry.seconds : undefined) == null) {
      return;
    }

    const gamePlayData = {duration: timeLogEntry.seconds};

    this.gamePlay.updateGamePlay(gamePlayData, options);
  }

  getAssessmentOption(valueOverrides) {
    const values = Object.assign({
      launchContext: this.get('launchContext'),
      assessmentReason: this.get('assessmentReason')
    }, valueOverrides);

    logging.debug(`Getting assessmentTopicOption for type: ${ this.getType() }`);
    return getTopicOptionModelForAssessmentType(this.getType(), values);
  }
}

class DailyTrainingAssessment extends Assessment {
  defaults() {
    return _.extend(super.defaults(),
      {type: AssessmentType.DailyTraining});
  }

  isGradable() {
    return false;
  }
}

class TopicLevelAssessment extends Assessment {
  getAssessmentOption(valueOverrides) {
    const values = Object.assign({
      topic: {
        id: this.get('topicId')
      },
      level: this.get('level')
    }, valueOverrides);

    return super.getAssessmentOption(values);
  }
}

class ExtraTrainingAssessment extends TopicLevelAssessment {
  defaults() {
    return _.extend(super.defaults(),
      {type: AssessmentType.ExtraTraining});
  }

  isGradable() {
    return false;
  }
}

class IntroductoryTrainingAssessment extends TopicLevelAssessment {
  defaults() {
    return _.extend(super.defaults(),
      {type: AssessmentType.IntroductoryTraining});
  }
}

class CertificationTrainingAssessment extends TopicLevelAssessment {
  defaults() {
    return _.extend(super.defaults(),
      {type: AssessmentType.CertificationTraining});
  }
}

class RefresherTrainingAssessment extends TopicLevelAssessment {
  defaults() {
    return _.extend(super.defaults(),
      {type: AssessmentType.RefresherTraining});
  }
}

class FormalExamTrainingAssessment extends Assessment {
  defaults() {
    return _.extend(super.defaults(),
      {type: AssessmentType.FormalExamTraining});
  }

  _initActivities() {
    super._initActivities();

    this.activities.on('error:action', (activity, xhr) => {
      const exception = AxonifyExceptionFactory.fromResponse(xhr);

      if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_PROGRAM_ENDED) {
        // Formal exam program has been shut down, remove the rest of the activities,
        // save the time spent and then destroy the assessment to escape it.
        xhr.skipGlobalHandler = true;
        this.removeUnderwayActivities({silent: true});
        this.saveFinalTimeSpent().done(this.destroy);
      }
    });
  }

  _initAssessmentResult() {
    this.assessmentResult = new AssessmentResult();

    this.on('change:type', (assessmentResultModel, type) => {
      this.assessmentResult.set('type', type);
    });

    this.on('change:id', (assessmentResultModel, id) => {
      this.assessmentResult.set('assessmentId', id);
    });

    this.examResult = new FormalExamResult();

    this.examResult.on('change', () => {
      this.set({lastRelevantResult: this.examResult.toJSON()});
    });

    this.on('change:lastRelevantResult', (assessmentResultModel, result) => {
      if (_.isObject(result)) {
        this.examResult.set(result);
      }
    });

    this.on('change:programId', (assessmentResultModel, programId) => {
      this.examResult.set({programId});
    });
  }

  getAssessmentOption(valueOverrides) {
    const values = Object.assign({
      programId: this.get('programId')
    }, valueOverrides);

    return super.getAssessmentOption(values);
  }

  shouldPickGame() {
    return false;
  }

  removeUnderwayActivities(options = {}) {
    const underwayActivities = this.activities.filter((activity) => {
      return activity.isUnderway();
    });
    return this.activities.remove(underwayActivities, options);
  }

  fetchAssessmentResult() {
    const dfr = $.Deferred();

    // if the assessment exists on the server we need to fetch the '/result' for it
    // so it gets properly closed and has an assessment result record created on the server.
    // Otherwise we create an automatically resolved deferred object as a placeholder.
    const assessmentResultDfr = !this.isNew()
      ? super.fetchAssessmentResult()
      : $.Deferred().resolve();

    assessmentResultDfr.done(() => {
      const examResultDfr = this.examResult.fetch();
      examResultDfr.done(dfr.resolve);
      return examResultDfr.fail(dfr.reject);
    });

    return dfr.promise();
  }
}

module.exports = {
  Assessment,
  DailyTrainingAssessment,
  ExtraTrainingAssessment,
  IntroductoryTrainingAssessment,
  CertificationTrainingAssessment,
  RefresherTrainingAssessment,
  FormalExamTrainingAssessment
};
