const logging = require('logging');
const Backbone = require('Backbone');
const UIKit = require('@training/widgets/UIKit');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');
const $os = require('detectOS');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const InfiniteScrollViewMixin = require('@common/mixins/InfiniteScrollViewMixin');
const NewsList = require('@training/apps/training/collections/NewsList');
const MessageCollection = require('@training/apps/training/collections/MessageCollection');
const TimeLogConfig = require('@training/apps/base/models/TimeLogConfig');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const {
  NewsFeedItemView,
  NoNewsItem,
  NoMoreNews
} = require('@training/apps/training/views/NewsItemView');
const {
  MessageItemView,
  NoMessagesItem,
  NoMoreMessages
} = require('@training/apps/training/views/MessageItemView');

const Cocktail = require('backbone.cocktail');
require('@common/libs/behaviors/msHideable/MsHideable');

class NewsCollectionView extends Marionette.CollectionView {
  constructor(...args) {
    super(...args);
    this.childView = NewsFeedItemView;
    this.emptyView = NoNewsItem;
  }

  attributes() {
    return {
      role: $os.desktop ? 'feed' : 'list'
    };
  }

  showNoMoreNews(endItemView) {
    this.$el.append(endItemView.render().el);
  }
}

module.exports = NewsCollectionView;


class MessagesCollectionView extends Marionette.CollectionView {
  constructor(...args) {
    super(...args);
    this.childView = MessageItemView;
    this.emptyView = NoMessagesItem;
  }

  attributes() {
    return {
      role: $os.desktop ? 'feed' : 'list'
    };
  }

  showNoMoreNews(endItemView) {
    this.$el.append(endItemView.render().el);
  }
}

module.exports = MessagesCollectionView;


class FeedPage extends Marionette.LayoutView {
  static TAB_NAME = {
    MESSAGES: 'messages',
    NEWS: 'news'
  };

  getTemplate() {
    return require('@training/apps/training/templates/FeedPage.html');
  }

  className() {
    return 'feed-page';
  }

  ui() {
    return {
      tabs: '.subtabs',
      newsContainer: '.news-container'
    };
  }

  behaviors() {
    return {
      MsHideable: { selector: '.page-header' }
    };
  }

  regions() {
    return {
      newsFeedRegion: '.news-container'
    };
  }

  constructor(options) {
    super(options);
    ({
      initialTab: this.initialTab = FeedPage.TAB_NAME.NEWS,
      isFeedAvailable: this.isFeedAvailable,
      isConsolidatedCommsEnabled: this.isConsolidatedCommsEnabled
    } = options);

    this.fetchDataOptions = {
      type: 'friendNews',
      complete: () => {
        if ($os.desktop) {
          this.setAriaProperties();
        }
        this.p += 1;
      }
    };

    this.tabHashes = {
      [FeedPage.TAB_NAME.MESSAGES]: '#hub/feed/messages',
      [FeedPage.TAB_NAME.NEWS]: '#hub/feed/news'
    };
  }

  templateHelpers() {
    this.isMessagesEnabled = TenantPropertyProvider.get().getProperty('messageInboxEnabled');

    return {
      isMessagesEnabled: this.isMessagesEnabled,
      isFeedAvailable: this.isFeedAvailable,
      isConsolidatedCommsEnabled: this.isConsolidatedCommsEnabled
    };
  }

  onRender() {
    if (this.initialTab === FeedPage.TAB_NAME.MESSAGES && this.isMessagesEnabled) {
      this.collection = new MessageCollection();
      this.newsFeedView = new MessagesCollectionView({
        collection: this.collection
      });

    } else {
      this.collection = new NewsList();
      this.newsFeedView = new NewsCollectionView({
        collection: this.collection
      });
    }
    this._setFeedTimeLog(this.initialTab);
  }

  onAttach() {
    this.$scrollContainer = this.$el.closest('.scrollable');
  }

  onShow() {
    logging.info('FeedPage - viewDidAppear');
    window.app.layout.setTitle(I18n.t('news.title'));

    if (this.isMessagesEnabled) {
      this.tabs = new UIKit.TabBar({
        el: this.ui.tabs,
        container: this.ui.newsContainer
      });
      this.tabs.clickTab(this.initialTab);
      this.listenTo(this.tabs, 'change:tabs', this.onChangeTabs);
    }

    this.newsFeedRegion.show(this.newsFeedView);

    // Load data
    // TODO: When we update to the common infinite scroller, check whether this is still necessary
    this.fetchNext();
  }

  onChangeTabs(tabname) {
    this._setFeedTimeLog(tabname);
    this.newsFeedRegion.show(this.getFeedView(tabname));
    Backbone.history.navigate(this.tabHashes[tabname], {
      trigger: false,
      replace: true
    });
  }

  getFeedView(viewType) {
    if (viewType === 'news') {
      this.collection = new NewsList();
      this.fetchDataOptions = {type: 'friendNews'};
      this.resetData();
      this.newsFeedView = new NewsCollectionView({collection: this.collection});
      return this.newsFeedView;
    }
    this.collection = new MessageCollection();
    this.fetchDataOptions = {};
    this.resetData();
    this.newsFeedView = new MessagesCollectionView({ collection: this.collection });
    return this.newsFeedView;
  }

  resetData() {
    this.noMorePages = false;
    this.p = 0;
    this.fetchNext();
  }

  onLoadNext() {
    this.fetchNext();
  }

  onDestroy() {
    this.collection.abortXHR(Sync.Method.READ);
    if (typeof this.options.complete === 'function') {
      this.options.complete();
    }
    this._stopFeedTimeLog();
  }

  fetchSuccess(response) {
    if (this.noMorePages) {
      return;
    }
    const items = response.news != null ? response.news : response.entities;

    // Set no more pages if length returned is less than results per page
    if (items.length < this.rpp && this.collection.length !== 0) {
      let itemView;
      this.noMorePages = true;
      if (this.tabs && this.tabs.selectedTabName() === 'messages') {
        itemView = new NoMoreMessages();
      } else {
        itemView = new NoMoreNews();
      }
      this.newsFeedView.showNoMoreNews(itemView);
    }

    triggerResize(true);
    this.trigger('force:icon:reflow');

    if ($os.desktop) {
      this.setAriaProperties();
    }
  }

  setAriaProperties() {
    let $itemRows;

    if (this.collection instanceof NewsList) {
      $itemRows = this.$('.newsstory');
    } else if (this.collection instanceof MessageCollection) {
      $itemRows = this.$('.message-item');
    }

    const listSize = $itemRows.length;
    $itemRows.each((index, itemRow) => {
      this.$(itemRow).attr('aria-posinset', index + 1)
        .attr('aria-setsize', listSize);
    });
  }

  // Helper functions for timelog reporting
  _stopFeedTimeLog() {
    if (this.timerId != null) {
      window.apps.base.timeLogController.stop(this.timerId);
    }
  }

  _setFeedTimeLog(tab) {
    this._stopFeedTimeLog();

    switch (tab) {
      case FeedPage.TAB_NAME.MESSAGES:
        this.timerId = window.apps.base.timeLogController.start(TimeLogConfig.PageView.FeedMessages);
        break;

      case FeedPage.TAB_NAME.NEWS:
      default:
        this.timerId = window.apps.base.timeLogController.start(TimeLogConfig.PageView.Feed);
    }
  }
}

Cocktail.mixin(FeedPage, InfiniteScrollViewMixin);
module.exports = FeedPage;
