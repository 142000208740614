import { DialogTitle } from '@mui/material';
import { type PropsWithChildren } from 'react';

import {
  AxDialogCloseButton,
  AxDialogDefaultTitle,
  AxStack
} from '@common/modules/react/themes/components';

export type AxDialogDefaultHeaderProps = PropsWithChildren<{
  qaClassName: `qa-${ string }`
  onClose?: () => void
}>;

export default function AxDialogDefaultHeader(props: AxDialogDefaultHeaderProps): JSX.Element {
  const {
    children,
    qaClassName,
    onClose
  } = props;

  const hasTitle = children != null;
  const closable = onClose != null;

  const titleComp = hasTitle && <AxDialogDefaultTitle qaClassName={ `${ qaClassName }-title` }>{ children }</AxDialogDefaultTitle>;
  const closeComp = closable && <AxDialogCloseButton onClose={ onClose } qaClassName={ `${ qaClassName }-close` }/>;

  return (hasTitle || closable) ? <DialogTitle component='div' className={ qaClassName }>
    <AxStack
      flexDirection='row'
      justifyContent={ !hasTitle && closable ? 'flex-end' : 'space-between' }
      alignItems='center'
      minHeight='3.6rem'
    >
      { titleComp }
      { closeComp }
    </AxStack>
  </DialogTitle> : <></>;
}
