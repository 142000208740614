const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
require('@common/libs/behaviors/msHideable/MsHideable');

class LearnerTrainingPage extends Marionette.LayoutView {
  className() {
    return 'learner-training-page';
  }

  regions() {
    return {
      dailyTrainingBanner: '.learner-daily-training',
      tabContent: '.learner-training-section-tab'
    };
  }

  behaviors() {
    return {
      MsHideable: { selector: '.page-header' }
    };
  }

  getTemplate() {
    return require('@training/apps/learnerTraining/LearnerTrainingPage.html');
  }

  viewDidAppear() {
    window.app.layout.setTitle(I18n.t('hub.training'));
  }
}

module.exports = LearnerTrainingPage;
