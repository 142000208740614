import {
  Select as MuiSelect,
  type SelectProps as MuiBaseSelectProps,
  styled
} from '@mui/material';
import { capitalizeFirstCharacter } from '@common/libs/helpers/types/StringHelpers';
import { merge } from 'lodash';
import {
  forwardRef,
  type Ref
} from 'react';

const styledOptions = {
  name: 'AxSelect'
};

declare module '@mui/material/InputBase' {
  export interface InputBasePropsSizeOverrides {
    'small': false
    'medium': true
    'large': true
  }
}

type MuiSelectProps = Omit<MuiBaseSelectProps, 'color'|'notched'>;

type AxSelectProps = MuiSelectProps;

// TODO - update styles to align with UI Kit text field (ie. size)
// https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=5099-6067&mode=design&t=56zA1ikdwx5wdCWX-0
const StyledAxSelect = styled(MuiSelect, styledOptions)<AxSelectProps>(({
  theme,
  size = 'medium'
}) => {
  const defaultStyles = {
    borderColor: theme.uiKit.colorGrey30,
    '& .MuiSelect-select': {
      minWidth: '19.8rem',
      padding: `${ theme.uiKit.spacingXS } ${ theme.uiKit.spacingS }`
    },

    // focused styling
    '&.Mui-focused, \
     &.Mui-focused.Mui-error': {
      ' .MuiOutlinedInput-notchedOutline, \
        &:hover .MuiOutlinedInput-notchedOutline': {
        ...theme.mixins.componentFocusInputStyles(),
        borderWidth: `.1rem`
      },
      ' .focus-visible': {
        // Remove default ax-form input focus state
        borderColor: 'transparent',
        boxShadow: 'none'
      }
    },
    // error styling
    '&.Mui-error .MuiOutlinedInput-notchedOutline, \
     &.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
      ...theme.mixins.componentErrorInputStyles()
    },
    // hover styling
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.uiKit.colorGrey50
    }
  };

  if (size === 'medium') {
    return {
      ...defaultStyles,
      '& .MuiOutlinedInput-input': {
        height: theme.uiKit.lineHeightXL
      }
    };
  } else if (size === 'large') {
    return {
      ...defaultStyles,
      height: '4rem'
    };
  }

  return defaultStyles;
});

export const DefaultAxSelectProps = {
  sx: {
    '& .MuiSelect-select': {
      paddingRight: '3.8rem'
    }
  },
  MenuProps: {
    transitionDuration: 160
  }
};

export const AxSelect = forwardRef(({
  inputProps,
  size = 'large',
  variant = 'outlined',
  ...otherProps
}: AxSelectProps, ref: Ref<HTMLDivElement>) => {
  const SelectProps: AxSelectProps = merge({
    size,
    inputProps,
    variant,
    ...otherProps
  }, DefaultAxSelectProps, otherProps);

  if (size) {
    SelectProps.className += ` MuiSelect-size${ capitalizeFirstCharacter(size) }`;
  }

  return (
    <StyledAxSelect { ...SelectProps } ref={ ref } />
  );
});

export default AxSelect;
