import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  RadioGroup as MuiRadioGroup,
  type RadioGroupProps as MuiRadioGroupProps
} from '@mui/material';

type AxRadioGroupProps = MuiRadioGroupProps;

const styledOptions = {
  name: 'AxRadioGroup'
};

const StyledAxRadioGroup = styled(MuiRadioGroup, styledOptions)<AxRadioGroupProps>(() => {
  // TODO - update styles to align with UI Kit buttons
  return '';
});

export const AxRadioGroup = forwardRef(({
  children,
  ...otherProps
}: AxRadioGroupProps, ref: Ref<HTMLDivElement>) => {
  const RadioGroupProps: AxRadioGroupProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxRadioGroup { ...RadioGroupProps } ref={ ref }>
      { children }
    </StyledAxRadioGroup>
  );
});

export default AxRadioGroup;
