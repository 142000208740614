import {
  FormControlLabel as MuiFormControlLabel,
  styled,
  type FormControlLabelProps as MuiFormControlLabelProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

export type AxFormControlLabelProps = MuiFormControlLabelProps;

const styledOptions = {
  name: 'AxFormControlLabel'
};

const StyledAxFormControlLabel = styled(MuiFormControlLabel, styledOptions)<AxFormControlLabelProps>(({
  theme,
  label,
  labelPlacement = 'end'
}) => {
  // TODO - update to align with UI Kit
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=5099-5555&mode=design&t=BPAww5mYbYFz5Q1j-0

  let labelPadding = '0';

  if (label != null && label !== '') {
    if (labelPlacement === 'start') {
      labelPadding = `0 ${ theme.uiKit.spacingS } 0 0`;
    } else if (labelPlacement === 'end') {
      labelPadding = `0 0 0 ${ theme.uiKit.spacingS }`;
    } else if (labelPlacement === 'top') {
      labelPadding = `0 0 ${ theme.uiKit.spacingS } 0`;
    } else if (labelPlacement === 'bottom') {
      labelPadding = `${ theme.uiKit.spacingS } 0 0 0`;
    }
  }

  return {
    padding: `0 0 ${ theme.uiKit.spacingM } 0`,
    margin: 0,
    '&.MuiDisabled': {
      pointerEvents: 'auto',
      cursor: 'not-allowed'
    },
    '&.MuiFormControlLabel-labelPlacementTop, &.MuiFormControlLabel-labelPlacementBottom': {
      alignItems: 'flex-start'
    },
    '& .MuiFormControlLabel-label': {
      padding: labelPadding
    }
  };
});

export const AxFormControlLabel = forwardRef(({
  ...otherProps
}: AxFormControlLabelProps, ref: Ref<HTMLDivElement>) => {
  const FormControlLabelProps: AxFormControlLabelProps = {
    ...otherProps
  };

  return (
    <StyledAxFormControlLabel { ...FormControlLabelProps } ref={ ref } />
  );
});

export default AxFormControlLabel;
