import {
  TableCell as MuiTableCell,
  styled,
  type TableCellProps as MuiTableCellProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type AxTableCellProps = MuiTableCellProps;

const styledOptions = {
  name: 'AxTableCell'
};

const StyledAxTableCell = styled(MuiTableCell, styledOptions)<AxTableCellProps>(() => {
  // TODO - update to use UI Kit styles (ensure size and variant styles accurate)
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=6013-9216&mode=design&t=7poBe9Dfwla5L23E-0
  return {
    padding: 0
  };
});

export const AxTableCell = forwardRef(({
  children,
  ...otherProps
}: AxTableCellProps, ref: Ref<HTMLTableCellElement>) => {
  const TableCellProps: AxTableCellProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableCell { ...TableCellProps } ref={ ref }>
      { children }
    </StyledAxTableCell>
  );
});

export default AxTableCell;
