import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  TableBody as MuiTableBody,
  type TableBodyTypeMap as MuiTableBodyTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTableBodyCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableBodyTypeMap<P = object, D extends React.ElementType = 'tbody'> = MuiTableBodyTypeMap<P & AxTableBodyCustomProps, D>;

export type AxTableBodyProps<
  D extends ElementType = AxTableBodyTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableBodyTypeMap<P & AxTableBodyCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTableBody'
};

const StyledAxTableBody = styled(MuiTableBody, styledOptions)<AxTableBodyProps>(() => {
  return {};
});

export const AxTableBody: MuiOverridableComponent<AxTableBodyTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableBodyProps, ref: Ref<HTMLTableSectionElement>) => {
  const TableBodyProps: AxTableBodyProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableBody { ...TableBodyProps } ref={ ref }>
      { children }
    </StyledAxTableBody>
  );
});

export default AxTableBody;
