const FilterableAttributesEnum = require('@common/data/enums/FilterableAttributesEnum');


const FilterableAttributesHelpers = {

  getNonRuleFilterableAttributes() {
    return [
      FilterableAttributesEnum.Locations,
      FilterableAttributesEnum.JobTitles,
      FilterableAttributesEnum.GroupAreasOfInterest,
      FilterableAttributesEnum.Departments,
      FilterableAttributesEnum.LinesOfBusiness,
      FilterableAttributesEnum.LocationTypes,
      FilterableAttributesEnum.GroupTeamAttributes
    ];
  },

  getUserAttributes() {
    return [
      FilterableAttributesEnum.Departments,
      FilterableAttributesEnum.JobTitles,
      FilterableAttributesEnum.GroupAreasOfInterest,
      FilterableAttributesEnum.LinesOfBusiness
    ];
  },

  getRuleFilterableAttributes() {
    return [
      FilterableAttributesEnum.LocationsFilterRule,
      FilterableAttributesEnum.JobTitlesFilterRule,
      FilterableAttributesEnum.DepartmentsFilterRule,
      FilterableAttributesEnum.LinesofBusinessFilterRule,
      FilterableAttributesEnum.LocationTypesFilterRule
    ];
  },

  getFilterRuleEnums() {
    return {
      groupAreasOfInterest: 'filterRule',
      jobTitles: FilterableAttributesEnum.JobTitlesFilterRule,
      departments: FilterableAttributesEnum.DepartmentsFilterRule,
      locations: FilterableAttributesEnum.LocationsFilterRule,
      linesOfBusiness: FilterableAttributesEnum.LinesofBusinessFilterRule,
      locationTypes: FilterableAttributesEnum.LocationTypesFilterRule,
      groupTeamAttributes: 'filterRule'
    };
  },

  getExcludeableAttributes() {
    return [
      FilterableAttributesEnum.Locations,
      FilterableAttributesEnum.LocationTypes,
      FilterableAttributesEnum.JobTitles,
      FilterableAttributesEnum.Departments,
      FilterableAttributesEnum.LinesOfBusiness
    ];
  }
};

module.exports = FilterableAttributesHelpers;
