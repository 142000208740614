const { Module } = require('Marionette');
const {
  Model,
  Wreqr
} = require('Backbone');

const I18n = require('@common/libs/I18n');

const BrandingStyleModel = require('./brandingStyleModel');
const BrandingStyleView = require('./brandingStyleView');

const USER_LOGGED_IN_KEY = 'isUserLoggedIn';
const LOCALE_KEY = 'localeKey';

class BrandingModule extends Module {
  constructor(moduleName, app, options) {
    super(moduleName, app, options);

    this.session = options.session;

    const $brandingStyleEl = this._getCreateStyleTag();

    this.brandingStyleModel = new BrandingStyleModel();

    this.brandingChannel = Wreqr.radio.channel('branding');

    this.listenTo(this.brandingStyleModel, 'change', () => {
      this.brandingChannel.vent.trigger('style:updated', {
        loginBgLowResPath: this.brandingStyleModel.getLoginBgPath(
          BrandingStyleView.DEFAULT_SIZES.LOW_RES_HEIGHT,
          BrandingStyleView.DEFAULT_SIZES.LOW_RES_WIDTH
        ),
        loginBgFullResPath: this.brandingStyleModel.getLoginBgPath(
          window.innerHeight,
          window.innerWidth
        ),
        bannerImageLowResPath: this.brandingStyleModel.getBannerImagePath(
          BrandingStyleView.DEFAULT_SIZES.LOW_RES_HEIGHT,
          BrandingStyleView.DEFAULT_SIZES.LOW_RES_WIDTH
        ),
        bannerImageFullResPath: this.brandingStyleModel.getBannerImagePath(
          window.innerHeight,
          window.innerWidth
        )
      });
    });

    this.brandingStyleView = new BrandingStyleView({
      model: this.brandingStyleModel,
      el: $brandingStyleEl
    });

    this.brandingState = new Model();
    this.listenTo(this.brandingState, 'change', this._loadBranding.bind(this));

    I18n.listenForLocaleChange(this._onLocaleChange.bind(this));

    this.listenToOnce(this.session, 'sync', this._onFirstSessionSync.bind(this)); // Used for the case where the user isn't signed in on page load

    this.listenTo(this.session.user, 'change:id change:lang', this._onUserChange.bind(this)); // Used for the case where the user is signed in on page load

    // To make sure the banding styles are always the the last to be applied, we watch for other tags
    // to be added to the head and then move the branding tag to the bottom again in reaction.
    this.mutationObserver = new MutationObserver(() => {
      $('head').append($brandingStyleEl);
      this.mutationObserver.takeRecords();
    });
    this.mutationObserver.observe($('head')[0], {
      childList: true
    });
  }

  _getCreateStyleTag() {
    const $brandingStyleEl = $('<style>', {id: 'branding'});

    $('head').append($brandingStyleEl);

    return $brandingStyleEl;
  }

  _getDefaultState() {
    return {
      [USER_LOGGED_IN_KEY]: false,
      [LOCALE_KEY]: I18n.getLocale()
    };
  }

  _getUserState(userModel = {}) {
    const state = {};

    if (!userModel.isNew()) {
      state[USER_LOGGED_IN_KEY] = true;

      const userLang = userModel.get('language');
      if (userLang !== 'XX') {
        state[LOCALE_KEY] = userLang;
      }
    }

    return state;
  }

  _loadBranding() {
    this.brandingStyleModel.abortXHR();
    this.brandingStyleModel.fetch({
      isLoggedIn: this.brandingState.get(USER_LOGGED_IN_KEY),
      data: {
        language: this.brandingState.get(LOCALE_KEY)
      },
      showSpinner: false
    });
  }

  _onFirstSessionSync() {
    if (!this.session.has('user')) {
      this._updateState();
    }
  }

  _onLocaleChange() {
    this._updateState();
  }

  _onUserChange() {
    this._updateState();
  }

  _updateState() {
    this.brandingState.set(
      Object.assign(
        {},
        this._getDefaultState(),
        this._getUserState(this.session.user)
      )
    );
  }
}

module.exports = BrandingModule;
