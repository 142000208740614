import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  ListItemIcon as MuiListItemIcon,
  type ListItemIconProps as MuiListItemIconProps
} from '@mui/material';

type AxListItemIconProps = MuiListItemIconProps;

const styledOptions = {
  name: 'AxListItemIcon'
};

const StyledAxListItemIcon = styled(MuiListItemIcon, styledOptions)<AxListItemIconProps>(() => {
  // TODO - update to use common variables (Possibly use existing Task > New > Assign list styles as default)
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=9831-3479&mode=design&t=7poBe9Dfwla5L23E-0
  return {};
});

export const AxListItemIcon = forwardRef(({
  children,
  ...otherProps
}: AxListItemIconProps, ref: Ref<HTMLLIElement>) => {
  const ListItemIconProps: AxListItemIconProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItemIcon { ...ListItemIconProps } ref={ ref }>
      { children }
    </StyledAxListItemIcon>
  );
});

export default AxListItemIcon;
