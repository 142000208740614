import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  TableSortLabel as MuiTableSortLabel,
  type TableSortLabelTypeMap as MuiTableSortLabelTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTableSortLabelCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - centerRipple - remove and add intelligent default (like AxButton)
  // - disableRipple - remove and add intelligent default (like AxButton)
  // - disableTouchRipple - remove and add intelligent default (like AxButton)
  // - focusRipple - remove and add intelligent default (like AxButton)
  // - TouchRippleProps - remove and add intelligent default (like AxButton)
  // - touchRippleRef - remove and add intelligent default (like AxButton)
};

export type AxTableSortLabelTypeMap<P = object, D extends React.ElementType = 'span'> = MuiTableSortLabelTypeMap<P & AxTableSortLabelCustomProps, D>;

export type AxTableSortLabelProps<
  D extends ElementType = AxTableSortLabelTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableSortLabelTypeMap<P & AxTableSortLabelCustomProps, D>, D>;


const styledOptions = {
  name: 'AxTableSortLabel'
};

const StyledAxTableSortLabel = styled(MuiTableSortLabel, styledOptions)<AxTableSortLabelProps>(() => {
  // TODO - update to use UI Kit styles
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=6013-9216&mode=design&t=7poBe9Dfwla5L23E-0
  return {};
});

export const AxTableSortLabel: MuiOverridableComponent<AxTableSortLabelTypeMap> = forwardRef(({
  ...otherProps
}: AxTableSortLabelProps, ref: Ref<HTMLSpanElement>) => {
  const TableSortLabelProps: AxTableSortLabelProps = {
    ...otherProps
  };

  return (
    <StyledAxTableSortLabel { ...TableSortLabelProps } ref={ ref }/>
  );
});

export default AxTableSortLabel;
