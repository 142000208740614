const _ = require('underscore');
const $os = require('detectOS');

const Form = require('@common/components/forms/Form');
const TreeEditor = require('@common/components/forms/editors/treeEditor/Form.Editor.TreeEditor');
const KeyCode = require('@common/data/enums/KeyCode');

require('@common/components/forms/editors/treeEditor/plugins/HierarchicalParentSelection');

const MAX_ROWS_MOBILE = 200;

// Realistically, a lot of this code should be refactored into the TreeEditor itself but we can survive
// with a specific specialized version of this for now
Form.Editor.HierarchyTreeEditor = class HierarchyTreeEditor extends TreeEditor {

  getTemplate() {
    return _.tpl(`\
      <div class="fancytree-grid-container">
        <table class="tree-wrapper">
          <tbody>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>\
    `);
  }

  _getTreeOptions() {
    return {
      extensions: ['filter', 'hierarchical-parent-tristate', 'grid'],
      click: this.onClick.bind(this),
      dblclick: this.onClick.bind(this),
      keydown: this.onKeyDown.bind(this),
      titlesTabbable: true,
      focusOnSelect: true,
      autoScroll: true,
      table: {
        indentation: 20,
        nodeColumnIdx: 0
      },
      viewport: {
        enabled: true,
        count: this.viewportRowCount
      }
    };
  }

  onClick(e, options = {}) {
    const {
      node,
      targetType
    } = options;

    // When the empty/no data node is returned during search, prevent it from being clickable
    if (node != null && node.statusNodeType === 'nodata') {
      return false;
    }

    if (['checkbox', 'title'].includes(targetType)) {
      this._performSelectChange(node);
    } else if (targetType === 'expander') {
      node.toggleExpanded();
      this.adjustMobileTreeRowCount();
    }

    // Returning false so the click event doesn't
    // keep bubbling and ending up closing the drop down on us.
    return false;
  }

  _performSelectChange(node) {
    // If you're in single select mode, you can only activate nodes (domain logic rule)
    if (this._isMultiSelect) {
      return node.toggleSelected();
    }
    return node.setSelected(true);
  }

  onKeyDown(e, options = {}) {
    const { node } = options;

    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      if (options.originalEvent.target.classList.contains('bulk-edit-button')) {
        this.trigger('bulkEditClicked', options.originalEvent);
      } else if (node && node.extraClasses && (node.extraClasses).includes('root')) {
        // check if the root node action was taken since TAB focus differs from list focus but ENTER is valid for both
        // send node since e is the entire fancytree
        if (this.$('.inclusion-button--include').is(':focus') || this.$('.inclusion-button--exclude').is(':focus')) {
          this.trigger('toggleInclusionButtons', node);
        } else {
          node.setExpanded(!node.expanded);
          node.setFocus();
        }
      } else if (node) {
        node.setActive(true);
      }
    }
    return true;
  }

  search(query) {
    super.search(query);

    this.adjustMobileTreeRowCount();
  }

  adjustTreeRowCount() {
    if ($os.desktop) {
      this._tree.adjustViewportSize();
    }
    this.adjustMobileTreeRowCount();
  }

  // fancytree viewport scrolling doesn't work on touch devices since it listens to `wheel` event.
  // Instead, we are rendering all the visible nodes up to MAX_ROWS_MOBILE rows.
  adjustMobileTreeRowCount() {
    if (!$os.desktop || $os.tablet) {
      const visibleNodesCount = this._tree.visibleNodeList.length;
      const rowsToShow = Math.min(visibleNodesCount, MAX_ROWS_MOBILE);

      this._tree.setViewport({count: rowsToShow + 1});
    }
  }

  setCollection(collection) {
    this._collection = collection;
  }
};

module.exports = Form.Editor.HierarchyTreeEditor;
