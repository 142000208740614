import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  Fab as MuiFab,
  type FabTypeMap as MuiFabTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxFabCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  //   - centerRipple - remove
  //   - color - remove
  //   - disableFocusRipple - remove
  //   - disableRipple - remove
  //   - disableTouchRipple - remove
  //   - focusRipple - remove
  //   - size - update to align with AxButton
  //   - TouchRippleProps - remove
  //   - touchRippleRef - remove
  //   - variant - update to align with AxButton
};

export type AxFabTypeMap<P = object, D extends React.ElementType = 'button'> = MuiFabTypeMap<P & AxFabCustomProps, D>;

export type AxFabProps<
  D extends ElementType = AxFabTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxFabTypeMap<P & AxFabCustomProps, D>, D>;

const StyledAxFab = styled(MuiFab, { name: 'AxFab' })<AxFabProps>(() => {
  // TODO - update styles to align with UI Kit buttons
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7244-5634&mode=design&t=hVEcz054xk5GGZgJ-0
  return {};
});

export const AxFab: MuiOverridableComponent<AxFabTypeMap> = forwardRef(({
  disableFocusRipple = true,
  disableRipple = true,
  disableTouchRipple = true,
  children,
  ...otherProps
}: AxFabProps, ref: Ref<HTMLButtonElement>) => {
  const buttonProps = {
    disableFocusRipple,
    disableRipple,
    disableTouchRipple,
    children,
    ...otherProps
  };

  return (
    <StyledAxFab { ...buttonProps } ref={ ref }>
      { children }
    </StyledAxFab>
  );
});

export default AxFab;
