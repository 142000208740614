import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  AlertTitle as MuiAlertTitle,
  type AlertTitleProps as MuiAlertTitleProps,
  type TypographyProps as MuiTypographyProps
} from '@mui/material';

type AxAlertTitleProps = MuiAlertTitleProps & MuiTypographyProps;

const styledOptions = {
  name: 'AxAlertTitle'
};

const StyledAxAlertTitle = styled(MuiAlertTitle, styledOptions)<AxAlertTitleProps>(() => {
  // TODO - update styles to align with UI Kit (AlertTitle styles should live here, not in AxAlert)
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7244-5616&mode=design&t=hVEcz054xk5GGZgJ-0
  return {};
}) as typeof MuiAlertTitle;

export const AxAlertTitle = forwardRef(({
  ...otherProps
}: AxAlertTitleProps, ref: Ref<HTMLDivElement>) => {
  const AlertTitleProps: AxAlertTitleProps = {
    ...otherProps
  };

  return (
    <StyledAxAlertTitle { ...AlertTitleProps } ref={ ref }/>
  );
});

export default AxAlertTitle;
