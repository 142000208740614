const env = require('env');

const TranscodedPlayer = require('@common/components/video/players/transcoded/TranscodedPlayer');

require('@common/components/video/players/transcoded/Transcoded.less');
require('@common/components/video/players/transcoded/PlayerControl.less');

class LegacyTranscodedPlayer extends TranscodedPlayer {

  _getPlayer() {
    return import('@common/vendor/mediaelement/v3/mediaelement-and-player.js');
  }

  _addMediaElementPlayerVersionSpecificOptions(options) {
    options.pluginPath = `${ env.settings.baseScriptURL }common/vendor/mediaelement/v3/`;
    options.classPrefix = 'mejs-';
    options.renderers = ['html5', 'flash_audio', 'flash_video'];

    return options;
  }
}

module.exports = LegacyTranscodedPlayer;
