import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  List as MuiList,
  type ListTypeMap as MuiListTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxListCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxListTypeMap<P = object, D extends React.ElementType = 'ul'> = MuiListTypeMap<P & AxListCustomProps, D>;

export type AxListProps<
  D extends ElementType = AxListTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxListTypeMap<P & AxListCustomProps, D>, D>;


const styledOptions = {
  name: 'AxList'
};

const StyledAxList = styled(MuiList, styledOptions)<AxListProps>(() => {
  // TODO - update to use common variables (Possibly use existing Task > New > Assign list styles as default)
  // Ensure any spacing props still work as expected
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=9831-3479&mode=design&t=7poBe9Dfwla5L23E-0
  return {
    padding: 0,
    margin: 0
  };
});

export const AxList: MuiOverridableComponent<AxListTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxListProps, ref: Ref<HTMLUListElement>) => {
  const ListProps: AxListProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxList { ...ListProps } ref={ ref }>
      { children }
    </StyledAxList>
  );
});

export default AxList;
