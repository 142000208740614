import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  TableFooter as MuiTableFooter,
  type TableFooterTypeMap as MuiTableFooterTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTableFooterCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableFooterTypeMap<P = object, D extends React.ElementType = 'tfoot'> = MuiTableFooterTypeMap<P & AxTableFooterCustomProps, D>;

export type AxTableFooterProps<
  D extends ElementType = AxTableFooterTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableFooterTypeMap<P & AxTableFooterCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTableFooter'
};

const StyledAxTableFooter = styled(MuiTableFooter, styledOptions)<AxTableFooterProps>(() => {
  return {};
});

export const AxTableFooter: MuiOverridableComponent<AxTableFooterTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableFooterProps, ref: Ref<HTMLTableSectionElement>) => {
  const TableFooterProps: AxTableFooterProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableFooter { ...TableFooterProps } ref={ ref }>
      { children }
    </StyledAxTableFooter>
  );
});

export default AxTableFooter;
