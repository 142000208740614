import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import { styled } from '@mui/material';
import {
  TabList as MuiTabList,
  type TabListTypeMap as MuiTabListTypeMap
} from '@mui/lab';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTabListCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - indicatorColor - remove and add intelligent default
  // - textColor - remove and add intelligent default
};

export type AxTabListTypeMap<P = object, D extends React.ElementType = 'button'> = MuiTabListTypeMap<P & AxTabListCustomProps, D>;

export type AxTabListProps<
  D extends ElementType = AxTabListTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTabListTypeMap<P & AxTabListCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTabList'
};

const StyledAxTabList = styled(MuiTabList, styledOptions)<AxTabListProps>(() => {
  // TODO - update styles to align with UI Kit
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7246-4627&mode=design&t=56zA1ikdwx5wdCWX-0
  return {};
});

export const AxTabList: MuiOverridableComponent<AxTabListTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTabListProps, ref: Ref<HTMLButtonElement>) => {
  const TabListProps: AxTabListProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTabList { ...TabListProps } ref={ ref }>
      { children }
    </StyledAxTabList>
  );
});

export default AxTabList;
