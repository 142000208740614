import {
  Switch as MuiSwitch,
  styled,
  type SwitchProps as MuiSwitchProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

//  TOGGLE EXAMPLE: creates a toggle with a label in front of the toggle inline
//    labelPlacement: 'start' | 'end' | 'top' | 'bottom'
//
//    <AxFormControlLabel
//      labelPlacement='start'
//      label={ I18n.t('nudgeTasks.create.requirePhoto') }
//      control={
//        <AxToggle
//          checked={ isSwitchChecked }
//          disabled={ isDisabled }
//          onChange={ setVerificationType }
//        />
//      }
//    />

export type AxToggleProps = MuiSwitchProps;
// TODO
// - color - remove
// - disableFocusRipple - remove
// - disableRipple - remove

const StyledAxToggle = styled(MuiSwitch, { name: 'AxToggle' })<AxToggleProps>(({
  theme
}) => {
  // TODO - double check that these styles align with UI Kit. Look for added MUI styles that need to be removed.
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=5099-6018&mode=design&t=hVEcz054xk5GGZgJ-0

  const transform = theme.direction === 'rtl'
    ? `calc(-${ theme.uiKit.switchWidth } + ${ theme.uiKit.switchSizeInner } + (2 * ${ theme.uiKit.switchSpacingInner }))`
    : `calc(${ theme.uiKit.switchWidth } - ${ theme.uiKit.switchSizeInner } - (2 * ${ theme.uiKit.switchSpacingInner }))`;

  return {
    width: 'auto',
    height: 'auto',
    overflow: 'unset',
    padding: 0,

    // default - enabled and off
    '.MuiSwitch-track': {
      borderRadius: theme.uiKit.spacingM,
      height: theme.uiKit.elementSize,
      width: theme.uiKit.switchWidth,
      backgroundColor: theme.uiKit.switchUncheckedColor,
      opacity: theme.uiKit.switchEnabledOpacity
    },

    '.MuiSwitch-switchBase': {
      justifyContent: 'flex-start',
      padding: 0,
      margin: theme.uiKit.switchSpacingInner,
      top: 'auto',
      left: 'auto',

      '& .MuiSwitch-input': {
        margin: 0,
        padding: 0
      },

      // focus styling
      '&.Mui-focusVisible': {
        '& + .MuiSwitch-track': theme.mixins.componentKeyboardFocusStyles()
      },

      // need to check sibling components for toggle checked state
      '&.Mui-checked': {
        transform: `translateX(${ transform })`,

        // enabled and on
        '& + .MuiSwitch-track': {
          backgroundColor: theme.uiKit.linkColor || theme.uiKit.switchColor, // Branded link color or default fallback
          opacity: theme.uiKit.switchEnabledOpacity
        },

        // disabled and on
        '&.Mui-disabled': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.uiKit.linkColor || theme.uiKit.switchDisabledCheckedColor, // Branded link color or default fallback
            opacity: theme.uiKit.switchDisabledOpacity,
            cursor: 'not-allowed'
          }
        }
      },
      // disabled and off
      '&.Mui-disabled': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme.uiKit.switchDisabledBorderColor,
          opacity: theme.uiKit.switchEnabledOpacity,
          cursor: 'not-allowed'
        }
      }
    },

    '& .MuiSwitch-thumb': {
      height: theme.uiKit.spacingM,
      width: theme.uiKit.spacingM,
      boxShadow: 'none',
      color: theme.uiKit.switchThumbColor
    }
  };
});

export const AxToggle = forwardRef(({
  disableRipple = true,
  ...props
}: AxToggleProps, ref: Ref<HTMLButtonElement>) => {
  const ToggleProps: AxToggleProps = {
    disableRipple,
    ...props
  };

  return (
    <StyledAxToggle { ...ToggleProps } ref={ ref }/>
  );
});

export default AxToggle;
