const { ItemView } = require('Marionette');
const $os = require('detectOS');
const env = require('env');
const NumberHelpers = require('@common/libs/helpers/types/NumberHelpers');

const BrandingStyleModel = require('./brandingStyleModel');

class BrandingStyleView extends ItemView {
  static DEFAULT_SIZES = {
    LOW_RES_HEIGHT: 0,
    LOW_RES_WIDTH: 0,
    HEADER_IMG_MAX_HEIGHT_MOBILE: 35,
    HEADER_IMG_MAX_HEIGHT_DESKTOP: 50
  };

  modelEvents() {
    return {
      change: 'render'
    };
  }

  getTemplate() {
    let styleTemplate = require('./cssTemplates/buttonsCommon.html')
    + require('./cssTemplates/axButtonCommon.html')
    + require('./cssTemplates/axFormCommon.html')
    + require('./cssTemplates/headerCommon.html')
    + require('./cssTemplates/loginCommon.html');

    if (!$os.mobile) {
      styleTemplate += require('./cssTemplates/linksDesktop.html')
        + require('./cssTemplates/buttonsDesktop.html')
        + require('./cssTemplates/tabsDesktop.html')
        + require('./cssTemplates/headerDesktop.html');
    } else {
      styleTemplate += require('./cssTemplates/linksMobile.html')
        + require('./cssTemplates/buttonsMobile.html')
        + require('./cssTemplates/headerMobile.html');
    }

    styleTemplate += require('./cssTemplates/brandingCommon.html');

    if ( env.settings.app === 'admin' ) {
      styleTemplate += require('./cssTemplates/adminOverrides.html');
    }

    return styleTemplate;
  }

  templateHelpers() {
    const FIELDS = BrandingStyleModel.FIELDS;
    const headerImageDesktop = this.model.getHeaderImage(
      BrandingStyleView.DEFAULT_SIZES.HEADER_IMG_MAX_HEIGHT_DESKTOP
    );
    const headerImageMobile = this.model.getHeaderImage(
      BrandingStyleView.DEFAULT_SIZES.HEADER_IMG_MAX_HEIGHT_MOBILE
    );

    return {
      headerImageDesktop,
      headerImageMobile,
      headerImageDesktopMinWidth: () => {
        if (!headerImageDesktop) {
          return 0;
        }
        return headerImageDesktop.actualDimensions.width / (headerImageDesktop.actualDimensions.height / BrandingStyleView.DEFAULT_SIZES.HEADER_IMG_MAX_HEIGHT_DESKTOP)
      },
      loginBgAvgColor: this.model.getLoginBgAvgColor(),
      loginBgLowResPath: this.model.getLoginBgPath(
        BrandingStyleView.DEFAULT_SIZES.LOW_RES_HEIGHT,
        BrandingStyleView.DEFAULT_SIZES.LOW_RES_WIDTH
      ),
      loginBgFullResPath: this.model.getLoginBgPath(
        window.innerHeight,
        window.innerWidth
      ),
      darkTheme: this.model.isDarkThemeEnabled(),
      headerBorderColor: this.model.get(FIELDS.HEADER_BORDER_COLOUR),
      headerBorderWidth: NumberHelpers.toFixed(this.model.get(FIELDS.HEADER_BORDER_WIDTH) / 10, 1),
      mainHeaderColor: this.model.get(FIELDS.MAIN_HEADER_COLOUR),
      linkColour: this.model.get(FIELDS.LINK_COLOUR),
      mainHeaderColour: this.model.get(FIELDS.MAIN_HEADER_COLOUR),
      primaryButtonDefaultColour: this.model.get(FIELDS.PRIMARY_BUTTON_DEFAULT_COLOUR),
      primaryButtonDefaultHoverColour: this.model.get(FIELDS.PRIMARY_BUTTON_DEFAULT_HOVER_COLOUR),
      primaryButtonTextColour: this.model.get(FIELDS.PRIMARY_BUTTON_TEXT_COLOUR) || '#ffffff',
      primaryButtonHoverTextColour: this.model.get(FIELDS.PRIMARY_BUTTON_HOVER_TEXT_COLOUR) || '#ffffff',
      tabBorderColour: this.model.get(FIELDS.TAB_BORDER_COLOUR),
      bannerImageLowResPath: this.model.getBannerImagePath(
        BrandingStyleView.DEFAULT_SIZES.LOW_RES_HEIGHT,
        BrandingStyleView.DEFAULT_SIZES.LOW_RES_WIDTH
      ),
      bannerImageFullResPath: this.model.getBannerImagePath(
        window.innerHeight,
        window.innerWidth
      ),
      bannerOverlayColour: this.model.get(FIELDS.BANNER_OVERLAY_COLOUR),
      bannerTextColour: this.model.get(FIELDS.BANNER_TEXT_COLOUR),
      bannerBadgeBackgroundColour: this.model.get(FIELDS.BADGE_BACKGROUND_COLOUR),
      pageHeaderBackgroundColour: this.model.get(FIELDS.PAGE_HEADER_BACKGROUND_COLOUR)
    };
  }
}

module.exports = BrandingStyleView;
