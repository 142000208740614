const Backbone = require('Backbone');
const $os = require('detectOS');
const _ = require('underscore');
const logging = require('logging');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const CryptographyHelper = require('@common/libs/cryptography/CryptographyHelper');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

const ActivitiesController = require('@training/apps/training/ActivitiesController');
const InGameQuestionView = require('@training/apps/training/views/InGameQuestionView');
const LeaderboardView = require('@training/apps/training/views/LeaderboardView');
const FeedbackModalFormView = require('@training/apps/training/views/FeedbackModalFormView');
const GamePlayFrame = require('@training/apps/training/views/GamePlayFrame');
const GameErrorModal = require('@training/apps/training/views/GameErrorModal');

const GameApi = require('./GameApi');
const StubGameApi = require('./StubGameApi');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

let startGameOnInit;
let questionQueue;
let gameIsOver;
const GAME_HISTORY_LIMIT = 5;

class GameManager extends ActivitiesController {
  constructor(questionsController, sessionModel) {
    super(questionsController, sessionModel);

    this.showQuestions = this.showQuestions.bind(this);
    this.processGameOverOnError = this.processGameOverOnError.bind(this);
    this.showErrorModal = this.showErrorModal.bind(this);
    this.processGameOver = this.processGameOver.bind(this);
    this.generateStamp = this.generateStamp.bind(this);
    this._endGame = this._endGame.bind(this);
    this.performGameActions = this.performGameActions.bind(this);
    this.finishedProcessing = this.finishedProcessing.bind(this);
    this.onBottomMenuOpen = this.onBottomMenuOpen.bind(this);

    startGameOnInit = false;
    questionQueue = 0;
    gameIsOver = false;
    this.gameApi = new GameApi(this);

    this.assessmentSessionId = this.sessionModel.currentAssessment.id;
    this.gamePlay = this.sessionModel.currentAssessment.gamePlay;
    this.game = this.gamePlay.game;

    // get game state from userGameState mechanic
    const userGameStateMechanic = this.game.getMechanic('userGameState');
    const saveGameState = _.get(userGameStateMechanic, 'state.gameState.gameState', '{}');
    const jsonGameState = this.parseJSONData(saveGameState);

    // deliberate double equal
    if (jsonGameState.assessmentGameState == null) {
      jsonGameState.assessmentGameState = [];
    }

    this.persistentGameState = jsonGameState;
    this.mapGameState = new Map(jsonGameState.assessmentGameState);

    this.glChannel = Backbone.Wreqr.radio.channel('global');
  }

  onBottomMenuOpen(isMenuOpen) {
    if (questionQueue === 0) {
      if (isMenuOpen) {
        this.pauseGame();
      } else {
        this.resumeGame();
      }
    }
  }

  processActivities() {
    logging.debug('Processing game activities');

    if (this.game.isSupported()) {
      this.showGame();
    } else {
      this.gamePlay.skipGame();
      this.finishedProcessing();
    }
  }

  showGame() {
    window.app.layout.setTitle(this.game.get('name'));
    // this._toggleAppLayout(false);

    this.gameView = new GamePlayFrame({
      gameType: this.game.get('type'),
      gameApi: this.gameApi
    });

    window.app.layout.setView(this.gameView);

    $('#modalview').addClass(this.game.get('type'));
  }

  //This is required for the GM games that have in game instructions instead of the framework ones.
  startGameOnInit(start) {
    startGameOnInit = start;
  }

  initGame() {
    if (startGameOnInit) {
      this.startGame();

      // This flag needs to be cleared so subsequent runs of the game will not
      // have this flag unless it's set explictly
      startGameOnInit = false;
    }
  }

  startGame() {
    this._focusFrame();
    this.startTimer();
    this.glChannel.vent.on('open:bottom:menu', this.onBottomMenuOpen);

    this.gameApi.startGame();
  }

  isHolidayTime() {
    return TenantPropertyProvider.get().getProperty('holidayTheme');
  }

  parseJSONData(saveGameState) {
    try {
      const rawGameState = JSON.parse(saveGameState);
      if (_.isObject(rawGameState)) {
        return rawGameState;
      }
      throw new Error('Save Game is not an object!');
    } catch (e) {
      return { persistentGameState: saveGameState };
    }
  }

  getGameData() {
    const gameData = _.pick(this.game.attributes, ['name', 'mechanics']);

    // add body for keeping backward compatibility with legacy game mechanics (Head2Head)
    gameData.body = {};

    // What used to be `gameDuration` on Game is now `duration` on GamePlay
    if (this.gamePlay.get('duration') != null) {
      gameData.gameDuration = this.gamePlay.get('duration');
    }

    // get game state from userGameState mechanic
    const userGameStateMechanic = this.game.getMechanic('userGameState');
    const saveGameState = _.get(userGameStateMechanic, 'state.gameState.gameState', '{}');

    const rawGameState = this.parseJSONData(saveGameState);
    delete rawGameState.assessmentGameState;
    gameData.gameState = rawGameState;

    // get assessment specific gameState if it exists
    gameData.assessmentGameState = {};
    if (this.mapGameState.has(this.assessmentSessionId)) {
      gameData.assessmentGameState = this.mapGameState.get(this.assessmentSessionId);
    }

    // get time limit from gameTimer mechanic
    const timeLimitMechanic = this.game.getMechanic('gameTimer');
    gameData.timeLimit = _.get(timeLimitMechanic, 'configuration.timeLimit', 0);

    // get bonus time info from bonusTimeForQuestions mechanic
    const bonusTimeForQuestionsMechanic = this.game.getMechanic('bonusTimeForQuestions');
    gameData.addedTimePerQuestion = _.get(bonusTimeForQuestionsMechanic, 'configuration.addedTimePerQuestion', 0);

    gameData.addedTimePerCorrectQuestion = _.get(bonusTimeForQuestionsMechanic, 'configuration.addedTimePerCorrectQuestion', 0);

    // get possible opponents from head2head mechanic
    const head2headMechanic = this.game.getMechanic('head2Head');

    if (head2headMechanic != null) {
      gameData.body.possibleOpponents = null;

      if ((head2headMechanic.state != null ? head2headMechanic.state.possibleOpponents : undefined) != null) {
        gameData.body.possibleOpponents = head2headMechanic.state.possibleOpponents;
      }

      gameData.user = {
        fullname: window.apps.auth.session.user.get('salutationName'),
        team: window.apps.auth.session.user.attributes.location.name
      };
      gameData.activities = window.app.sessionController.session.currentAssessment.activities;
    }

    // get questionCount
    gameData.questionCount = this.getQuestionCount();

    return gameData;
  }

  queueQuestion() {
    if (gameIsOver) {
      return;
    }
    questionQueue++;
  }

  dequeueQuestions(dequeueCount) {
    if (gameIsOver) {
      return;
    }
    questionQueue = questionQueue - dequeueCount;
  }

  displayQuestions() {
    if (gameIsOver) {
      return;
    }

    if (questionQueue > 0) {
      this.pauseGame();

      const lengthToShow = questionQueue;

      this.showQuestions(lengthToShow, () => {
        this.dequeueQuestions(lengthToShow);

        window.app.layout.flash.clear();

        this.resumeGame();
      });
    }
  }

  getQuestionCount() {
    return this.sessionModel.currentAssessment.activities.numberOfQuestionsUnderway();
  }

  getNextActivity() {
    return this.sessionModel.currentAssessment.activities.getNextQuestion();
  }

  showQuestions(numberOfQuestions, complete) {
    const activity = this.getNextActivity();
    // Store/update the current activity for future reference (specifically in the Feedback modal)
    this.currentActivity = activity;

    let transition = UIKit.View.Transitions.FADE;
    logging.info(`showQuestions - activityId: ${ (activity != null ? activity.id : undefined) }, questionCount: ${ numberOfQuestions }`);

    if (activity && (numberOfQuestions > 0)) {
      if (!this.isShowingQuestions) {
        transition = UIKit.View.Transitions.NONE;
        this.isShowingQuestions = true;
        const v = new InGameQuestionView();
        window.app.layout.presentModal(v, {
          closeClick: false,
          closeEsc: false
        });

        v.listenTo(apps.glChannel.vent, 'app:feedbackSubmitted', () => {
          v.actionBar.disableButton('feedback');
        });

        v.listenTo(apps.glChannel.vent, 'app:openFeedbackModal', () => {
          // Store a reference to the details view/layer (where the feedback modal will be displayed)
          const detailsView = window.app.layout.mainLayout.findControllerById('details-view').getView();
          const isFooterShowing = detailsView.isFooterShowing();

          // Create a blank view to contain the feedback form (in its own modal)
          const feedbackWrapper = new UIKit.View({
            className: 'feedback-modal-wrapper'
          });

          window.app.layout.pushDetailsView(feedbackWrapper, transition);
          // If the details view was previously showing the logo/footer - hide it for the feedback modal
          if (isFooterShowing) {
            detailsView.toggleFooter(false);
          }

          const feedbackModal = new AccessibleModalView({
            id: 'modalview',
            className: 'modal feedback-modal',
            bottomOffset: 150,
            topOffset: 250
          });

          const cleanUpFeedbackModal = () => {
            detailsView.$el.toggleClass('feedback', false);
            // If the details view was previously showing the logo/footer - turn it back on
            if (isFooterShowing) {
              detailsView.toggleFooter(true);
            }
            window.app.layout.popDetailsView();
          };
          cleanUpFeedbackModal.bind(this);

          const feedbackModalFormView = new FeedbackModalFormView({
            feedbackContext: this.currentActivity,
            onClose: cleanUpFeedbackModal
          });

          detailsView.$el.toggleClass('feedback', true);
          feedbackWrapper.presentModal(feedbackModal, {
            closeEsc: false,
            closeClick: false,
            modalCSS: {
              bottom: 'unset'
            },
            onClose: cleanUpFeedbackModal
          });
          feedbackModal.setSubviewIn(feedbackModalFormView, transition);
        });
      }

      // this._toggleAppLayout(true);

      this.showActivity(activity, {
        transition,
        modal: true,
        gameManager: this,
        complete: () => {
          this.showQuestions(numberOfQuestions - 1, complete);
        }
      });
    } else {
      logging.debug(`About to dismiss InGameQuestion ModalView with ${ numberOfQuestions } of questions remaining`);
      window.app.layout.dismissModal(() => {
        // this._toggleAppLayout(false);
        this.isShowingQuestions = false;
        complete();
      });
    }
  }

  gameOverOnError() {
    // Make sure gameOver is only called once.
    if (gameIsOver) {
      return;
    }
    gameIsOver = true;

    this.glChannel.vent.off('open:bottom:menu', this.onBottomMenuOpen);

    this.processGameOverOnError();
  }

  gameOver(score, gameOverStringId, gameState = {}) {
    if (gameIsOver) {
      return;
    }
    gameIsOver = true;

    const stringGameState = JSON.stringify(gameState);
    const cleanGameState = this.cleanGameState(stringGameState);

    const actions = this.getActions(score, cleanGameState);

    const leaderboardScore = this.getScoreForLeaderboard(score);

    this.glChannel.vent.off('open:bottom:menu', this.onBottomMenuOpen);

    this.processGameOver(actions, gameOverStringId, leaderboardScore);
  }

  removeOldestSaveIfLimitReached() {
    if (!this.mapGameState.has(this.assessmentSessionId)) {
      if (this.mapGameState.size >= GAME_HISTORY_LIMIT) {
        const keyToRemove = this.mapGameState.keys().next().value; // get the first key in Map object
        this.mapGameState.delete(keyToRemove);
      }
    }
  }

  updateAssessmentGameState(gameState, async = false) {
    // Make sure gameOver is only called once.
    if (gameIsOver) {
      return;
    }

    this.removeOldestSaveIfLimitReached();
    this.mapGameState.set(this.assessmentSessionId, gameState);
    this.updateGameState(this.persistentGameState, async);
  }

  updateGameState(gameState, async = false) {
    // Make sure gameOver is only called once.
    let action, options;
    if (gameIsOver) {
      return;
    }

    // merge assessmentGameState before saving.
    gameState.assessmentGameState = [...this.mapGameState];
    this.persistentGameState = gameState; // keep this.persistentGameState updated

    if (this.game.getMechanic('userGameState')) {
      action = this._createUserGameStateMechanic(JSON.stringify(gameState));
      options = {
        async: async,
        skipGlobalHandler: true,
        showSpinner: false
      };
    }

    // Update our game state
    this.performGameActions([action], options);
  }

  _createUserGameStateMechanic(gameState) {
    return {
      mechanic: {
        mechanicId: 'userGameState'
      },
      actionType: 'updateGameState',
      actionBody: {
        gameState
      }
    };
  }

  getActions(score, gameState) {
    // create game actions for updating score and game_state
    let action;
    const actions = [];

    if (this.game.getMechanic('userGameState')) {
      actions.push(this._createUserGameStateMechanic(gameState));
    }

    const challenge = _.get(this.game.getMechanic('challenge'), 'state.challenge');

    // Only perform a `completeChallenge` action if not `isComplete`
    if ((challenge != null ? challenge.isActive : undefined) && !challenge.isComplete) {
      action = {
        mechanic: {
          mechanicId: 'challenge'
        },
        actionType: 'completeChallenge',
        actionBody: {
          challenge_id: challenge.id,
          points: score
        }
      };
      actions.push(action);
    }

    // Adds the single player scoreboard mechanic as required
    if (this.game.getMechanic('singlePlayerScoreBoard') != null) {
      action = {
        mechanic: {
          mechanicId: 'singlePlayerScoreBoard'
        },
        actionType: 'updateScore',
        actionBody: {
          points: score,
          stamp: this.generateStamp(score)
        }
      };

      actions.push(action);
    }
    return actions;
  }

  cleanGameState(gameState) {
    // If gameState is not a primitive, log an error and set game state to empty string
    if (_.isObject(gameState)) {
      logging.error(`GameManager - ${ this.game.get('name') }  tried to persist game state that wasn't a primitive. Operation not supported.`);
      return '';
    }
    // Force a conversion to a string in game states
    return `${ gameState }`;

  }

  // by default, the score returned from game
  // is what is needed for the leaderboard
  getScoreForLeaderboard(score) {
    return score;
  }

  processGameOverOnError() {
    this.showErrorModal();
  }

  showErrorModal() {
    const questionsRemaining = this.getQuestionCount();

    const v = new GameErrorModal();
    window.app.layout.presentModal(v, {
      closeClick: false,
      closeEsc: false,
      onClose: () => {
        this.gamePlay.skipGame();
        this.showQuestions(questionsRemaining, this._endGame);
      }
    });
  }

  processGameOver(actions = [], gameOverStringId, score) {
    const gamePlayData = {isComplete: true};

    const timeLogEntry = this.stopTimer();

    if ((timeLogEntry != null ? timeLogEntry.seconds : undefined) != null) {
      gamePlayData.duration = timeLogEntry.seconds;
    }

    if (!this.gamePlayIsActive()) {
      // No game play existed which means we reloaded with no
      // question activities pass on through
      this._endGame();
    } else if (this.game.hasLeaderboard()) {
      // There may be some actions that need to be sent to the server so perform
      // actions, update game play, then show the leaderboard
      this.performGameActions(actions, {}, () => {
        this.updateGamePlay(gamePlayData, {
          success: () => {
            this.awardChallengePoints();

            const questionsRemaining = this.getQuestionCount();

            this.showLeaderboard(score, gameOverStringId, questionsRemaining, () => {
              this.showQuestions(questionsRemaining, this._endGame);
            });
          }
        });
      });
    } else {
      this.updateGamePlay(gamePlayData, {success: this._endGame});
    }
  }

  generateStamp(value) {
    const stampValue = JSON.stringify(value);
    return CryptographyHelper.getMD5Hash(stampValue);
  }

  _endGame() {
    this.endGame();
    this.finishedProcessing();
  }

  performGameAction(action = {}, options, callback) {
    const {
      actionType,
      actionBody
    } = action;

    if (actionType === 'GAME_VICTORY_DECLARATION') {
      this.sessionModel.currentAssessment.gamePlay.sendVictoryMessage(actionBody, callback);
    } else {
      this.performGameActions([action], options, callback);
    }
  }

  performGameActions(actions, options, callback) {
    this.sessionModel.currentAssessment.performGameActions(actions, options, callback);
  }

  updateGamePlay(data, options) {
    this.gamePlay.updateGamePlay(data, options);
  }

  gamePlayIsActive() {
    // A game play is "active" if it has a gamePlay id
    return !this.gamePlay.isNew();
  }

  showLeaderboard(score, gameOverStringId, questionsRemaining, next) {
    // Show game leaderboard
    const v = new LeaderboardView({
      score,
      questionsRemaining,
      game: this.game,
      gameOverStringId,
      continue: next
    });

    // this._toggleAppLayout(true);

    window.app.layout.presentModal(v, {
      closeClick: false,
      closeEsc: false
    });
  }

  awardChallengePoints() {
    const challenge = _.get(this.game.getMechanic('challenge'), 'state.challenge');

    // Award the wagered points if I am the winner and there was a wager.
    // If I was challenged then `accepted` is `true` and I am the challengee.
    if ((challenge != null ? challenge.accepted : undefined) && (challenge.wager != null)
        && (challenge.challengeeScore > challenge.challengerScore)) {
      window.apps.auth.session.user.addPoints(challenge.wager);
    }
  }

  finishedProcessing() {
    logging.debug('Finished processing game');

    this.game.clear();

    // this._toggleAppLayout(true);

    $('#modalview').removeClass(this.game.get('type'));

    if ($os.windowsphone) {
      $('.contentwrapper').css('-ms-touch-action', 'pan-y');
    }

    super.finishedProcessing();
  }

  setI18n(i18nModule) {
    this._gamesI18n = i18nModule;
  }

  getGameString(stringId, stringVars = {}) {
    return this._gamesI18n.t(stringId, stringVars);
  }

  getUserLocale() {
    return I18n.getLocale();
  }

  startTimer() {
    return this.gamePlay.startGamePlay();
  }

  pauseTimer() {
    this.gamePlay.pauseGamePlay();
  }

  resumeTimer() {
    this.gamePlay.resumeGamePlay();
  }

  stopTimer() {
    return this.gamePlay.stopGamePlay();
  }

  pauseGame() {
    this.gameApi.pauseGame();
    this.pauseTimer();
  }

  questionAnsweredCorrect() {
    this.gameApi.questionAnsweredCorrect();
  }

  questionAnsweredIncorrect() {
    this.gameApi.questionAnsweredIncorrect();
  }

  resumeGame() {
    this.gameApi.resumeGame();
    this.resumeTimer();
    this._focusFrame();
  }

  _focusFrame() {
    // if we're in-app on an iOS device, we don't
    // want to setFocus on the gameview as it creates a
    // focus issue on the next touch event
    if (!$os.ios || !$os.isInMobileApp()) {
      this.gameView.setFocus();
    }
  }

  endGame() {
    this.gameApi.endGame();

    // Be sure to update the games with a stub API so games don't try and
    // reference dead code...
    this.gameView.setGameApi(new StubGameApi(this.game.get('type')));
  }

  // Commentting this out for now as we'll want to restore this functionality later on
  // once we figure out how to create a leave assessment hook.
  // _toggleAppLayout(toggle) {
  //   window.app.layout.toggleMenuHiddenDisplay(!toggle);
  //
  //   if ($os.mobile) {
  //     window.app.layout.togglePageHeader(toggle);
  //   }
  // }
}

module.exports = GameManager;
