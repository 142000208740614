import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  ButtonGroup as MuiButtonGroup,
  type ButtonGroupTypeMap as MuiButtonGroupTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxButtonGroupCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  //   - color - remove
  //   - disableElevation - remove
  //   - disableFocusRipple - remove
  //   - disableRipple - remove
  //   - size - update to align with AxButton
  //   - variant - update to align with AxButton
};

export type AxButtonGroupTypeMap<P = object, D extends React.ElementType = 'div'> = MuiButtonGroupTypeMap<P & AxButtonGroupCustomProps, D>;

export type AxButtonGroupProps<
  D extends ElementType = AxButtonGroupTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxButtonGroupTypeMap<P & AxButtonGroupCustomProps, D>, D>;

const styledOptions = {
  name: 'AxButtonGroup'
};

const StyledAxButtonGroup = styled(MuiButtonGroup, styledOptions)<AxButtonGroupProps>(() => {
  // TODO - update styles to align with UI Kit buttons
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7244-5634&mode=design&t=hVEcz054xk5GGZgJ-0
  return {};
});

export const AxButtonGroup: MuiOverridableComponent<AxButtonGroupTypeMap> = forwardRef(({
  children,
  disableElevation = true,
  disableFocusRipple = true,
  disableRipple = true,
  ...otherProps
}: AxButtonGroupProps, ref: Ref<HTMLDivElement>) => {
  const buttonProps: AxButtonGroupProps = {
    children,
    disableElevation,
    disableFocusRipple,
    disableRipple,
    ...otherProps
  };

  return (
    <StyledAxButtonGroup { ...buttonProps } ref={ ref }>
      { children }
    </StyledAxButtonGroup>
  );
});

export default AxButtonGroup;
