import {
  ListSubheader as MuiListSubheader,
  styled,
  type ListSubheaderTypeMap as MuiListSubheaderTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxListSubheaderCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - color - adjust to default, secondary, inherit
};

export type AxListSubheaderTypeMap<P = object, D extends React.ElementType = 'li'> = MuiListSubheaderTypeMap<P & AxListSubheaderCustomProps, D>;

export type AxListSubheaderProps<
  D extends ElementType = AxListSubheaderTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxListSubheaderTypeMap<P & AxListSubheaderCustomProps, D>, D>;

const styledOptions = {
  name: 'AxListSubheader'
};

const StyledAxListSubheader = styled(MuiListSubheader, styledOptions)<AxListSubheaderProps>(({theme}) => {
  // TODO - update to use common variables (Possibly use existing Task > New > Assign list styles as default)
  // Ensure any spacing props still work as expected
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=9831-3479&mode=design&t=7poBe9Dfwla5L23E-0
  return {
    borderTop: `0.1rem solid ${ theme.uiKit.colorGrey20 }`,
    fontSize: theme.uiKit.fontSizeXS
  };
});

export const AxListSubheader: MuiOverridableComponent<AxListSubheaderTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxListSubheaderProps, ref: Ref<HTMLLIElement>) => {
  const ListSubheaderProps: AxListSubheaderProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListSubheader { ...ListSubheaderProps } ref={ ref }>
      { children }
    </StyledAxListSubheader>
  );
});

export default AxListSubheader;
