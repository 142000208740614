import { AxFormControlLabel } from '@common/modules/react/themes/components/inputs';
import {
  MenuItem as MuiMenuItem,
  styled,
  useTheme,
  type MenuItemTypeMap as MuiMenuItemTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  find,
  merge
} from 'lodash';
import {
  Children,
  forwardRef,
  isValidElement,
  type ElementType,
  type Ref
} from 'react';

type AxMenuItemCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  //   - centerRipple - remove
  //   - disableRipple - remove
  //   - disableTouchRipple - remove
  //   - focusRipple - remove
  //   - TouchRippleProps - remove
  //   - touchRippleRef - remove
};

export type AxMenuItemTypeMap<P = object, D extends React.ElementType = 'li'> = MuiMenuItemTypeMap<P & AxMenuItemCustomProps, D>;

export type AxMenuItemProps<
  D extends ElementType = AxMenuItemTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxMenuItemTypeMap<P & AxMenuItemCustomProps, D>, D>;

const StyledAxMenuItem = styled(MuiMenuItem, {
  name: 'AxMenuItem'
})<AxMenuItemProps>(({
  theme
}) => {
  // TODO - update styles to align with UI Kit MenuItem buttons
  return {
    padding: `${ theme.uiKit.spacingS } ${ theme.uiKit.spacingM }`,
    '& + .MuiDivider-root': {
      marginTop: 0,
      marginBottom: 0
    }
  };
});

export const AxMenuItem: MuiOverridableComponent<AxMenuItemTypeMap> = forwardRef(({
  disableRipple = true,
  disableTouchRipple = true,
  focusRipple = false,
  children,
  ...otherProps
}: AxMenuItemProps, ref: Ref<HTMLLIElement>) => {
  const buttonProps = {
    disableRipple,
    disableTouchRipple,
    focusRipple,
    ...otherProps
  };

  const theme = useTheme();

  // This code checks if the children of the AxMenuItem component include an AxFormControlLabel component.
  // If an AxFormControlLabel component is found, it modifies the buttonProps object to update the styling.
  // The padding and flexBasis properties are adjusted to align with the UI Kit styling.
  const hasFormControlLabelChild = find(Children.toArray(children), (child) => {
    return isValidElement(child) && child.type === AxFormControlLabel;
  }) != null;

  if (hasFormControlLabelChild) {
    merge(buttonProps, {
      sx: {
        padding: 0,
        '& > .MuiFormControlLabel-root': {
          padding: `${ theme.uiKit.spacingXS } ${ theme.uiKit.spacingS }`,
          flexBasis: '100%'
        }
      }
    });
  }

  return (
    <StyledAxMenuItem { ...buttonProps } ref={ ref }>
      { children }
    </StyledAxMenuItem>
  );
});

export default AxMenuItem;
