import {
  Menu as MuiMenu,
  styled,
  type MenuProps as MuiMenuProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

export type AxMenuProps = MuiMenuProps;
// TODO
// - elevation - remove

const styledOptions = {
  name: 'AxMenu'
};

const StyledAxMenu = styled(MuiMenu, styledOptions)<AxMenuProps>(({ theme }) => {
  // TODO - update styles to align with UI Kit
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7246-4597&mode=design&t=56zA1ikdwx5wdCWX-0
  return {
    '& .MuiMenu-list': {
      padding: 0
    },
    '& .MuiPaper-root': {
      boxShadow: theme.uiKit.boxShadowM
    }
  };
});

export const AxMenu = forwardRef(({
  ...otherProps
}: AxMenuProps, ref: Ref<HTMLDivElement>) => {
  const MenuProps: AxMenuProps = {
    ...otherProps
  };

  return (
    <StyledAxMenu { ...MenuProps } ref={ ref } />
  );
});

export default AxMenu;
