import {
  FormControl as MuiFormControl,
  styled,
  type FormControlTypeMap as MuiFormControlTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxFormControlCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  // TODO
  // - color - remove
  // - margin - is this needed?
  // - size - align with AxTextField
  // - variant - remove
};

export type AxFormControlTypeMap<P = object, D extends React.ElementType = 'div'> = MuiFormControlTypeMap<P & AxFormControlCustomProps, D>;

export type AxFormControlProps<
  D extends ElementType = AxFormControlTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxFormControlTypeMap<P & AxFormControlCustomProps, D>, D>;

const styledOptions = {
  name: 'AxFormControl'
};

const StyledAxFormControl = styled(MuiFormControl, styledOptions)<AxFormControlProps>(() => {
  // TODO - update to align with UI Kit
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=5099-5555&mode=design&t=BPAww5mYbYFz5Q1j-0
  return {};
});

export const AxFormControl: MuiOverridableComponent<AxFormControlTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxFormControlProps, ref: Ref<HTMLDivElement>) => {
  const FormControlProps: AxFormControlProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxFormControl { ...FormControlProps } ref={ ref }>
      { children }
    </StyledAxFormControl>
  );
});

export default AxFormControl;
