const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const $os = require('detectOS');
const TrainingModuleViewFactory = require('@common/components/training_modules/TrainingModuleViewFactory');
const PreviewTrainingModule = require('@common/components/training_modules/PreviewTrainingModule');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

require('@common/libs/behaviors/resizable/Resizable');

class TrainingModulePreviewView extends Marionette.LayoutView {
  initialize(options = {}) {
    const previewUser = window.apps.auth.session.user
    const currentLanguage = previewUser.get('language');
    this.module = options.trainingModule
    this.trainingModule = new PreviewTrainingModule(this.module, {
      language: currentLanguage,
      deliveryMethod: this.module.deliveryMethod
    });

    const factory = new TrainingModuleViewFactory({
      trainingModule: this.trainingModule,
      previewingUser: previewUser
    });

    const pageViewRef = document.querySelector('#page-view');
    this.viewPromise = factory.createTrainingModulePreviewForLanguage(currentLanguage, {
      maxHeight: $os.mobile ? pageViewRef?.clientHeight : undefined
    });
  }

  onShow() {
    this.viewPromise.then((view) => {
      if (this.isDestroyed) {
        return;
      }

      this.listenTo(view, 'render', () => {
        triggerResize(true);
      });

      view.setElement(this.ui.moduleContainer);
      view.render();
    });
  }

  className() {
    return 'training-module-preview-modal parent-height';
  }

  getTemplate() {
    return `
      <div class="page-module <%- moduleClass %>-module"></div>
    `;
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  onResize() {
    if ($os.mobile) {
      const pageViewRef = document.querySelector('#page-view');
      const actionBarRef = pageViewRef?.querySelector('#modalView .actionbar');
      // 3.2rem is to account for the top and bottom padding added for training-module-preview-modal
      this.$('.page-module')[0].style.height = `calc(${ (pageViewRef?.clientHeight || 0) - (actionBarRef?.clientHeight || 0) }px - 3.2rem)`;
    } else {
      this.$('.page-module')[0].style.height = this.$el.closest('.training-preview-modal')[0].style.height + 'px';
    }
  }

  templateHelpers() {
    return {
      moduleClass: this.module.moduleType
    };
  }

  ui() {
    return {
      moduleContainer: '.page-module'
    };
  }

  onRender() {
    this.actionBar.setActionBar({buttons: {
      type: 'customText',
      text: I18n.t('general.done'),
      className: 'white',
      onClick: this.destroy.bind(this)
    }});
  }
}

module.exports = TrainingModulePreviewView;
