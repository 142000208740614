const Form = require('@common/components/forms/Form');

const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const logging = require('logging');

const ChildCollectionTreeSourceMapper = require('@common/components/axonFancyTree/ChildCollectionTreeSourceMapper');
const TreeNodeFormatter = require('@common/components/axonFancyTree/TreeNodeFormatter');
const SelectedNodeTreeSerializer = require('@common/components/axonFancyTree/SelectedNodeTreeSerializer');
const FancytreeHelpers = require('@common/components/axonFancyTree/FancytreeHelpers');

require('jquery.fancytree');
require('jquery.fancytree.filter');
require('jquery.fancytree.grid');

const DEFAULT_VIEWPORT_ROW_COUNT = 12;

// Support for root nodes? Maybe in the mapper...
Form.Editor.TreeEditor = class TreeEditor extends Form.Editor {

  initialize(options = {}) {
    this.render = this.render.bind(this);
    this._clearAllSelections = this._clearAllSelections.bind(this);
    this._formatNodes = this._formatNodes.bind(this);
    this._nodeSelect = this._nodeSelect.bind(this);
    this._nodeClicked = this._nodeClicked.bind(this);
    const formOptions = options.options || {};
    this._isMultiSelect = formOptions.isMultiSelectEnabled || false;
    this._collection = formOptions.collection;
    this._escapeTitles = formOptions.escapeTitles;
    this._selectMode = formOptions.selectMode != null ? formOptions.selectMode : 2;
    this._minExpandLevel = formOptions.minExpandLevel != null ? formOptions.minExpandLevel : 1;
    this._shouldRollupChildren = formOptions.shouldRollupChildren != null ? formOptions.shouldRollupChildren : false;

    this._serializer = formOptions.serializer || new SelectedNodeTreeSerializer({ isMultiSelected: this._isMultiSelect});
    this._sourceMapper = formOptions.mapper || new ChildCollectionTreeSourceMapper({ showCheckbox: this._isMultiSelect});

    this._nodeFormatter = formOptions.nodeFormatter || new TreeNodeFormatter();
    this._customMatcher = formOptions.customMatcher || _.noop;
    this._treeOptions = formOptions.treeOptions || {};

    this._postNodeFormatAction = formOptions.postNodeFormatAction;
    this.viewportRowCount = formOptions.viewportRowCount || DEFAULT_VIEWPORT_ROW_COUNT;

    super.initialize(options);
    this.render();
  }

  getTemplate() {
    return _.tpl('\
<div class="tree-wrapper"></div>\
');
  }

  render() {
    try {
      this._treeWrapper.fancytree('destroy');
    } catch (error) {
      // Ignore; no tree to destroy
    }

    this.$el.html(this.getTemplate());
    this._treeWrapper = this.$('.tree-wrapper');

    // Render FancyTree nodes
    this._source = this._sourceMapper.getSourceForTree(this._collection);

    let defaultTreeOptions = {
      extensions: ['filter'],
      filter: {
        counter: false,
        fuzzy: false,
        hideExpandedCounter: true,
        highlight: true,
        mode: 'hide',
        leavesOnly: true,
        autoExpand: true
      },

      icon: false,
      debugLevel: 0,
      checkbox: this._isMultiSelect,
      escapeTitles: this._escapeTitles,
      minExpandLevel: this._minExpandLevel,
      selectMode: this._selectMode,
      source: this._source,

      // Event handlers
      click: this._nodeClicked,
      dblclick: this._nodeClicked,
      select: this._nodeSelect,

      strings: {
        loading: I18n.t('treeEditor.loading'),
        loadError: I18n.t('treeEditor.loadingError'),
        moreData: I18n.t('treeEditor.more'),
        noData: I18n.t('treeEditor.noDataGeneric')
      }
    };

    defaultTreeOptions = $.extend(defaultTreeOptions, this._getTreeOptions(), this._treeOptions);
    if (defaultTreeOptions.extensions.includes('grid')) {
      defaultTreeOptions = $.extend(defaultTreeOptions, FancytreeHelpers.getViewportScrollbarOptions());
    }
    this._treeWrapper.fancytree(defaultTreeOptions);

    this._tree = this._treeWrapper.fancytree('getTree');

    this._formatNodes();
  }

  setNodesSelected(nodes, state = true) {
    // Support for single elements
    const nodesCompact = _.compact([].concat(nodes));

    for (const nodeKey of nodesCompact) {
      const node = this._tree.getNodeByKey(nodeKey.toString());
      if (node) {
        node.setFocus(state && this._canFocus());
        node.setSelected(state);
      }
    }
  }

  getSelectedNodes(stopOnParent) {
    return (this._tree && this._tree.getSelectedNodes(stopOnParent)) || [];
  }

  findNode(predicate) {
    return (this._tree && this._tree.findFirst(predicate)) || null;
  }

  getExpandedNodes() {
    const expanded = [];
    this._tree.visit((node) => {
      if (node.isExpanded()) {
        expanded.push(node.key);
      }
    });
    return expanded;
  }

  setExpandedNodes(keys) {
    _.each(keys, (key) => {
      const node = this.findNode((nodeItem) => {
        return nodeItem.key === key;
      });
      if (node) {
        node.makeVisible().then(() => {
          node.li.scrollIntoView();
          node.setActive(true);
        });
      }
    });
  }

  _clearAllSelections() {
    // Reset everything first
    const nodeKeys = _.pluck(this.getSelectedNodes(), 'key');

    this.setNodesSelected(nodeKeys, false);
  }

  getValue() {
    // Delegates the serialzation down to whatever implementation was specified at run time
    return this._serializer.serialize(this);
  }

  setValue(value) {
    // Delegates the serialization and state changes to the serializer
    const nodeValues = this._serializer.deserialize(value);

    const selectedNodes = this.getSelectedNodes(this._shouldRollupChildren);
    const oldSelectedIds = _.map(selectedNodes, (node) => {
      return node.key;
    });

    const newNodes = _.difference(nodeValues, oldSelectedIds);
    const oldNodes = _.difference(oldSelectedIds, nodeValues);

    this.setNodesSelected(oldNodes, false);
    this.setNodesSelected(newNodes, true);
  }

  reset() {
    this._tree.visit((node) => {
      node.setFocus(false);
      node.setSelected(false);
    });
  }

  search(query) {
    if (!query) {
      this._tree.clearFilter();
    } else {
      const wasSomeMatch = this._tree.filterBranches(query) > 0;

      if (!wasSomeMatch) {
        // Capture the query into a closure
        const customMatcher = (node) => {
          return this._customMatcher(query, node);
        };
        this._tree.filterBranches(customMatcher);
      }
    }
  }

  _formatNodes() {
    this._tree.visit((node) => {
      // Hack: fancytree @version 2.37.0 has a bug where it doesn't expand nodes up to minExpandLevel when using
      // table/grid extension. So, we are compress and expand the node.
      // Created an issue with fancytree: https://github.com/mar10/fancytree/issues/1060
      if (node.isExpanded()) {
        node.setExpanded(false).then(() => {
          node.setExpanded(true);
        });
      }
      if (!_.isEmpty(node.data)) {
        node.icon = this._nodeFormatter.getIconForNode(node);
        node.setTitle(this._nodeFormatter.formatNodeElement(node));
        node.renderTitle();
      }
    });

    if (_.isFunction(this._postNodeFormatAction)) {
      this._postNodeFormatAction();
    }
  }

  _nodeSelect(event, data) {
    this.trigger('node:selected', data.node.isSelected(), data.node);
  }

  _nodeClicked(event, data) {
    const { node } = data;

    // When the empty/no data node is returned during search, prevent it from being clickable
    if (node != null && node.statusNodeType === 'nodata') {
      return false;
    }

    logging.info(`Node clicked -  ${ node.key }`);

    if (this._isLeafNode(event) && !this._isMultiSelect) {
      this.trigger('node:clicked', node);
      this._clearAllSelections();
      this.setNodesSelected(node.key);
    }
    return undefined;
  }

  _getTreeOptions() {
    return {};
  }

  _canFocus() {
    return true;
  }

  // Uses to determine leaf nodes; i.e: not an expander
  _isLeafNode(event) {
    return $.ui.fancytree.getEventTargetType(event.originalEvent) !== 'expander';
  }
};

module.exports = Form.Editor.TreeEditor;
