import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  StepIcon as MuiStepIcon,
  type StepIconProps as MuiStepIconProps
} from '@mui/material';

export type AxStepIconProps = MuiStepIconProps;

const styledOptions = {
  name: 'AxStepIcon'
};

const StyledAxStepIcon = styled(MuiStepIcon, styledOptions)<AxStepIconProps>(() => {
  // TODO - update to use common variables
  return {};
});

export const AxStepIcon = forwardRef(({
  ...otherProps
}: AxStepIconProps, ref: Ref<HTMLDivElement>) => {
  const StepIconProps: AxStepIconProps = {
    ...otherProps
  };

  return (
    <StyledAxStepIcon { ...StepIconProps } ref={ ref } />
  );
});

export default AxStepIcon;
