import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  TableRow as MuiTableRow,
  type TableRowTypeMap as MuiTableRowTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxTableRowCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableRowTypeMap<P = object, D extends React.ElementType = 'tr'> = MuiTableRowTypeMap<P & AxTableRowCustomProps, D>;

export type AxTableRowProps<
  D extends ElementType = AxTableRowTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableRowTypeMap<P & AxTableRowCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTableRow'
};

const StyledAxTableRow = styled(MuiTableRow, styledOptions)<AxTableRowProps>(() => {
  // TODO - update to use UI Kit styles
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=6013-9216&mode=design&t=7poBe9Dfwla5L23E-0
  return {};
});

export const AxTableRow: MuiOverridableComponent<AxTableRowTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableRowProps, ref: Ref<HTMLTableRowElement>) => {
  const TableRowProps: AxTableRowProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableRow { ...TableRowProps } ref={ ref }>
      { children }
    </StyledAxTableRow>
  );
});

export default AxTableRow;
