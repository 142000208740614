import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  SpeedDial as MuiSpeedDial,
  type SpeedDialProps as MuiSpeedDialProps
} from '@mui/material';

export type AxSpeedDialProps = MuiSpeedDialProps;

const styledOptions = {
  name: 'AxSpeedDial'
};

const StyledAxSpeedDial = styled(MuiSpeedDial, styledOptions)<AxSpeedDialProps>(() => {
  // TODO - should use AxFab and AxIconButton styles
  return {};
});

export const AxSpeedDial = forwardRef(({
  ...otherProps
}: AxSpeedDialProps, ref: Ref<HTMLDivElement>) => {
  const SpeedDialProps: AxSpeedDialProps = {
    ...otherProps
  };

  return (
    <StyledAxSpeedDial { ...SpeedDialProps } ref={ ref } />
  );
});

export default AxSpeedDial;
