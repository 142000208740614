import {
  Radio as MuiRadio,
  styled,
  type RadioProps as MuiRadioProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type AxRadioProps = MuiRadioProps;
// TODO
//   - color - remove
//   - centerRipple - remove
//   - disableRipple - remove
//   - disableTouchRipple - remove
//   - focusRipple - remove
//   - TouchRippleProps - remove
//   - touchRippleRef - remove
//   - disableFocusRipple - remove
//   - size - remove

const styledOptions = {
  name: 'AxRadio'
};

const StyledAxRadio = styled(MuiRadio, styledOptions)<AxRadioProps>(({ theme }) => {
  // TODO - update styles to align with UI Kit
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=5099-6018&mode=design&t=hVEcz054xk5GGZgJ-0
  return {
    padding: 0,

    // focus styling
    '&.Mui-focusVisible': theme.mixins.componentKeyboardFocusStyles()
  };
});

export const AxRadio = forwardRef(({
  disableRipple = true,
  disableTouchRipple = true,
  focusRipple = false,
  disableFocusRipple = true,
  ...otherProps
}: AxRadioProps, ref: Ref<HTMLButtonElement>) => {
  const RadioProps: AxRadioProps = {
    disableRipple,
    disableTouchRipple,
    focusRipple,
    disableFocusRipple,
    ...otherProps
  };

  return (
    <StyledAxRadio { ...RadioProps } ref={ ref }/>
  );
});

export default AxRadio;
